import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Ripple } from 'primereact/ripple';
import EndPointService from '../service/EndPointService'
import 'antd/dist/antd.css';
import moment from 'moment';
import { useHistory } from 'react-router-dom';

export const Archive = () => {
    let emptyReservation = {
        id: 0,
        giverCompanyId: 0,
        takerCompanyId: 0,
        givenPrice: 0,
        takenPrice: 0,
        carId: 0,
        driverId: 0,
        serviceTypeId: 0,
        givenPriceCurrencyId: 1,
        takenPriceCurrencyId: 1,
        collectionCurrencyId: 1,
        isFromPassenger: false,
        isFromAgency: false,
        departure: '',
        arrival: '',
        date: '',
        completedDate: '',
        description: null,
        collection: 0
    };
    const [Give, setGive] = useState([])
    const [Receive, setReceive] = useState([])
    const [TotalReceive, setTotalReceive] = useState(0)
    const [TotalGive, setTotalGive] = useState(0)
    const [Archives, setArchives] = useState(null)
    const [Currencys, setCurrencys] = useState([]);
    const [Companys, setCompanys] = useState(null);
    const [Cars, setCars] = useState(null);
    const [ExchangeRates, setExchangeRates] = useState([]);
    const [Car, setCar] = useState(null);
    const [Driver, setDriver] = useState(null);
    const [SearchText, setSearchText] = useState('');
    const [Company, setCompany] = useState(null);
    const [AllDriverCars, setAllDriverCars] = useState([]);
    const [ArchiveGive, setArchiveGive] = useState('');
    const [ArchiveReceive, setArchiveReceive] = useState('');
    const [EndDate, setEndDate] = useState(null);
    const [StartDate, setStartDate] = useState(null);
    const [DriverCars, setDriverCars] = useState([]);
    const [Reservation, setReservation] = useState(emptyReservation);
    const [ReservationDialog, setReservationDialog] = useState(false);
    const toast = useRef(null);
    const dt = useRef(null);
    const history = useHistory();

    const getPayments = () => {
        EndPointService.post(`/api/Reservation/GetConsensus`).then(res => {
            var reveives = res.filter(x => x.receive > 0)
            setReceive(reveives);
            var gives = res.filter(x => x.give < 0)
            gives.forEach((pay) => { pay.give = pay.give * -1 })
            setGive(gives);
            setTotalGive((gives.map(item => item.give).reduce((prev, curr) => prev + curr, 0)));
            setTotalReceive((reveives.map(item => item.receive).reduce((prev, curr) => prev + curr, 0)));
        });
    }

    useEffect(() => {
        getPayments()
    }, [])

    const getDateFormat = (data) => {
        return [...data || []].map(d => {
            d.date = new moment(d.date);
            d.completedDate = new moment(d.completedDate);
            return d;
        });
    }

    useEffect(async () => {
        await EndPointService.get('/api/Currency').then(res => {
            setCurrencys(res)
        });
        // EndPointService.post('/api/Reservation/GetArchive', {
        //     endDate: moment()
        // }).then(res => {
        //     setArchives(getDateFormat(res.list))
        //     setArchiveGive(res.give)
        //     setArchiveReceive(res.receive)
        //     setExchangeRates(res.exchangeRates)
        // });
        EndPointService.get('/api/Company').then(res => {
            setCompanys(res)
        });
        EndPointService.get('/api/Car').then(res => {
            setCars(res)
        });
        EndPointService.get('/api/DriverCars').then(res => {
            setAllDriverCars(res)
            let all = []
            res.map(x => {
                if (all.findIndex(y => y.nationalityNum === x.driver.nationalityNum) === -1)
                    all.push(x.driver)
            })
            setDriverCars(all)
        });
    }, []);

    const search = () => {
        let requestBody = {
            companyId: Company?.id,
            carId: Car?.id,
            driverId: Driver?.id,
            endDate: EndDate,
            startDate: StartDate,
            searchText: SearchText.length > 0 ? SearchText : null
        }
        EndPointService.post('/api/Reservation/GetArchive', requestBody).then(res => {
            setArchives(getDateFormat(res.list))
            setArchiveGive(res.give)
            setArchiveReceive(res.receive)
            setExchangeRates(res.exchangeRates)
        });
    }

    const showReservation = (Reservation) => {
        setReservation({ ...Reservation });
        setReservationDialog(true);
    }


    const hideDialog = () => {
        setReservationDialog(false);
    }

    const clear = () => {
        setCar(null);
        setCompany(null);
        setDriver(null);
        setEndDate(null);
        setStartDate(null);
        EndPointService.post('/api/Reservation/GetArchive', { endDate: moment() }).then(res => {
            setArchives(getDateFormat(res.list))
            setArchiveGive(res.give)
            setArchiveReceive(res.receive)
            setExchangeRates(res.exchangeRates)
        });
    }

    const onCompanyChange = (e) => {
        const val = (e.target && e.target.value) || '';
        setCompany(val);
        setCar(null);
        setDriver(null);
        if (val.id) {
            EndPointService.get(`/api/Car/GetWithCompany?companyId=${val.id}`).then(data => {
                setCars(data)
            });
        }
        else {
            EndPointService.get('/api/Car').then(res => {
                setCars(res)
            });
        }
    }

    const onCarChange = (e) => {
        const val = (e.target && e.target.value) || '';
        setCar(val);
        setDriver(null);
        if (val.id) {
            let carDriver = [];
            AllDriverCars.filter(x => x.car.id == val.id).map(x => {
                carDriver.push(x.driver)
            })
            setDriverCars(carDriver)
        }
        else {
            let all = []
            AllDriverCars.map(x => {
                if (all.findIndex(y => y.nationalityNum === x.driver.nationalityNum) === -1)
                    all.push(x.driver)
            })
            setDriverCars(all)
        }
    }

    const onDriverChange = (e) => {
        const val = (e.target && e.target.value) || '';
        setDriver(val);
    }

    const onEndDateChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        var date = new Date(val.getTime() - (val.getTimezoneOffset() * 60000)).toISOString();
        setEndDate(date);
    }

    const onStartDateChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        var date = new Date(val.getTime() - (val.getTimezoneOffset() * 60000)).toISOString();
        setStartDate(date);
    }

    const giverCompanyBodyTemplate = (data) => {
        var name = data.giverCompany.name
        return (
            <>
                {name}
            </>
        );
    };

    const takerCompanyBodyTemplate = (data) => {
        var name = data.takerCompany?.name ?? ""
        return (
            <>
                {name}
            </>
        );
    };

    const carBodyTemplate = (data) => {
        var plate = data.car?.plate
        return (
            <>
                {plate}
            </>
        );
    };

    const driverBodyTemplate = (data) => {
        var name = data.driver?.name
        return (
            <>
                {name}
            </>
        );
    };

    const dateBodyTemplate = (data) => {
        var date = data.date.format("DD-MM-YY HH:mm");
        return (
            <>
                {date}
            </>
        );
    };

    const completedDateBodyTemplate = (data) => {
        var date = data.completedDate.format("DD-MM-YY HH:mm");
        return (
            <>
                {date}
            </>
        );
    };

    const takenPriceBodyTemplate = (data) => {
        var currency = data.takenPrice > 0 ? Currencys.find(x => x.id === data.takenPriceCurrencyId)?.code : "";
        var fullPrice = data.takenPrice + " " + currency;
        return (
            <>
                {fullPrice}
            </>
        );
    }

    const givenPriceBodyTemplate = (data) => {
        var currency = data.givenPrice > 0 ? Currencys.find(x => x.id === data.givenPriceCurrencyId)?.code : "";
        var fullPrice = data.givenPrice + " " + currency;
        return (
            <>
                {fullPrice}
            </>
        );
    }

    const collectionPriceBodyTemplate = (data) => {
        var currency = data.collection > 0 ? Currencys.find(x => x.id === data.collectionCurrencyId)?.code : "";
        var fullPrice = data.collection + " " + currency;
        var convertPrice = undefined
        if (data.collectionCurrencyId > 1) {
            var currency = ExchangeRates.find(x => x.currencyId === data.collectionCurrencyId && x.date.substring(0, 10) === data.completedDate.toISOString().substring(0, 10))?.value
            convertPrice = data.collection * currency
        }
        return (
            <>
                {fullPrice} {convertPrice ? <>({convertPrice}₺)</> : ""}
            </>
        );
    }

    const idBodyTemplate = (data) => {
        return (
            <p onClick={() => {
                history.push(`/CompanyArchive/${data.id}`)
            }} style={{ color: 'blue', cursor:'pointer' }}> {data.name}</p>
        );
    };

    const header = (
        <div className="table-header">
            <h5 className="p-m-0">Cari Kayıt</h5>
            <span className="p-input-icon-left">
                <h5>Hizmet Bedeli {ArchiveGive}₺ - Tahsil Edilen Tutar {ArchiveReceive}₺</h5>
            </span>
        </div>
    );

    const template = (options) => {
        const toggleIcon = options.collapsed ? 'pi pi-chevron-down' : 'pi pi-chevron-up';
        const className = `${options.className} justify-content-start`;

        return (
            <div className={className}>
                <button className={options.togglerClassName} onClick={options.onTogglerClick}>
                    <span className={toggleIcon}></span>
                    <Ripple />
                </button>
            </div>
        )
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-search" className="p-button-rounded p-button-info" onClick={() => showReservation(rowData)} />
            </div>
        );
    }

    const giveBodyTemplate = (rowData) => {
        return formatCurrency(rowData.give)+"₺";
    }

    const receiveBodyTemplate = (rowData) => {
        return formatCurrency(rowData.receive)+"₺";
    }


    const formatCurrency = (value) => {
        return new Intl.NumberFormat('tr-TR', {
            style: 'currency',
            currency: 'TRY'
        }).format(value).replace("₺", "");
    }

    const ReservationDialogFooter = (
        <>
            <Button label="İptal" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
        </>
    );

    const headerGive = (
        <div className="table-header" style={{ backgroundColor: 'red', height: '50px', marginBottom: '-13px', width: '741px', marginLeft: '-15px', marginTop: '-15px' }}>
            <h5 className="p-m-2">Borçlar</h5>
            <span className="p-input-icon-left">
                <h5>Toplam {formatCurrency(TotalGive)+"₺"}</h5>
            </span>
        </div>
    );

    const headerReceive = (
        <div className="table-header" style={{ backgroundColor: '#40c8ff', height: '50px', marginBottom: '-13px', width: '741px', marginLeft: '-15px', marginTop: '-15px' }}>
            <h5 className="p-m-2">Alacaklar</h5>
            <span className="p-input-icon-left">
                <h5>Toplam {formatCurrency(TotalReceive)+"₺"}</h5>
            </span>
        </div>
    );

    return (
        <div className="p-grid crud-demo">
            <div className="p-col-12 p-lg-5">
                <div className="Paymentd">
                    <DataTable ref={dt} value={Receive}
                        dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-scrollable"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Gösterilen {first} ve {last} Toplam {totalRecords} Alacaklar İçinden"
                        emptyMessage="Alacaklar." header={headerReceive} style={{ width:'746px', borderStyle: 'groove' }} 
                        sortField="receive" sortOrder={-1}>
                        <Column field="id" header="id" style={{ display: 'none' }} sortable></Column>
                        <Column field="name" header="Şirket" body={idBodyTemplate}></Column>
                        <Column field="receive" body={receiveBodyTemplate} header="Alacak Tutar" sortable></Column>
                    </DataTable>
                </div>
            </div>
            <div className="p-col-12 p-lg-5" style={{ right: '0', position: 'absolute' }}>
                <div className="Paymentd">
                    <DataTable ref={dt} value={Give}
                        dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Gösterilen {first} ve {last} Toplam {totalRecords} Borçlar İçinden"
                        emptyMessage="Borçlar." header={headerGive} style={{ width:'746px', borderStyle: 'groove' }} 
                        sortField="give" sortOrder={-1}>
                        <Column field="id" header="id" style={{ display: 'none' }} sortable></Column>
                        <Column field="name" header="Şirket" body={idBodyTemplate}></Column>
                        <Column field="give" body={giveBodyTemplate} header="Borç Tutarı" sortable></Column>
                    </DataTable>
                </div>
            </div>
            {/* <div className="p-col-12">
                <div className="Reservationd">
                    <Toast ref={toast} style={{ top: 60 }} />
                    <Panel headerTemplate={template} toggleable>
                        <div className="p-formgrid p-grid">
                            <div className="p-field p-col" >
                                <label style={{ marginLeft: '44px' }}>Şirket</label>
                                <Dropdown value={Company} style={{ width: '200px', marginLeft: '10px' }} filter showClear filterBy="name" options={Companys} onChange={onCompanyChange} optionLabel="name" />
                            </div>
                            <div className="p-field p-col">
                                <label style={{ marginLeft: '68px' }}>Araç</label>
                                <Dropdown value={Car} style={{ width: '200px', marginLeft: '10px' }} options={Cars} filter showClear filterBy="plate" onChange={onCarChange} optionLabel="plate" />
                            </div>
                            <div className="p-field p-col">
                                <label style={{ marginLeft: '20px' }}>Sürücü</label>
                                <Dropdown value={Driver} style={{ width: '200px', marginLeft: '10px' }} filter showClear filterBy="name" options={DriverCars} onChange={onDriverChange} optionLabel="name" />
                            </div>
                            <div className="p-field p-col">
                            </div>
                        </div>
                        <div className="p-formgrid p-grid">
                            <div className="p-field p-col">
                                <label>Hizmet Kodu</label>
                                <InputText value={SearchText} style={{ width: '200px', marginLeft: '10px' }} onChange={(e) => setSearchText((e.target && e.target.value) || '')} />
                            </div>
                            <div className="p-field p-col">
                                <label >Başlangıç Tarihi</label>
                                <Calendar value={StartDate} style={{ width: '200px', marginLeft: '10px' }} onChange={onStartDateChange} dateFormat="dd/mm/yy" />
                            </div>
                            <div className="p-field p-col">
                                <label>Bitiş Tarihi</label>
                                <Calendar value={EndDate} style={{ width: '200px', marginLeft: '10px' }} onChange={onEndDateChange} dateFormat="dd/mm/yy" />
                            </div>
                            <div className="p-field p-col">
                                <Button icon="pi pi-search" className="p-button-rounded p-button-success" onClick={search} />
                                <Button icon="pi pi-times" className="p-button-rounded p-button-danger" style={{ marginLeft: '20px' }} onClick={clear} />
                            </div>

                        </div>
                    </Panel>
                    <DataTable ref={dt} value={Archives}
                        dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Gösterilen {first} ve {last} Toplam {totalRecords} Cari Kayıt İçinden"
                        emptyMessage="Kayıt Bulunamadı" header={header}>
                        <Column field="reservationId" header="Hizmet kodu" body={idBodyTemplate} ></Column>
                        <Column field="giverCompany" header="İş Veren Şirket" body={giverCompanyBodyTemplate} sortField="giverCompany.name"></Column>
                        <Column field="takerCompany" header="İş Alan Şirket" body={takerCompanyBodyTemplate} sortField="takerCompany.name"></Column>
                        <Column field="car" body={carBodyTemplate} header="Araç" sortField="car.plate"></Column>
                        <Column field="driver" body={driverBodyTemplate} header="Sürücü" sortField='driver.name' ></Column>
                        <Column field="collection" header="Tahsilat" body={collectionPriceBodyTemplate}></Column>
                        <Column field="takenPrice" header="Alınan Ücret" body={takenPriceBodyTemplate}></Column>
                        <Column field="givenPrice" header="Verilen Ücret" body={givenPriceBodyTemplate}></Column>
                        <Column field="departure" header="Alış" ></Column>
                        <Column field="arrival" header="Bırakış" ></Column>
                        <Column field="completedDate" header="Tarih" body={completedDateBodyTemplate}></Column>
                        <Column body={actionBodyTemplate}></Column>
                    </DataTable>
                </div>
            </div>
            <Dialog visible={ReservationDialog} style={{ width: '650px' }} header="Rezervasyon Detayları" modal className="p-fluid" footer={ReservationDialogFooter} onHide={hideDialog}>
                <div className="p-formgrid p-grid">
                    <div className="p-field p-col">
                        <label htmlFor="giverCompanyId">İş Veren Şirket</label>
                        <InputText id="arrival" disabled={true} value={Reservation.giverCompany?.name} />
                    </div>
                    <div className="p-field p-col">
                        <label htmlFor="takerCompanyId">İşi Yapan Şirket</label>
                        <InputText id="arrival" disabled={true} value={Reservation.takerCompany?.name} />
                    </div>
                </div>
                <div className="p-formgrid p-grid">
                    <div className="p-field p-col">
                        <label htmlFor="carId">Araç</label>
                        <InputText id="arrival" disabled={true} value={Reservation.car?.plate} />
                    </div>
                    <div className="p-field p-col">
                        <label htmlFor="driverId">Sürücü</label>
                        <InputText id="arrival" disabled={true} value={Reservation.driver?.name} />
                    </div>
                </div>
                <div className="p-field">
                    <label className="p-mb-3">Ücretin Alınma Şekli</label>
                    <div className="p-formgrid p-grid">
                        <div className="p-field-radiobutton p-col">
                            <RadioButton inputId="isFromPassenger" disabled={true} name="isFromPassenger" value="isFromPassenger" checked={Reservation.isFromPassenger} />
                            <label htmlFor="isFromPassenger">Yolcudan</label>
                        </div>
                        <div className="p-field-radiobutton p-col">
                            <RadioButton inputId="isFromAgency" disabled={true} name="isFromAgency" value="isFromAgency" checked={Reservation.isFromAgency} />
                            <label htmlFor="isFromAgency">Acenteden</label>
                        </div>
                    </div>
                </div>
                <div className="p-formgrid p-grid">
                    <div className="p-field p-col">
                        <label htmlFor="takenPrice">Ücret</label>
                        <div className="p-inputgroup">
                            <InputNumber id="takenPrice" disabled={true} value={Reservation.takenPrice} />
                            <span className="p-inputgroup-addon">
                                <label>{Currencys.find(x => x.id === Reservation.takenPriceCurrencyId)?.code}</label>
                            </span>
                        </div>
                    </div>
                    <div className="p-field p-col">
                        <label htmlFor="collection">Tahsilat</label>
                        <div className="p-inputgroup">
                            <InputNumber id="collection" disabled={true} value={Reservation.collection} />
                            <span className="p-inputgroup-addon">
                                <label>{Currencys.find(x => x.id === Reservation.collectionCurrencyId)?.code}</label>
                            </span>
                        </div>
                    </div>
                    <div className="p-field p-col">
                        <label htmlFor="givenPrice">Verilen Ücret</label>
                        <div className="p-inputgroup">
                            <InputNumber id="givenPrice" disabled={true} value={Reservation.givenPrice} />
                            <span className="p-inputgroup-addon">
                                <label>{Currencys.find(x => x.id === Reservation.givenPriceCurrencyId)?.code}</label>
                            </span>
                        </div>
                    </div>
                </div>
                <div className="p-formgrid p-grid">
                    <div className="p-field p-col">
                        <Space direction="vertical" size={12}>
                            <label htmlFor="date">Başlangıç Tarihi</label>
                            <DatePicker disabled={true} format="DD-MM-YY HH:mm" value={Reservation.date} />
                        </Space>
                    </div>
                    <div className="p-field p-col">
                        <Space direction="vertical" size={12}>
                            <label htmlFor="completedDate">Bitiş Tarihi</label>
                            <DatePicker disabled={true} format="DD-MM-YY HH:mm" value={Reservation.completedDate} />
                        </Space>
                    </div>
                </div>
                <div className="p-formgrid p-grid">
                    <div className="p-field p-col">
                        <label htmlFor="departure">Alış</label>
                        <InputText id="departure" disabled={true} value={Reservation.departure} />
                    </div>
                    <div className="p-field p-col">
                        <label htmlFor="arrival">Bırakış</label>
                        <InputText id="arrival" disabled={true} value={Reservation.arrival} />
                    </div>
                </div>
                <div className="p-formgrid p-grid">
                    <div className="p-field p-col">
                        <label htmlFor="serviceTypeId">Servis Tipi</label>
                        <InputText id="arrival" disabled={true} value={Reservation.serviceType?.name} />
                    </div>
                    <div className="p-field p-col">
                        <label htmlFor="description">Açıklama</label>
                        <InputTextarea id="description" disabled={true} value={Reservation.description} autoResize rows={3} cols={20} />
                    </div>
                </div>
            </Dialog> */}
        </div>
    );
}
