import React, { useState, useRef, useEffect } from 'react';
import classNames from 'classnames';
import { Route,Switch } from 'react-router-dom';
import AppTopbar from './AppTopbar';
import AppMenu from './AppMenu';
import AppConfig from './AppConfig';
import { Dashboard } from './components/Dashboard';
import { CarDefinition } from './pages/CarDefinition';
import { CompanyDefinition } from './pages/CompanyDefinition';
import { DriverDefinition } from './pages/DriverDefinition';
import { ExchangeRateDefinition } from './pages/ExchangeRateDefinition';
import PrimeReact from 'primereact/api';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { signInWithToken } from './actions/authAction';
import { DriverCarsDefinition } from './pages/DriverCarsDefinition';
import { ServiceTypeDefinition } from './pages/ServiceTypeDefinition';
import { Reservation } from './pages/Reservation';
import { Archive } from './pages/Archive';

import 'primereact/resources/primereact.min.css';
import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css';
import './App.scss';
import { ReservationDetail } from './components/ReservationDetail';
import { Payment } from './pages/Payment';
import { CompanyArchive } from './pages/Archive/CompanyArchive';
import { CarArchive } from './pages/Archive/CarArchive';


const App = () => {
    const [activeTopbarItem, setActiveTopbarItem] = useState(null);
    const [layoutMode, setLayoutMode] = useState('overlay');
    const [mobileMenuActive, setMobileMenuActive] = useState(null);
    const [topbarMenuActive, setTopbarMenuActive] = useState(null);
    const [menuActive, setMenuActive] = useState(false);
    const [themeColor, setThemeColor] = useState('bluegrey');
    const [layoutColor, setLayoutColor] = useState('moody');
    const [darkMenu, setDarkMenu] = useState(false);
    const [inputStyle, setInputStyle] = useState('outlined');
    const [ripple, setRipple] = useState(true);
    const [colorMode, setColorMode] = useState('light');
    const [loading, setLoading] = useState(true);
    const sidebar = useRef(null);

    const auth = useSelector(({ auth }) => auth);
    const history = useHistory();
    const dispatch = useDispatch();
    useEffect(() => {
        if (auth.error) {
            history.push('/login')
        }
        else if (!auth.isAuthenticated) {
            dispatch(signInWithToken())
        }
        else {
            setLoading(false)
        }
    }, [auth]);

    PrimeReact.ripple = true;

    let menuClick;
    let menuButtonClick;
    let topbarMenuClick;
    let topbarMenuButtonClick;
    let sidebarTimeout;

    const menu = [
        {
            label: 'Organizasyon', icon: 'pi pi-fw pi-home',
            items: [
                { label: 'Rezervasyonlar', icon: 'pi pi-fw pi-home', to: '/' },
                // { label: 'Rezervasyon', icon: 'pi pi-fw pi-calendar-plus', to: '/reservation' },
                { label: 'Cari Hesap', icon: 'pi pi-fw pi-money-bill', to: '/archive' },
                { label: 'Şirket Cari Hesap', icon: 'pi pi-fw pi-money-bill', to: '/CompanyArchive' },
                { label: 'Araç Cari Hesap', icon: 'pi pi-fw pi-money-bill', to: '/CarArchive' },
                { label: 'Ödemeler', icon: 'pi pi-fw pi-briefcase', to: '/payment' },
            ]
        },
        {
            label: 'Tanımlar', icon: 'pi pi-fw pi-align-left',
            items: [
                { label: 'Şirket Tanım', icon: 'pi pi-fw pi-building', to: '/company' },
                { label: 'Araç Tanım', icon: 'pi pi-fw pi-car', to: '/car' },
                { label: 'Sürücü Tanım', icon: 'pi pi-fw pi-id-card', to: '/driver' },
                { label: 'Araç Sürücüleri Tanım', icon: 'pi pi-fw pi-users', to: '/drivercars' },
                { label: 'Servis Tipi Tanım', icon: 'pi pi-fw pi-plus-circle', to: '/servicetype' },
                { label: 'Döviz Tanım', icon: 'pi pi-fw pi-globe', to: '/exchange' },
            ]
        }
    ];

    const onInputStyleChange = (inputStyle) => {
        setInputStyle(inputStyle);
    }

    const onRipple = (e) => {
        PrimeReact.ripple = e.value;
        setRipple(e.value);
    }

    const onLayoutModeChange = (mode) => {
        setLayoutMode(mode);
    }

    const onDarkMenuChange = (mode) => {
        if (mode === 'dark') {
            setDarkMenu(true)
            setColorMode('dark');
        }
        else {
            setDarkMenu(false);
            setColorMode('light');
        }
    }

    const onWrapperClick = (event) => {
        if (!menuClick && !menuButtonClick && mobileMenuActive) {
            setMobileMenuActive(false)
        }

        if (!topbarMenuClick && !topbarMenuButtonClick) {
            setActiveTopbarItem(null);
            setTopbarMenuActive(false)
        }

        if (!menuClick) {
            if (isHorizontal() || isOverlay()) {
                setMenuActive(false)
            }
        }

        menuClick = false;
        menuButtonClick = false;
        topbarMenuClick = false;
        topbarMenuButtonClick = false;
    }

    const onTopbarItemClick = (event) => {
        topbarMenuClick = true;
        if (activeTopbarItem === event.item)
            setActiveTopbarItem(null)
        else
            setActiveTopbarItem(event.item)
        event.originalEvent.preventDefault();

    }

    const onMenuButtonClick = (event) => {
        menuButtonClick = true;

        if (isMobile()) {
            setMobileMenuActive(prevState => !prevState);
        }

        event.preventDefault();
    }

    const onTopbarMobileMenuButtonClick = (event) => {
        topbarMenuButtonClick = true;
        setTopbarMenuActive(prevState => !prevState)
        event.preventDefault();
    }

    const onToggleMenuClick = (event) => {
        setLayoutMode(prevState => prevState !== 'static' ? 'static' : 'overlay')
    }

    const onSidebarClick = (event) => {
        menuClick = true;
    }

    const onSidebarMouseEnter = (event) => {
        if (layoutMode !== 'horizontal') {
            setMenuActive(true);
        }
        if (sidebarTimeout) {
            clearTimeout(sidebarTimeout);
        }
        addClass(sidebar.current, 'layout-sidebar-active');
    }

    const onSidebarMouseLeave = (event) => {
        if (layoutMode !== 'horziontal') {
            setMenuActive(false);
        }
        sidebarTimeout = setTimeout(() => {
            removeClass(sidebar.current, 'layout-sidebar-active');
        }, 250);
    }

    const addClass = (element, className) => {
        if (element) {
            if (element.classList)
                element.classList.add(className);
            else
                element.className += ' ' + className;
        }
    }

    const removeClass = (element, className) => {
        if (element) {
            if (element.classList)
                element.classList.remove(className);
            else
                element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
        }
    }

    const onRootMenuItemClick = (event) => {
        setMenuActive(prevState => !prevState)
    }

    const onMenuItemClick = (event) => {
        if (!event.item.items && isHorizontal()) {
            setMenuActive(false)
        }

        if (!event.item.items && isMobile()) {
            setMobileMenuActive(false);
            setMenuActive(false);
            onSidebarMouseLeave();
        }
    }

    const changeTheme = (event) => {
        setThemeColor(event);
        changeStyleSheetUrl('theme-css', event, 'theme');
    }

    const changeLayout = (event) => {
        setLayoutColor(event)
        changeStyleSheetUrl('layout-css', event, 'layout');
    }

    const changeStyleSheetUrl = (id, value, prefix) => {
        let element = document.getElementById(id);
        let urlTokens = element.getAttribute('href').split('/');
        urlTokens[urlTokens.length - 1] = prefix + '-' + value + '.css';
        let newURL = urlTokens.join('/');

        replaceLink(element, newURL);
    }

    const isIE = () => {
        return /(MSIE|Trident\/|Edge\/)/i.test(window.navigator.userAgent)
    }

    const replaceLink = (linkElement, href) => {
        if (isIE()) {
            linkElement.setAttribute('href', href);
        }
        else {
            const id = linkElement.getAttribute('id');
            const cloneLinkElement = linkElement.cloneNode(true);

            cloneLinkElement.setAttribute('href', href);
            cloneLinkElement.setAttribute('id', id + '-clone');

            linkElement.parentNode.insertBefore(cloneLinkElement, linkElement.nextSibling);

            cloneLinkElement.addEventListener('load', () => {
                linkElement.remove();
                cloneLinkElement.setAttribute('id', id);
            });
        }
    }

    const isMobile = () => {
        return window.innerWidth <= 1024;
    }

    const isOverlay = () => {
        return layoutMode === 'overlay';
    }

    const isHorizontal = () => {
        return layoutMode === 'horizontal';
    }

    let wrapperClass = classNames('layout-wrapper', {
        'layout-wrapper-static': layoutMode === 'static',
        'layout-wrapper-active': mobileMenuActive,
        'layout-menu-horizontal': layoutMode === 'horizontal',
        'p-input-filled': inputStyle === 'filled',
        'p-ripple-disabled': !ripple
    });

    let sidebarClassName = classNames("layout-sidebar", { 'layout-sidebar-dark': darkMenu });

    return (<>{loading ? <div class="splash-screen">
        <div class="splash-loader-container">
            <svg class="splash-loader" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                <circle class="splash-path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
            </svg>
        </div>
    </div> : (
        <div className={wrapperClass} onClick={onWrapperClick}>
            <div ref={sidebar} className={sidebarClassName} onClick={onSidebarClick}
                onMouseEnter={onSidebarMouseEnter} onMouseLeave={onSidebarMouseLeave}>

                <div className="sidebar-logo">
                    <button className="p-link">
                        <img alt="logo" src="assets/layout/images/interlogo.png" style={{ width: '150px', height: '50px', marginLeft: '30px' }} />
                    </button>
                    <button className="p-link sidebar-anchor" title="Toggle Menu" onClick={onToggleMenuClick}> </button>
                </div>

                <div className="layout-menu-container">
                    <AppMenu model={menu} onRootMenuItemClick={onRootMenuItemClick} layoutMode={layoutMode}
                        active={menuActive} onMenuItemClick={onMenuItemClick} />
                </div>
            </div>
            <div className="layout-main">
                <AppTopbar layoutMode={layoutMode} activeTopbarItem={activeTopbarItem} onTopbarItemClick={onTopbarItemClick}
                    onMenuButtonClick={onMenuButtonClick} onTopbarMobileMenuButtonClick={onTopbarMobileMenuButtonClick}
                    topbarMenuActive={topbarMenuActive} isMobile={isMobile} />

                {/* <AppBreadcrumb /> */}
                <br /><br /><br />
                <div className="layout-content">
                    <Route path="/" exact component={Dashboard} />
                    <Route path="/car" component={CarDefinition} />
                    <Route path="/company" component={CompanyDefinition} />
                    <Route path="/driver" component={DriverDefinition} />
                    <Route path="/drivercars" component={DriverCarsDefinition} />
                    <Route path="/servicetype" component={ServiceTypeDefinition} />
                    <Route path="/reservation" component={Reservation} />
                    <Route path="/archive" component={Archive} />
                    <Route path="/payment" component={Payment} />
                    <Route path="/CompanyArchive" component={CompanyArchive} exact/>
                    <Route path="/CompanyArchive/:id" component={CompanyArchive} exact/>
                    <Route path="/CarArchive" component={CarArchive} />
                    <Route path="/exchange" component={ExchangeRateDefinition}/>
                </div>
{/* 
                <AppConfig inputStyle={inputStyle} onInputStyleChange={onInputStyleChange}
                    rippleEffect={ripple} onRippleEffect={onRipple}
                    layoutMode={layoutMode} onLayoutModeChange={onLayoutModeChange}
                    colorMode={colorMode} onDarkMenuChange={onDarkMenuChange}
                    layoutColor={layoutColor} changeLayout={changeLayout}
                    themeColor={themeColor} changeTheme={changeTheme} /> */}

                {/* <AppFooter /> */}

                {mobileMenuActive && <div className="layout-main-mask"></div>}
            </div>
        </div>
    )}</>
    );

}

export default App;
