import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { DatePicker, Space } from 'antd';
import { Dropdown } from 'primereact/dropdown';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import EndPointService from '../service/EndPointService'
import { InputMask } from 'primereact/inputmask';
import locale from 'antd/es/date-picker/locale/tr_TR';
import { InputTextarea } from 'primereact/inputtextarea';
import 'antd/dist/antd.css';
import moment from 'moment';


export const DriverDefinition = () => {
    let emptyDriver = {
        id: null,
        name: '',
        phone: '',
        birthdate: null,
        nationalityNum: '',
        address: '',
    };

    const [Drivers, setDrivers] = useState(null);
    const [DriverDialog, setDriverDialog] = useState(false);
    const [deleteDriverDialog, setDeleteDriverDialog] = useState(false);
    const [deleteDriversDialog, setDeleteDriversDialog] = useState(false);
    const [Driver, setDriver] = useState(emptyDriver);
    const [selectedDrivers, setSelectedDrivers] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);

    const getDrivers = () => {
        EndPointService.get('/api/Driver').then(data => { setDrivers(changeDateFormat(data)) });
    }

    const changeDateFormat = (data) => {
        return [...data || []].map(d => {
            d.birthdate = new moment(d.birthdate);
            return d;
        });
    }

    useEffect(() => {
        getDrivers();
    }, []);

    const openNew = () => {
        setDriver(emptyDriver);
        setSubmitted(false);
        setDriverDialog(true);
    }

    const hideDialog = () => {
        setSubmitted(false);
        setDriverDialog(false);
    }

    const hideDeleteDriverDialog = () => {
        setDeleteDriverDialog(false);
    }

    const hideDeleteDriversDialog = () => {
        setDeleteDriversDialog(false);
    }

    const objectNullCheck = (data) => {
        var result = true;
        for (const [key, value] of Object.entries(data)) {
            if (key !== "id" && !value) {
                result = false;
            }
        }
        return result;
    }

    const saveDriver = async () => {
        setSubmitted(true);
        if (objectNullCheck(Driver)) {
            let _Driver = { ...Driver };
            if (Driver.id) {
                try {
                    let updateBody = {
                        name: Driver.name,
                        phone: Driver.phone,
                        birthdate: Driver.birthdate,
                        nationalityNum: Driver.nationalityNum,
                        address: Driver.address,
                    }
                    await EndPointService.put('/api/Driver?id=' + _Driver.id, updateBody)
                    toast.current.show({ severity: 'success', summary: 'Başarılı', detail: 'Sürücü Güncellendi', life: 3000 });
                } catch (error) {
                    toast.current.show({ severity: 'error', summary: 'Hata', detail: 'Sürücü Güncellenirken Hata Oluştu', life: 3000 });
                }
            }
            else {
                try {
                    let addBody = {
                        name: Driver.name,
                        phone: Driver.phone,
                        birthdate: Driver.birthdate,
                        nationalityNum: Driver.nationalityNum,
                        address: Driver.address,
                    }
                    await EndPointService.post('/api/Driver', addBody)
                    toast.current.show({ severity: 'success', summary: 'Başarılı', detail: 'Sürücü Eklendi', life: 3000 });
                } catch (error) {
                    toast.current.show({ severity: 'error', summary: 'Hata', detail: 'Sürücü Eklerken Hata Oluştu', life: 3000 });
                }
            }

            getDrivers();
            setDriverDialog(false);
            setDriver(emptyDriver);
        }
    }

    const editDriver = (Driver) => {
        setDriver({ ...Driver });
        setDriverDialog(true);
    }

    const confirmDeleteDriver = (Driver) => {
        setDriver(Driver);
        setDeleteDriverDialog(true);
    }

    const deleteDriver = async () => {
        try {
            await EndPointService.delete('/api/Driver?id=' + Driver.id)
            toast.current.show({ severity: 'success', summary: 'Başarılı', detail: 'Sürücü Silindi', life: 3000 });
        } catch (error) {
            toast.current.show({ severity: 'error', summary: 'Hata', detail: 'Sürücü Silinirken Hata Oluştu', life: 3000 });
        }
        getDrivers();
        setDeleteDriverDialog(false);
        setDriver(emptyDriver);
    }

    const confirmDeleteSelected = () => {
        setDeleteDriversDialog(true);
    }

    const deleteSelectedDrivers = async () => {
        try {
            await selectedDrivers.map(async Driver => {
                await EndPointService.delete('/api/Driver?id=' + Driver.id)
            })
            toast.current.show({ severity: 'success', summary: 'Başarılı', detail: 'Sürücüler Silindi', life: 3000 });
        } catch (error) {
            toast.current.show({ severity: 'error', summary: 'Hata', detail: 'Sürücüler Silinirken Hata Oluştu', life: 3000 });
        }
        getDrivers();
        setDeleteDriversDialog(false);
        setSelectedDrivers(null);
        setDriver(emptyDriver);
    }

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _Driver = { ...Driver };
        _Driver[`${name}`] = val;

        setDriver(_Driver);
    }

    const onInputDateChange = (e, name) => {
        const val = e._d || '';
        let _Driver = { ...Driver };
        _Driver[`${name}`] = new moment(val);
        setDriver(_Driver);
    }

    const onInputSearchChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _Driver = { ...Driver };
        _Driver[`${name}`] = val.name ?? val;

        setDriver(_Driver);
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success p-mr-2" onClick={() => editDriver(rowData)} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-warning" onClick={() => confirmDeleteDriver(rowData)} />
            </div>
        );
    }

    const header = (
        <div className="table-header">
            <h5 className="p-m-0">Sürücüler</h5>
            <span className="p-input-icon-left">
                <React.Fragment>
                    <InputText type="search" style={{ height: '42px', marginRight: '10px', marginBottom: '7px' }} onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Ara..." />
                    <Button label="Ekle" icon="pi pi-plus" className="p-button-success p-mr-2 p-mb-2" onClick={openNew} />
                    <Button label="Sil" icon="pi pi-trash" className="p-button-danger p-mb-2" onClick={confirmDeleteSelected} disabled={!selectedDrivers || !selectedDrivers.length} />
                </React.Fragment>
            </span>
        </div>
    );

    const DriverDialogFooter = (
        <>
            <Button label="İptal" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Kaydet" icon="pi pi-check" className="p-button-text" onClick={saveDriver} />
        </>
    );
    const deleteDriverDialogFooter = (
        <>
            <Button label="Hayır" icon="pi pi-times" className="p-button-text" onClick={hideDeleteDriverDialog} />
            <Button label="Evet" icon="pi pi-check" className="p-button-text" onClick={deleteDriver} />
        </>
    );
    const deleteDriversDialogFooter = (
        <>
            <Button label="Hayır" icon="pi pi-times" className="p-button-text" onClick={hideDeleteDriversDialog} />
            <Button label="Evet" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedDrivers} />
        </>
    );

    const dateBodyTemplate = (data) => {
        var date = data.birthdate.format("DD-MM-YYYY");
        return (
            <>
                {date}
            </>
        );
    };

    const monthNavigatorTemplate = (e) => {
        return <Dropdown value={e.value} options={e.options} onChange={(event) => e.onChange(event.originalEvent, event.value)} style={{ lineHeight: 1 }} />;
    }

    const yearNavigatorTemplate = (e) => {
        return <Dropdown value={e.value} options={e.options} onChange={(event) => e.onChange(event.originalEvent, event.value)} className="ml-2" style={{ lineHeight: 1 }} />;
    }

    return (
        <div className="p-grid crud-demo">
            <div className="p-col-12">
                <div className="card">
                    <Toast ref={toast} style={{ top: 60 }} />
                    <DataTable ref={dt} value={Drivers} selection={selectedDrivers} selectionPageOnly onSelectionChange={(e) => setSelectedDrivers(e.value)}
                        dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Gösterilen {first} ve {last} Toplam {totalRecords} Sürücü İçinden"
                        globalFilter={globalFilter} emptyMessage="No Drivers found." header={header}>
                        <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
                        <Column field="id" header="id" style={{ display: 'none' }} sortable></Column>
                        <Column field="nationalityNum" header="TC." filter filterField="nationalityNum" showFilterMenu={false} sortable></Column>
                        <Column field="name" header="İsim" filter filterField="name" showFilterMenu={false} sortable></Column>
                        <Column field="phone" header="Telefon" filter filterField="phone" showFilterMenu={false} sortable></Column>
                        <Column field="birthdate" header="Doğum Tarihi" body={dateBodyTemplate} sortable></Column>
                        <Column field="address" header="Adres" sortable></Column>
                        <Column body={actionBodyTemplate}></Column>
                    </DataTable>

                    <Dialog visible={DriverDialog} style={{ width: '450px' }} header="Sürücü Detayları" modal className="p-fluid" footer={DriverDialogFooter} onHide={hideDialog}>
                        <div className="p-field">
                            <label htmlFor="nationalityNum">TC.</label>
                            <InputMask mask="99999999999" id="nationalityNum" value={Driver.nationalityNum} onChange={(e) => onInputChange(e, 'nationalityNum')} required autoFocus className={classNames({ 'p-invalid': submitted && !Driver.nationalityNum })} />
                        </div>
                        <div className="p-field">
                            <label htmlFor="name">İsim</label>
                            <InputText id="name" value={Driver.name} onChange={(e) => onInputChange(e, 'name')} required className={classNames({ 'p-invalid': submitted && !Driver.name })} />
                        </div>
                        <div className="p-field">
                            <label htmlFor="phone">Telefon</label>
                            <InputText id="phone" value={Driver.phone} onChange={(e) => onInputChange(e, 'phone')} required className={classNames({ 'p-invalid': submitted && !Driver.phone })} />
                        </div>
                        <div className="p-field">
                            <Space direction="vertical" size={12}>
                                <label htmlFor="birthdate">Doğum Tarihi</label>
                                <DatePicker locale={locale} getPopupContainer={(triggerNode) => {
                                    return triggerNode.parentNode;
                                }} style={{ width: '408px', height: '50px', borderColor: 'rgba(0, 0, 0, 0.38)', borderRadius: '3px' }} onChange={(e) => onInputDateChange(e, 'birthdate')} onOk={(e) => onInputDateChange(e, 'birthdate')} format="DD-MM-YYYY" value={Driver.birthdate}/>
                                {submitted && !Driver.birthdate && <small className="p-invalid">Doğum Tarihi zorunlu.</small>}
                            </Space>
                        </div>
                        <div className="p-field">
                            <label htmlFor="address">Adres</label>
                            <InputTextarea id="address" value={Driver.address} onChange={(e) => onInputChange(e, 'address')} required={true} rows={3} cols={20} autoResize className={classNames({ 'p-invalid': submitted && !Driver.address })} />
                            {submitted && !Driver.address && <small className="p-invalid">Adres zorunlu.</small>}
                        </div>
                    </Dialog>

                    <Dialog visible={deleteDriverDialog} style={{ width: '450px' }} header="Onay" modal footer={deleteDriverDialogFooter} onHide={hideDeleteDriverDialog}>
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                            {Driver && <span>Silmek istediğinize emin misiniz <b>{Driver.name}</b>?</span>}
                        </div>
                    </Dialog>

                    <Dialog visible={deleteDriversDialog} style={{ width: '450px' }} header="Onay" modal footer={deleteDriversDialogFooter} onHide={hideDeleteDriversDialog}>
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                            {Driver && <span>Seçilen kayıtları silmek istediğinize emin misiniz?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
}
