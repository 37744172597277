import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { Dialog } from 'primereact/dialog';
import EndPointService from '../src/service/EndPointService'
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { useSelector } from 'react-redux';

const AppTopbar = (props) => {
	const history = useHistory();

	const goDashboard = () => {
		history.push('/')
	}

	const logutSubmit = () => {
		history.push('/login')
	}
	let emptyUser = {
		name: '',
		surname: '',
		email: '',
		password: '',
		confirmPassword: ''
	};
	const [User, setUser] = useState(emptyUser);
	const [UserDialog, setUserDialog] = useState(false);
	const [submitted, setSubmitted] = useState(false);
	const toast = useRef(null);
	const onTopbarItemClick = (event, item) => {
		if (props.onTopbarItemClick) {
			props.onTopbarItemClick({
				originalEvent: event,
				item: item
			});
		}
	}

	const onInputChange = (e, name) => {
		const val = (e.target && e.target.value) || '';
		let _User = { ...User };
		_User[`${name}`] = val;

		setUser(_User);
	}

	const hideDialog = () => {
		setSubmitted(false);
		setUserDialog(false);
	}

	const saveUser = async () => {
		setSubmitted(true);
		if (User.name.trim() && User.surname.trim() && User.email.trim() && User.password.trim() && User.confirmPassword.trim()) {
			let _User = { ...User };
			try {
				await EndPointService.post('/api/Account', _User)
				toast.current.show({ severity: 'success', summary: 'Başarılı', detail: 'Kullanıcı Eklendi', life: 3000 });
				setUserDialog(false);
				setUser(emptyUser);
			} catch (error) {
				toast.current.show({ severity: 'error', summary: 'Hata', detail: 'Kullanıcı Eklerken Hata Oluştu', life: 3000 });
			}
		}
	}

	const UserDialogFooter = (
		<>
			<Button label="İptal" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
			<Button label="Kaydet" icon="pi pi-check" className="p-button-text" onClick={saveUser} />
		</>
	);

	let topbarClass = classNames('topbar-menu fadeInDown', { 'topbar-menu-active': props.topbarMenuActive })
	let horizontalIcon = (props.layoutMode === 'horizontal') &&
		<button type="button" className="p-link topbar-logo" onClick={goDashboard}>
			<img alt="logo" src="assets/layout/images/logo-slim.png" />
			<span className="app-name">SERENITY</span>
		</button>;

	const auth = useSelector(({ auth }) => auth);

	return (
		<div className="layout-topbar">
			{horizontalIcon}
			<img alt="logo" src="assets/layout/images/logo-slim.png" className="mobile-logo" />
			<Toast ref={toast} style={{ top: 60 }} />
			<button type="button" className="p-link menu-btn" onClick={props.onMenuButtonClick}>
				<i className="pi pi-bars"></i>
			</button>

			<button type="button" className="p-link topbar-menu-btn" onClick={props.onTopbarMobileMenuButtonClick}>
				<i className="pi pi-user"></i>
			</button>

			<div className="layout-topbar-menu-wrapper">
				<ul className={topbarClass}>
					<li className={classNames('profile-item', { 'active-topmenuitem': props.activeTopbarItem === 'profile' })}>
						<button type="button" className="p-link" onClick={(e) => onTopbarItemClick(e, 'profile')}>
							<span className="profile-image-wrapper">
								<img src="assets/layout/images/profile.jpg" alt="avatar" />
							</span>
							<span className="topbar-item-name profile-name">{auth.user.userName}</span>
						</button>
						<ul className={classNames({ 'fadeInDown': !props.isMobile() })}>
							{/* <li role="menuitem">
								<button type="button" className="p-link">
									<i className="pi pi-user"></i>
									<span>Profil</span>
								</button>
							</li>
							<li role="menuitem">
								<button type="button" className="p-link">
									<i className="pi pi-lock"></i>
									<span>Privacy</span>
								</button>
							</li>
							<li role="menuitem">
								<button type="button" className="p-link">
									<i className="pi pi-cog"></i>
									<span>Ayarlar</span>
								</button>
							</li> */}
							<li role="menuitem">
								<button type="button" className="p-link" onClick={() => setUserDialog(true)}>
									<i className="pi pi-user-plus"></i>
									<span>Kullanıcı Ekle</span>
								</button>
							</li>
							<li role="menuitem">
								<button type="button" className="p-link" onClick={logutSubmit}>
									<i className="pi pi-sign-out"></i>
									<span>Çıkış</span>
								</button>
							</li>
						</ul>
					</li>
					{/* <li className={classNames({ 'active-topmenuitem': props.activeTopbarItem === 'settings' })}>
						<button type="button" className="p-link" onClick={(e) => onTopbarItemClick(e, 'settings')}>
							<i className="topbar-icon pi pi-cog"></i>
							<span className="topbar-item-name">Settings</span>
						</button>
						<ul className={classNames({ 'fadeInDown': !props.isMobile() })}>
							<li role="menuitem">
								<button type="button" className="p-link">
									<i className="pi pi-palette"></i>
									<span>Change Theme</span>
								</button>
							</li>
							<li role="menuitem">
								<button type="button" className="p-link">
									<i className="pi pi-heart"></i>
									<span>Favorites</span>
								</button>
							</li>
							<li role="menuitem">
								<button type="button" className="p-link">
									<i className="pi pi-lock"></i>
									<span>Lock Screen</span>
								</button>
							</li>
							<li role="menuitem">
								<button type="button" className="p-link">
									<i className="pi pi-image"></i>
									<span>Wallpaper</span>
								</button>
							</li>
						</ul>
					</li> */}
					{/* <li className={classNames({ 'active-topmenuitem': props.activeTopbarItem === 'messages' })}>
						<button type="button" className="p-link" onClick={(e) => onTopbarItemClick(e, 'messages')}>
							<i className="topbar-icon pi pi-envelope animated swing"></i>
							<span className="topbar-badge animated rubberBand">5</span>
							<span className="topbar-item-name">Messages</span>
						</button>
						<ul className={classNames({ 'fadeInDown': !props.isMobile() })}>
							<li role="menuitem">
								<button type="button" className="topbar-message p-link">
									<img src="assets/layout/images/avatar1.png" width="35" alt="avatar1" />
									<span>Give me a call</span>
								</button>
							</li>
							<li role="menuitem">
								<button type="button" className="topbar-message p-link">
									<img src="assets/layout/images/avatar2.png" width="35" alt="avatar2" />
									<span>Sales reports attached</span>
								</button>
							</li>
							<li role="menuitem">
								<button type="button" className="topbar-message p-link">
									<img src="assets/layout/images/avatar3.png" width="35" alt="avatar3" />
									<span>About your invoice</span>
								</button>
							</li>
							<li role="menuitem">
								<button type="button" className="topbar-message p-link">
									<img src="assets/layout/images/avatar2.png" width="35" alt="avatar2" />
									<span>Meeting today at 10pm</span>
								</button>
							</li>
							<li role="menuitem">
								<button type="button" className="topbar-message p-link">
									<img src="assets/layout/images/avatar4.png" width="35" alt="avatar4" />
									<span>Out of office</span>
								</button>
							</li>
						</ul>
					</li>
					<li className={classNames({ 'active-topmenuitem': props.activeTopbarItem === 'notifications' })}>
						<button type="button" className="p-link" onClick={(e) => onTopbarItemClick(e, 'notifications')}>
							<i className="topbar-icon pi pi-bell"></i>
							<span className="topbar-badge animated rubberBand">4</span>
							<span className="topbar-item-name">Notifications</span>
						</button>
						<ul className={classNames({ 'fadeInDown': !props.isMobile() })}>
							<li role="menuitem">
								<button type="button" className="p-link">
									<i className="pi pi-sliders-h"></i>
									<span>Pending tasks</span>
								</button>
							</li>
							<li role="menuitem">
								<button type="button" className="p-link">
									<i className="pi pi-calendar"></i>
									<span>Meeting today at 3pm</span>
								</button>
							</li>
							<li role="menuitem">
								<button type="button" className="p-link">
									<i className="pi pi-download"></i>
									<span>Download documents</span>
								</button>
							</li>
							<li role="menuitem">
								<button type="button" className="p-link">
									<i className="pi pi-ticket"></i>
									<span>Book flight</span>
								</button>
							</li>
						</ul>
					</li> */}
					{/* <li className={classNames('search-item', { 'active-topmenuitem': props.activeTopbarItem === 'search' })}
						onClick={(e) => onTopbarItemClick(e, 'search')}>
						<span className="p-float-label p-input-icon-left">
							<i className="topbar-icon pi pi-search"></i>
							<InputText id="search" type="text" placeholder="Search" />
						</span>
					</li> */}
				</ul>
			</div>
			<Dialog visible={UserDialog} style={{ width: '450px' }} header="Kullanıcı Detayları" modal className="p-fluid" footer={UserDialogFooter} onHide={hideDialog}>
				<div className="p-field">
					<label htmlFor="name">İsim</label>
					<InputText id="name" onChange={(e) => onInputChange(e, 'name')} required className={classNames({ 'p-invalid': submitted && !User.name })} />
					{submitted && !User.name && <small className="p-invalid">İsim zorunlu.</small>}
				</div>
				<div className="p-field">
					<label htmlFor="surname">Soyisim</label>
					<InputText id="surname" onChange={(e) => onInputChange(e, 'surname')} required className={classNames({ 'p-invalid': submitted && !User.surname })} />
					{submitted && !User.surname && <small className="p-invalid">Soyisim zorunlu.</small>}
				</div>
				<div className="p-field">
					<label htmlFor="email">Email</label>
					<InputText id="email" onChange={(e) => onInputChange(e, 'email')} required className={classNames({ 'p-invalid': submitted && !User.email })} />
					{submitted && !User.email && <small className="p-invalid">Email zorunlu.</small>}
				</div>
				<div className="p-field">
					<label htmlFor="password">Şifre</label>
					<Password toggleMask id="password" onChange={(e) => onInputChange(e, 'password')} required className={classNames({ 'p-invalid': submitted && !User.password })} />
					{submitted && !User.password && <small className="p-invalid">Şifre zorunlu.</small>}
				</div>
				<div className="p-field">
					<label htmlFor="confirmPassword">Şifre</label>
					<Password toggleMask id="confirmPassword" onChange={(e) => onInputChange(e, 'confirmPassword')} required className={classNames({ 'p-invalid': submitted && !User.confirmPassword })} />
					{submitted && !User.confirmPassword && <small className="p-invalid">Şifre zorunlu.</small>}
				</div>
			</Dialog>
		</div>
	);

}

export default AppTopbar;