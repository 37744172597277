import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Panel } from 'primereact/panel';
import { Ripple } from 'primereact/ripple';
import EndPointService from '../../service/EndPointService'
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import 'antd/dist/antd.css';
import moment from 'moment';
import { InputNumber } from 'primereact/inputnumber';
import { savePDF } from '@progress/kendo-react-pdf';
import "./styles.css";
import { ReservationDetail } from '../../components/ReservationDetail';
import { useParams } from 'react-router-dom';

let emptyTotal = [
    { id: 1, text: 'TOPLAM CİRO', value: '' },
    { id: 2, text: 'KDV', value: '' },
    { id: 3, text: 'GENEL TOPLAM', value: '' },
    { id: 4, text: 'GELEN ÖDEMELER TOPLAMI', value: '' },
    { id: 6, text: 'KALAN', value: '' },
]


export const CompanyArchive = () => {
    const [Companys, setCompanys] = useState(null);
    const [Company, setCompany] = useState(null);
    const [GenelToplam, setGenelToplam] = useState(0);
    const [Archives, setArchives] = useState([]);
    const [Kdv, setKdv] = useState(18)
    const [total, setTotal] = useState(emptyTotal);
    const [yukYotal, setYukYotal] = useState(emptyTotal);
    const [totalPlusAmount, setPlusAmount] = useState(0)
    const [totalNegativeAmount, setNegativeAmount] = useState(0)
    const [dateInfo, setDateInfo] = useState('')
    const [Currencys, setCurrencys] = useState([]);
    const [Dates, setDates] = useState(null);
    const [EndDate, setEndDate] = useState(null);
    const [StartDate, setStartDate] = useState(null);
    const [reservationId, setReservationId] = useState(null)
    const [showReservation, setShowReservation] = useState(false);
    const toast = useRef(null);
    const dt = useRef(null);

    const [height, setHeight] = useState(0)
    const [width, setWidth] = useState(0)
    const ref = useRef(null)

    const { id } = useParams();

    useEffect(() => {
        if (id) {
            EndPointService.get(`/api/Company/${id}`).then(res => {
                setCompany(res)
                search(res)
            });
        }
    }, [id]);

    useEffect(() => {
        EndPointService.get('/api/Company').then(res => {
            setCompanys(res)
        });
        EndPointService.get('/api/Currency').then(res => {
            setCurrencys(res)
        });
    }, []);

    const search = (company) => {
        if (Company?.id === undefined && company?.id === undefined)
            return
        let url = `/api/Reservation/GetConsensusCompany?companyId=${Company?.id ?? company?.id}`
        if (Dates) {
            if (Dates[0])
                url += `&StartDate=${new Date(Dates[0].getTime() - (Dates[0].getTimezoneOffset() * 60000)).toISOString()}`
            if (Dates[1])
                url += `&EndDate=${new Date(Dates[1].getTime() - (Dates[1].getTimezoneOffset() * 60000)).toISOString()}`
        }

        EndPointService.post(url).then(res => {
            setArchives(getDateFormat(res.items))
            let genelTop = res.payment;
            setGenelToplam(genelTop)
            let top = res.items?.filter(x => x.givenPriceCurrencyId == 1 && !x.budgetType).map(item => item.givenPrice).reduce((prev, curr) => prev + curr, 0) +
            res.items?.filter(x => x.givenPriceTl > 0 && !x.budgetType).map(item => item.givenPriceTl).reduce((prev, curr) => prev + curr, 0)
            let resKdv = top > 0 ? ((top * Kdv) / 100) : 0
            let tahTop = res.items?.filter(x => x.collectionCurrencyId == 1 && !x.budgetType).map(item => item.collection).reduce((prev, curr) => prev + curr, 0) +
            res.items?.filter(x => x.collectionTl > 0 && !x.budgetType).map(item => item.collectionTl).reduce((prev, curr) => prev + curr, 0)
            let gelodtop = res.items?.filter(x => x.amount > 0).map(item => item.amount).reduce((prev, curr) => prev + curr, 0)
            setPlusAmount(gelodtop)
            let kalan =(top + resKdv) - (tahTop + gelodtop)
            let resTotal = [
                { id: 1, text: 'TOPLAM CİRO', value: numberFormat(top) + "₺" },
                { id: 2, text: `KDV`, value: numberFormat(resKdv) + "₺" },
                {
                    id: 3, text: 'TAHSİLATLAR TOPLAM', value: numberFormat(tahTop) + "₺"
                },
                { id: 4, text: 'GELEN ÖDEMELER TOPLAMI', value: numberFormat(gelodtop) + "₺" },
                { id: 6, text: 'KALAN', value: numberFormat(kalan) + "₺", color: kalan > 0 ? 'green':'red' },
            ]
            setTotal(resTotal)


            let yktop = res.items?.filter(x => x.givenPriceCurrencyId == 1 && x.budgetType).map(item => item.givenPrice).reduce((prev, curr) => prev + curr, 0) +
            res.items?.filter(x => x.givenPriceTl > 0 && x.budgetType).map(item => item.givenPriceTl).reduce((prev, curr) => prev + curr, 0)
            //setTotalBudget(res.total)
            let ykresKdv = yktop > 0 ? ((yktop * Kdv) / 100) : 0
            let tktahtop = res.items?.filter(x => x.collectionCurrencyId == 1 && x.budgetType).map(item => item.collection).reduce((prev, curr) => prev + curr, 0) +
            res.items?.filter(x => x.collectionTl > 0 && x.budgetType).map(item => item.collectionTl).reduce((prev, curr) => prev + curr, 0)
            let ykgelodtop = res.items?.filter(x => x.amount < 0).map(item => item.amount).reduce((prev, curr) => prev + curr, 0) * -1
            setNegativeAmount(ykgelodtop)
            let ykkalan =(yktop + ykresKdv) - (tktahtop + ykgelodtop)
            let ykresTotal = [
                { id: 1, text: 'TOPLAM CİRO', value: numberFormat(yktop) + "₺" },
                { id: 2, text: `KDV`, value: numberFormat(ykresKdv) + "₺" },
                {
                    id: 3, text: 'TAHSİLATLAR TOPLAM', value: numberFormat(tktahtop) + "₺"
                },
                { id: 4, text: 'GELEN ÖDEMELER TOPLAMI', value: numberFormat(ykgelodtop) + "₺" },
                { id: 6, text: 'KALAN', value: numberFormat(ykkalan) + "₺", color: ykkalan > 0 ? 'green':'red' },
            ]
            setYukYotal(ykresTotal)
        });
    }

    useEffect(() => {
        dateInfoCalculate()
    }, Dates)
    useEffect(() => {
        setHeight(ref.current.clientHeight)
        setWidth(ref.current.clientWidth)
    })
    const numberFormat = (value) =>
        new Intl.NumberFormat('tr-TR', {
            style: 'currency',
            currency: 'TRY'
        }).format(value).replace("₺", "");

    const exportPdf = () => {
        if (!Company)
            return

        savePDF(ref.current, {
            paperSize: 'A4',
            landscape:true,
            keepTogether:".keepTogether",
            fileName: `${Company.name}.pdf`,
            scale: 0.37,
        })
    }

    const clear = () => {
        setTotal(emptyTotal)
        setArchives([])
        setGenelToplam(0)
        setCompany()
        setDates()
        setStartDate()
        setEndDate()
    }

    const getDateFormat = (data) => {
        return [...data || []].map(d => {
            d.date = new moment(d.date);
            return d;
        });
    }

    const onEndDateChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        var date = new Date(val.getTime() - (val.getTimezoneOffset() * 60000)).toISOString();
        setEndDate(date);
    }

    const onStartDateChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        var date = new Date(val.getTime() - (val.getTimezoneOffset() * 60000)).toISOString();
        setStartDate(date);
    }

    const onCompanyChange = (e) => {
        const val = (e.target && e.target.value) || '';
        setCompany(val);
    }

    const collectionBudgetBodyTemplate = (data) => {
        return (
            <>
                {data.detail ? data.takenPrice + "₺" : <label style={{ color: 'green' }}>{data.takenPrice}₺</label>}
            </>
        );
    }

    const collectionPriceBodyTemplate = (data) => {
        return (
            <>
                {data.detail ? numberFormat(data.budget) + "₺" : <label style={{ color: data.amount > 0 ? 'green' : 'red' }}>{data.budget > 0 ? numberFormat(data.budget) + "₺" : ''}</label>}
            </>
        );
    }

    const givenPriceBodyTemplate = (data) => {
        if (!data.givenPrice || data.budgetType)
            return null
        var currency = Currencys.find(x => x.id === data.givenPriceCurrencyId)?.code;
        var fullPrice = numberFormat(data.givenPrice) + " " + (currency ?? "") + (data.givenPriceTl ? ` (${numberFormat(data.givenPriceTl)}₺)` : "");
        return (
            <>
                {fullPrice}
            </>
        );
    }

    const collectionPriceTlBodyTemplate = (data) => {
        if (!data.collection || data.budgetType)
            return null
        var currency = Currencys.find(x => x.id === data.collectionCurrencyId)?.code;
        var fullPrice = numberFormat(data.collection) + " " + (currency ?? "") + (data.collectionTl ? `(${numberFormat(data.collectionTl)}₺)` : "");
        return (
            <>
                {fullPrice}
            </>
        );
    }

    const givenPrice2BodyTemplate = (data) => {
        if (!data.givenPrice || !data.budgetType)
            return null
        var currency = Currencys.find(x => x.id === data.givenPriceCurrencyId)?.code;
        var fullPrice = numberFormat(data.givenPrice) + " " + (currency ?? "") + (data.givenPriceTl ? ` (${numberFormat(data.givenPriceTl)}₺)` : "");
        return (
            <>
                {fullPrice}
            </>
        );
    }

    const collectionPriceTl2BodyTemplate = (data) => {
        if (!data.collection || !data.budgetType)
            return null
        var currency = Currencys.find(x => x.id === data.collectionCurrencyId)?.code;
        var fullPrice = numberFormat(data.collection) + " " + (currency ?? "") + (data.collectionTl ? `(${numberFormat(data.collectionTl)}₺)` : "");
        return (
            <>
                {fullPrice}
            </>
        );
    }

    const collectionAmountBodyTemplate = (data) => {
        return (
            <>
                {data.detail ? data.amount != "0" ? numberFormat(data.amount) + "₺" : '' : <label style={{ color: data.amount > 0 ? 'green' : 'red' }}>{data.amount != "0" ? numberFormat(data.amount) + "₺" : ''}</label>}
            </>
        );
    }

    const dateBodyTemplate = (data) => {
        var date = data.date.format("DD-MM-YY HH:mm");
        return (
            <>
                {data.detail ? date : <label style={{ color: data.amount > 0 ? 'green' : 'red' }}>{date}</label>}
            </>
        );
    };

    const detailBodyTemplate = (data) => {
        return (
            <>
                {data.detail ? data.detail : <label style={{ color: data.amount > 0 ? 'green' : 'red', marginLeft: '50%' }}>{data.description}</label>}
            </>
        );
    }

    const dateInfoCalculate = () => {
        let info = ''
        if (Dates) {
            if (Dates[0])
                info += `${new moment(Dates[0]).format("DD/MM/YYYY")}`
            if (Dates[1])
                info += ` - ${new moment(Dates[1]).format("DD/MM/YYYY")}`
        }
        setDateInfo(info)
    }

    const totalTextBodyTemplate = (data) => {
        return (
            <>
                {data.color ? <label style={{ color: data.color }}>{data.text}</label> : data.text}
            </>
        );
    }

    const totalValueBodyTemplate = (data) => {
        return (
            <>
                {data.color ? <label style={{ color: data.color }}>{data.value}</label> : data.value}
            </>
        );
    }

    const template = (options) => {
        const toggleIcon = options.collapsed ? 'pi pi-chevron-down' : 'pi pi-chevron-up';
        const className = `${options.className} justify-content-start`;

        return (
            <div className={className}>
                <button className={options.togglerClassName} onClick={options.onTogglerClick}>
                    <span className={toggleIcon}></span>
                    <Ripple />
                </button>
            </div>
        )
    }

    const idBodyTemplate = (data) => {
        return (
            <p onClick={() => {
                setReservationId(data.reservationUId)
                setShowReservation(true)
            }} style={{ color: 'blue' }}> {data.reservationId}</p>
        );
    };

    const onInputChange = (e) => {
        setKdv(e);
    }

    return (
        <div className="p-col-12">
            <div className="Reservationd">
                <Toast ref={toast} style={{ top: 60 }} />
                <Panel headerTemplate={template} toggleable style={{ minWidth: '1024px' }}>
                    <div className="p-formgrid p-grid">
                        <div className="p-field p-col" >
                            <label style={{ marginLeft: '44px' }}>Şirket</label>
                            <Dropdown value={Company} style={{ width: '200px', marginLeft: '10px' }} filter showClear filterBy="name" options={Companys} onChange={onCompanyChange} optionLabel="name" />
                        </div>
                        <div className="p-field p-col">
                            <label >Tarih Aralığı</label>
                            <Calendar value={Dates} selectionMode="range" style={{ width: '200px', marginLeft: '10px' }} onChange={(e) => setDates(e.value)} dateFormat="dd/mm/yy" />
                        </div>
                        <div className="p-field p-col">
                            <Button icon="pi pi-search" className="p-button-rounded p-button-success" onClick={search} />
                            <Button icon="pi pi-times" className="p-button-rounded p-button-danger" style={{ marginLeft: '20px' }} onClick={clear} />
                            <Button icon="pi pi-file-pdf" className="p-button-rounded p-button-info" style={{ marginLeft: '20px' }} onClick={exportPdf} />
                        </div>
                    </div>
                </Panel>
                <div id='divToPrint' style={{ marginTop: '30px' }} className='custom-div' ref={ref}>
                    <div className="p-formgrid p-grid" style={{ borderStyle: 'groove', width: '100%', height: '85px', marginLeft: '0.01%', minWidth: '1024px' }}>
                        <div className="p-col-12 p-md-4" style={{ marginTop: '10px' }}>
                            <h5 className="p-m-0">Şirket Adı: {Company?.name ?? ""} </h5>
                        </div>
                        <div className="p-col-12 p-md-6" style={{ marginTop: '10px' }}>
                            <h5 className="p-m-0">Adres: {Company?.address ?? ""} </h5>
                        </div>
                        <div className="p-col-12 p-md-2" style={{ marginTop: '10px' }}>
                            <h5 className="p-m-0">Telefon: {Company?.phone ?? ""}</h5>
                        </div>
                        <div className="p-col-12 p-md-4" style={{ marginTop: '10px' }}>
                            <h5 className="p-m-0">Tarih Aralığı: {dateInfo} </h5>
                        </div>
                    </div>
                    <DataTable ref={dt} value={Archives} style={{ borderStyle: 'groove', marginTop: '30px', minWidth: '1024px' }} dataKey="id" className="datatable-responsive"
                        emptyMessage="Kayıt Bulunamadı">
                        <Column field="reservationId" header="Hizmet No" body={idBodyTemplate}></Column>
                        <Column field="date" header="Tarih" body={dateBodyTemplate}></Column>
                        <Column field="detail" header="Detay" body={detailBodyTemplate}></Column>
                        <Column field="description" header="Açıklama"></Column>
                        <Column field="plate" header="Plaka"></Column>
                        <Column field="driver" header="Şoför" ></Column>
                        <Column field="givenPrice" style={{ width: '150px' }} header="Bütçe" body={givenPriceBodyTemplate}></Column>
                        <Column field="collectionPrice" style={{ width: '150px' }} header="Tahsilat" body={collectionPriceTlBodyTemplate}></Column>
                        <Column field="givenPrice" style={{ width: '150px' }} header="Yüklenici Bütçe" body={givenPrice2BodyTemplate}></Column>
                        <Column field="collectionPrice" style={{ width: '150px' }} header="Yüklenici Tahsilat" body={collectionPriceTl2BodyTemplate}></Column>
                        <Column field="budget" style={{ width: '150px' }} header="Cari Durum" body={collectionPriceBodyTemplate}></Column>
                        <Column field="amount" style={{ width: '150px' }} header="Ödeme" body={collectionAmountBodyTemplate}></Column>
                    </DataTable>
                    <div className="p-formgrid p-grid p-col-12">
                        <div className="p-col-5" style={{ marginTop: '10px' }}>

                        </div>
                        <div className="p-col-2" style={{ marginTop: '10px', marginLeft: '-2%', fontWeight: 'bold', textAlign: 'right' }}>
                            {/* {`TOPLAM: ${numberFormat(Archives?.filter(x => !x.budgetType).map(item => item.givenPriceTl).reduce((prev, curr) => prev + curr, 0)) + "₺"}`} */}
                            {`TOPLAM: ${numberFormat(Archives?.filter(x => x.givenPriceCurrencyId == 1 && !x.budgetType).map(item => item.givenPrice).reduce((prev, curr) => prev + curr, 0) +
                                Archives?.filter(x => x.givenPriceTl > 0 && !x.budgetType).map(item => item.givenPriceTl).reduce((prev, curr) => prev + curr, 0))
                                }      
                            `+ "₺"}
                        </div>
                        <div className="p-col-1" style={{ marginTop: '10px', fontWeight: 'bold', textAlign: 'center' }}>
                            {`${numberFormat(Archives?.filter(x => x.collectionCurrencyId == 1 && !x.budgetType).map(item => item.collection).reduce((prev, curr) => prev + curr, 0) +
                                Archives?.filter(x => x.collectionTl > 0 && !x.budgetType).map(item => item.collectionTl).reduce((prev, curr) => prev + curr, 0))
                                }      
                            `+ "₺"}
                        </div>
                        <div className="p-col-1" style={{ marginTop: '10px', fontWeight: 'bold', textAlign: 'center' }}>
                            {/* {`${numberFormat(Archives?.filter(x => x.budgetType).map(item => item.givenPriceTl).reduce((prev, curr) => prev + curr, 0)) + "₺"}`} */}
                            {`${numberFormat(Archives?.filter(x => x.givenPriceCurrencyId == 1 && x.budgetType).map(item => item.givenPrice).reduce((prev, curr) => prev + curr, 0) +
                                Archives?.filter(x => x.givenPriceTl > 0 && x.budgetType).map(item => item.givenPriceTl).reduce((prev, curr) => prev + curr, 0))
                                }      
                            `+ "₺"}
                        </div>
                        <div className="p-col-1" style={{ marginTop: '10px', fontWeight: 'bold', textAlign: 'center' }}>
                            {`${numberFormat(Archives?.filter(x => x.collectionCurrencyId == 1 && x.budgetType).map(item => item.collection).reduce((prev, curr) => prev + curr, 0) +
                                Archives?.filter(x => x.collectionTl > 0 && x.budgetType).map(item => item.collectionTl).reduce((prev, curr) => prev + curr, 0))
                                }      
                            `+ "₺"}
                        </div>
                        <div className="p-col-2" style={{ marginTop: '10px', fontWeight: 'bold', textAlign: 'center' }}>
                        {`InterTour Ödeme: ${numberFormat(totalNegativeAmount) + "₺"}`}
                        <p></p>
                            {`${Company?.name} ödeme: ${numberFormat(totalPlusAmount) + "₺"}`}
                        </div>

                    </div>


                    <div className="p-formgrid p-grid keepTogether" style={{ marginTop: '50px', }}>
                        <DataTable value={total} style={{ minWidth: '300px', borderStyle: 'groove', marginLeft: '50px' }}>
                            <Column field="text" style={{ minWidth: '200px' }} header="Hesap Özeti" body={totalTextBodyTemplate}></Column>
                            <Column field="value" style={{ minWidth: '200px' }} body={totalValueBodyTemplate}></Column>
                        </DataTable>
                        <div className="p-field p-col" style={{ marginTop: '115px', zIndex: '10' }}>
                            <InputNumber size={3} prefix="%" tooltip="Oran Değişikliği Sonrası Tekrar Arama Yapmalısınız!" tooltipOptions={{ position: 'left' }} value={Kdv} style={{ height: '7px', marginLeft: '-370px' }} mode="decimal" min={0} max={100} onValueChange={(e) => onInputChange(e.value)} />
                        </div>
                        <DataTable value={yukYotal} style={{ minWidth: '300px', borderStyle: 'groove', marginLeft: '50px' }}>
                            <Column field="text" style={{ minWidth: '200px' }} header="Hesap Özeti" body={totalTextBodyTemplate}></Column>
                            <Column field="value" style={{ minWidth: '200px' }} body={totalValueBodyTemplate}></Column>
                        </DataTable>
                        <div className="p-field p-col" style={{ marginTop: '115px', zIndex: '10' }}>
                            <InputNumber size={3} prefix="%" tooltip="Oran Değişikliği Sonrası Tekrar Arama Yapmalısınız!" tooltipOptions={{ position: 'left' }} value={Kdv} style={{ height: '7px', marginLeft: '-370px' }} mode="decimal" min={0} max={100} onValueChange={(e) => onInputChange(e.value)} />
                        </div>
                    </div>

                    {showReservation && <ReservationDetail id={reservationId} />}
                </div>
            </div>
        </div>
    );
}
