import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import EndPointService from '../service/EndPointService'
import { InputMask } from 'primereact/inputmask';


export const ServiceTypeDefinition = () => {
    let emptyServiceType = {
        id: null,
        name: '',
        code: ''
    };

    const [ServiceTypes, setServiceTypes] = useState(null);
    const [ServiceTypeDialog, setServiceTypeDialog] = useState(false);
    const [deleteServiceTypeDialog, setDeleteServiceTypeDialog] = useState(false);
    const [deleteServiceTypesDialog, setDeleteServiceTypesDialog] = useState(false);
    const [ServiceType, setServiceType] = useState(emptyServiceType);
    const [selectedServiceTypes, setSelectedServiceTypes] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);

    const getServiceTypes = () => {
        EndPointService.get('/api/ServiceType').then(data => setServiceTypes(data));
    }

    useEffect(() => {
        getServiceTypes();
    }, []);

    const openNew = () => {
        setServiceType(emptyServiceType);
        setSubmitted(false);
        setServiceTypeDialog(true);
    }

    const hideDialog = () => {
        setSubmitted(false);
        setServiceTypeDialog(false);
    }

    const hideDeleteServiceTypeDialog = () => {
        setDeleteServiceTypeDialog(false);
    }

    const hideDeleteServiceTypesDialog = () => {
        setDeleteServiceTypesDialog(false);
    }

    const saveServiceType = async () => {
        setSubmitted(true);
        if (ServiceType.name.trim()) {
            let _ServiceType = { ...ServiceType };
            if (ServiceType.id) {
                try {
                    let updateBody = {
                        name: ServiceType.name,
                        code: ServiceType.code
                    }
                    await EndPointService.put('/api/ServiceType?id=' + _ServiceType.id, updateBody)
                    toast.current.show({ severity: 'success', summary: 'Başarılı', detail: 'Servis Tipi Güncellendi', life: 3000 });
                } catch (error) {
                    toast.current.show({ severity: 'error', summary: 'Hata', detail: 'Servis Tipi Güncellenirken Hata Oluştu', life: 3000 });
                }
            }
            else {
                try {
                    await EndPointService.post('/api/ServiceType', _ServiceType)
                    toast.current.show({ severity: 'success', summary: 'Başarılı', detail: 'Servis Tipi Eklendi', life: 3000 });
                } catch (error) {
                    toast.current.show({ severity: 'error', summary: 'Hata', detail: 'Servis Tipi Eklerken Hata Oluştu', life: 3000 });
                }
            }

            getServiceTypes();
            setServiceTypeDialog(false);
            setServiceType(emptyServiceType);
        }
    }

    const editServiceType = (ServiceType) => {
        setServiceType({ ...ServiceType });
        setServiceTypeDialog(true);
    }

    const confirmDeleteServiceType = (ServiceType) => {
        setServiceType(ServiceType);
        setDeleteServiceTypeDialog(true);
    }

    const deleteServiceType = async () => {
        try {
            await EndPointService.delete('/api/ServiceType?id=' + ServiceType.id)
            toast.current.show({ severity: 'success', summary: 'Başarılı', detail: 'Servis Tipi Silindi', life: 3000 });
        } catch (error) {
            toast.current.show({ severity: 'error', summary: 'Hata', detail: 'Servis Tipi Silinirken Hata Oluştu', life: 3000 });
        }
        getServiceTypes();
        setDeleteServiceTypeDialog(false);
        setServiceType(emptyServiceType);
    }

    const confirmDeleteSelected = () => {
        setDeleteServiceTypesDialog(true);
    }

    const deleteSelectedServiceTypes = async () => {
        try {
            await selectedServiceTypes.map(async ServiceType => {
                await EndPointService.delete('/api/ServiceType?id=' + ServiceType.id)
            })
            toast.current.show({ severity: 'success', summary: 'Başarılı', detail: 'Servis Tipleri Silindi', life: 3000 });
        } catch (error) {
            toast.current.show({ severity: 'error', summary: 'Hata', detail: 'Servis Tipleri Silinirken Hata Oluştu', life: 3000 });
        }
        getServiceTypes();
        setDeleteServiceTypesDialog(false);
        setSelectedServiceTypes(null);
        setServiceType(emptyServiceType);
    }

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _ServiceType = { ...ServiceType };
        _ServiceType[`${name}`] = val;

        setServiceType(_ServiceType);
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success p-mr-2" onClick={() => editServiceType(rowData)} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-warning" onClick={() => confirmDeleteServiceType(rowData)} />
            </div>
        );
    }

    const header = (
        <div className="table-header">
            <h5 className="p-m-0">Servis Tipleri</h5>
            <span className="p-input-icon-left">
                <React.Fragment>
                    <InputText type="search" style={{ height: '42px', marginRight: '10px', marginBottom: '7px' }} onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Ara..." />
                    <Button label="Ekle" icon="pi pi-plus" className="p-button-success p-mr-2 p-mb-2" onClick={openNew} />
                    <Button label="Sil" icon="pi pi-trash" className="p-button-danger p-mb-2" onClick={confirmDeleteSelected} disabled={!selectedServiceTypes || !selectedServiceTypes.length} />
                </React.Fragment>
            </span>
        </div>
    );

    const ServiceTypeDialogFooter = (
        <>
            <Button label="İptal" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Kaydet" icon="pi pi-check" className="p-button-text" onClick={saveServiceType} />
        </>
    );
    const deleteServiceTypeDialogFooter = (
        <>
            <Button label="Hayır" icon="pi pi-times" className="p-button-text" onClick={hideDeleteServiceTypeDialog} />
            <Button label="Evet" icon="pi pi-check" className="p-button-text" onClick={deleteServiceType} />
        </>
    );
    const deleteServiceTypesDialogFooter = (
        <>
            <Button label="Hayır" icon="pi pi-times" className="p-button-text" onClick={hideDeleteServiceTypesDialog} />
            <Button label="Evet" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedServiceTypes} />
        </>
    );

    return (
        <div className="p-grid crud-demo">
            <div className="p-col-12">
                <div className="card">
                    <Toast ref={toast} style={{ top: 60 }} />
                    <DataTable ref={dt} value={ServiceTypes} selection={selectedServiceTypes} selectionPageOnly onSelectionChange={(e) => setSelectedServiceTypes(e.value)}
                        dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Gösterilen {first} ve {last} Toplam {totalRecords} Servis Tipi İçinden"
                        globalFilter={globalFilter} emptyMessage="No ServiceTypes found." header={header}>
                        <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
                        <Column field="id" header="id" style={{ display: 'none' }} sortable></Column>
                        <Column field="name" header="İsim" filter filterField="name" showFilterMenu={false} sortable></Column>
                        <Column field="code" header="Hizmet Kodu" filter filterField="code" showFilterMenu={false} sortable></Column>
                        <Column body={actionBodyTemplate}></Column>
                    </DataTable>

                    <Dialog visible={ServiceTypeDialog} style={{ width: '450px' }} header="Servis Tipi Detayları" modal className="p-fluid" footer={ServiceTypeDialogFooter} onHide={hideDialog}>
                        <div className="p-field">
                            <label htmlFor="name">İsim</label>
                            <InputText id="name" value={ServiceType.name} onChange={(e) => onInputChange(e, 'name')} required autoFocus className={classNames({ 'p-invalid': submitted && !ServiceType.name })} />
                            {submitted && !ServiceType.name && <small className="p-invalid">İsim zorunlu.</small>}
                        </div>
                        <div className="p-field">
                            <label htmlFor="code">Hizmet Kodu</label>
                            <InputText id="code" value={ServiceType.code} onChange={(e) => onInputChange(e, 'code')} required className={classNames({ 'p-invalid': submitted && !ServiceType.code })} />
                            {submitted && !ServiceType.code && <small className="p-invalid">Hizmet Kodu zorunlu.</small>}
                        </div>
                    </Dialog>

                    <Dialog visible={deleteServiceTypeDialog} style={{ width: '450px' }} header="Onay" modal footer={deleteServiceTypeDialogFooter} onHide={hideDeleteServiceTypeDialog}>
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                            {ServiceType && <span>Silmek istediğinize emin misiniz <b>{ServiceType.name}</b>?</span>}
                        </div>
                    </Dialog>

                    <Dialog visible={deleteServiceTypesDialog} style={{ width: '450px' }} header="Onay" modal footer={deleteServiceTypesDialogFooter} onHide={hideDeleteServiceTypesDialog}>
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                            {ServiceType && <span>Seçilen kayıtları silmek istediğinize emin misiniz?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
}
