import axios from 'axios';

export const setAuthorizationToken = token => {
  if (token) {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    axios.interceptors.response.use(
      response => {
        return response;
      },
      err => {
        return new Promise((resolve, reject) => {
          if (err?.response?.status === 401 && err.config && !err.config.__isRetryRequest) {
            window.location = '/login';
          }
          throw err;
        });
      }
    );
  }
  else
    delete axios.defaults.headers.common["Authorization"];
}
