import React, { useState, useCallback, useEffect } from 'react';
import { NavLink } from 'react-router-dom'
import classNames from 'classnames';
import { CSSTransition } from 'react-transition-group';
import { Ripple } from 'primereact/ripple';

const AppSubmenu = (props) => {

	const [activeIndex, setActiveIndex] = useState(null);

	const onMenuItemClick = (event, item, index) => {
		//avoid processing disabled items
		if (item.disabled) {
			event.preventDefault();
			return true;
		}

		if (props.root && props.onRootItemClick) {
			props.onRootItemClick({
				originalEvent: event,
				item: item
			});
		}

		//execute command
		if (item.command) {
			item.command({ originalEvent: event, item: item });
			event.preventDefault();
		}

		if (item.items) {
			setActiveIndex(activeIndex === index ? null : index);
		}

		if (props.onMenuItemClick) {
			props.onMenuItemClick({
				originalEvent: event,
				item: item
			});
		}

		
	}

	const onKeyDown = (event, item, index) => {
		if (event.key === 'Enter') {
			onMenuItemClick(event, item, index);
		}
	}

	const onMenuItemMouseEnter = (index) => {
		if (props.root && props.menuActive && isHorizontal() && !isMobile()) {
			setActiveIndex(index)
		}
	}

	const isHorizontalOrSlim = useCallback(() => {
		return (props.layoutMode === 'horizontal' || props.layoutMode === 'slim');
	}, [props.layoutMode]);

	const isMobile = useCallback(() => {
		return window.innerWidth < 1025;
	}, []);

	useEffect(() => {
		if (!props.menuActive && isHorizontalOrSlim() && !isMobile()) {
			setActiveIndex(null);
		}
	}, [props.menuActive, isHorizontalOrSlim, isMobile]);

	const isHorizontal = () => {
		return (props.layoutMode === 'horizontal');
	}


	const isSlim = () => {
		return props.layoutMode === 'slim';
	}

	const renderLinkContent = (item) => {
		let submenuIcon = item.items && <i className="pi pi-angle-down layout-submenu-toggler"></i>;
		let badge = item.badge && <span className="menuitem-badge">{item.badge}</span>;

		return (
			<React.Fragment>
				<i className={classNames(item.icon, 'layout-menuitem-icon')}></i>
				<span className="menuitem-text">{item.label}</span>
				{badge}
				{submenuIcon}
			</React.Fragment>
		);
	}

	const renderLink = (item, i) => {
		let content = renderLinkContent(item);
		let linkStyle = classNames(item.className, 'p-ripple');

		if (item.to) {
			return (
				<NavLink activeClassName="router-link-active" to={item.to}
					onClick={(e) => onMenuItemClick(e, item, i)} exact role="menuitem"
					target={item.target} onMouseEnter={(e) => onMenuItemMouseEnter(i)}
					className={linkStyle}>{content}
					<Ripple />
				</NavLink>
			)
		} else {
			return (
				<a className={linkStyle} role="menuitem" href={item.url} tabIndex={item.url ? '' : 0}
					onClick={(e) => onMenuItemClick(e, item, i)} target={item.target} onKeyDown={(e) => onKeyDown(e, item, i)}
					onMouseEnter={(e) => onMenuItemMouseEnter(i)}>
					{content}
					<Ripple />
				</a>
			);

		}
	}


	var items = props.items && props.items.map((item, i) => {
		let active = activeIndex === i;
		let styleClass = classNames(item.badgeStyleClass, { 'active-menuitem': active }, { 'layout-root-menuitem': props.root });
		let tooltip = props.root && <div className='layout-menuitem-root-text' style={{ textTransform: 'uppercase' }}>
			{!props.menuActive && props.layoutMode !== 'static' && !isMobile() && <i className="pi pi-ellipsis-h"></i>}
			{item.label}
		</div>;

		return <li className={styleClass} key={i} role="none">
			{renderLink(item, i)}
			{tooltip}
			<CSSTransition classNames="layout-submenu-container" timeout={{ enter: 400, exit: 400 }} in={item.items && (props.root && !((isHorizontal() || isSlim()) && !isMobile && (!isSlim() || (isSlim() && activeIndex !== null))) ? true : active)} unmountOnExit>
				<AppSubmenu items={item.items} onMenuItemClick={props.onMenuItemClick}
					layoutMode={props.layoutMode}
					menuActive={props.menuActive} parentMenuItemActive={active} />
			</CSSTransition>
		</li>
	});

	return <ul role="menu" className={props.className}>{items}</ul>;


}

const AppMenu = (props) => {

	return (
		<AppSubmenu items={props.model} className="layout-menu" menuActive={props.active}
			onMenuItemClick={props.onMenuItemClick} onRootItemClick={props.onRootMenuItemClick}
			root={true} layoutMode={props.layoutMode} parentMenuItemActive={true} />
	);

}

export default AppMenu;
