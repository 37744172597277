import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import EndPointService from '../service/EndPointService'
import { Dropdown } from 'primereact/dropdown';


export const DriverCarsDefinition = () => {
    let emptyDriverCars = {
        id: null,
        carId: 0,
        driverId: 0,
        driver: {},
        car: {},
    };

    const [Cars, setCars] = useState(null);
    const [Drivers, setDrivers] = useState(null);
    const [DriverCarss, setDriverCarss] = useState(null);
    const [DriverCarsDialog, setDriverCarsDialog] = useState(false);
    const [deleteDriverCarsDialog, setDeleteDriverCarsDialog] = useState(false);
    const [deleteDriverCarssDialog, setDeleteDriverCarssDialog] = useState(false);
    const [DriverCars, setDriverCars] = useState(emptyDriverCars);
    const [selectedDriverCarss, setSelectedDriverCarss] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);

    const getDriverCarss = () => {
        EndPointService.get('/api/DriverCars').then(data => setDriverCarss(data));
        EndPointService.get('/api/Car').then(data => setCars(data));
        EndPointService.get('/api/Driver').then(data => setDrivers(data));
    }

    useEffect(() => {
        getDriverCarss();
    }, []);

    const openNew = () => {
        setDriverCars(emptyDriverCars);
        setSubmitted(false);
        setDriverCarsDialog(true);
    }

    const hideDialog = () => {
        setSubmitted(false);
        setDriverCarsDialog(false);
    }

    const hideDeleteDriverCarsDialog = () => {
        setDeleteDriverCarsDialog(false);
    }

    const hideDeleteDriverCarssDialog = () => {
        setDeleteDriverCarssDialog(false);
    }

    const saveDriverCars = async () => {
        setSubmitted(true);
        if ((DriverCars.carId || DriverCars.car.id) && (DriverCars.driverId || DriverCars.driver.id)) {
            let _DriverCars = { ...DriverCars };
            if (DriverCars.id) {
                try {
                    let updateBody = {
                        carId: DriverCars.car.id,
                        driverId: DriverCars.driver.id,
                    }
                    await EndPointService.put('/api/DriverCars?id=' + _DriverCars.id, updateBody)
                    toast.current.show({ severity: 'success', summary: 'Başarılı', detail: 'Araç Sürücüsü Güncellendi', life: 3000 });
                } catch (error) {
                    toast.current.show({ severity: 'error', summary: 'Hata', detail: 'Araç Sürücüsü Güncellenirken Hata Oluştu', life: 3000 });
                }
            }
            else {
                let addBody = {
                    carId: DriverCars.carId,
                    driverId: DriverCars.driverId,
                }
                try {
                    await EndPointService.post('/api/DriverCars', addBody)
                    toast.current.show({ severity: 'success', summary: 'Başarılı', detail: 'Araç Sürücüsü Eklendi', life: 3000 });
                } catch (error) {
                    toast.current.show({ severity: 'error', summary: 'Hata', detail: 'Araç Sürücüsü Eklerken Hata Oluştu', life: 3000 });
                }
            }

            getDriverCarss();
            setDriverCarsDialog(false);
            setDriverCars(emptyDriverCars);
        }
    }

    const editDriverCars = (DriverCars) => {
        setDriverCars({ ...DriverCars });
        setDriverCarsDialog(true);
    }

    const confirmDeleteDriverCars = (DriverCars) => {
        setDriverCars(DriverCars);
        setDeleteDriverCarsDialog(true);
    }

    const deleteDriverCars = async () => {
        try {
            await EndPointService.delete('/api/DriverCars?id=' + DriverCars.id)
            toast.current.show({ severity: 'success', summary: 'Başarılı', detail: 'Araç Sürücüsü Silindi', life: 3000 });
        } catch (error) {
            toast.current.show({ severity: 'error', summary: 'Hata', detail: 'Araç Sürücüsü Silinirken Hata Oluştu', life: 3000 });
        }
        getDriverCarss();
        setDeleteDriverCarsDialog(false);
        setDriverCars(emptyDriverCars);
    }

    const confirmDeleteSelected = () => {
        setDeleteDriverCarssDialog(true);
    }

    const deleteSelectedDriverCarss = async () => {
        try {
            await selectedDriverCarss.map(async DriverCars => {
                await EndPointService.delete('/api/DriverCars?id=' + DriverCars.id)
            })
            toast.current.show({ severity: 'success', summary: 'Başarılı', detail: 'Araç Sürücüleri Silindi', life: 3000 });
        } catch (error) {
            toast.current.show({ severity: 'error', summary: 'Hata', detail: 'Araç Sürücüleri Silinirken Hata Oluştu', life: 3000 });
        }
        getDriverCarss();
        setDeleteDriverCarssDialog(false);
        setSelectedDriverCarss(null);
        setDriverCars(emptyDriverCars);
    }

    const CarBodyTemplate = (data) => {
        var plate = data.car.plate
        return (
            <>
                {plate}
            </>
        );
    };

    const DriverBodyTemplate = (data) => {
        var name = data.driver.name
        return (
            <>
                {name}
            </>
        );
    };

    const onCarChange = (e) => {
        const val = (e.target && e.target.value) || '';
        let _DriverCars = { ...DriverCars };
        _DriverCars.car = val;
        _DriverCars.carId = val.id;
        setDriverCars(_DriverCars);
    }

    const onDriverChange = (e) => {
        const val = (e.target && e.target.value) || '';
        let _DriverCars = { ...DriverCars };
        _DriverCars.driver = val;
        _DriverCars.driverId = val.id;
        setDriverCars(_DriverCars);
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success p-mr-2" onClick={() => editDriverCars(rowData)} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-warning" onClick={() => confirmDeleteDriverCars(rowData)} />
            </div>
        );
    }

    const header = (
        <div className="table-header">
            <h5 className="p-m-0">Araç Sürücüleri</h5>
            <span className="p-input-icon-left">
                <React.Fragment>
                    <InputText type="search" style={{ height: '42px', marginRight: '10px', marginBottom: '7px' }} onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Ara..." />
                    <Button label="Ekle" icon="pi pi-plus" className="p-button-success p-mr-2 p-mb-2" onClick={openNew} />
                    <Button label="Sil" icon="pi pi-trash" className="p-button-danger p-mb-2" onClick={confirmDeleteSelected} disabled={!selectedDriverCarss || !selectedDriverCarss.length} />
                </React.Fragment>
            </span>
        </div>
    );

    const DriverCarsDialogFooter = (
        <>
            <Button label="İptal" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Kaydet" icon="pi pi-check" className="p-button-text" onClick={saveDriverCars} />
        </>
    );
    const deleteDriverCarsDialogFooter = (
        <>
            <Button label="Hayır" icon="pi pi-times" className="p-button-text" onClick={hideDeleteDriverCarsDialog} />
            <Button label="Evet" icon="pi pi-check" className="p-button-text" onClick={deleteDriverCars} />
        </>
    );
    const deleteDriverCarssDialogFooter = (
        <>
            <Button label="Hayır" icon="pi pi-times" className="p-button-text" onClick={hideDeleteDriverCarssDialog} />
            <Button label="Evet" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedDriverCarss} />
        </>
    );

    return (
        <div className="p-grid crud-demo">
            <div className="p-col-12">
                <div className="DriverCarsd">
                    <Toast ref={toast} style={{ top: 60 }} />
                    <DataTable ref={dt} value={DriverCarss} selection={selectedDriverCarss} selectionPageOnly onSelectionChange={(e) => setSelectedDriverCarss(e.value)}
                        dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Gösterilen {first} ve {last} Toplam {totalRecords} Araç Sürücüsü İçinden"
                        globalFilter={globalFilter} emptyMessage="No DriverCars found." header={header}>
                        <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
                        <Column field="id" header="id" style={{ display: 'none' }} sortable></Column>
                        <Column field="Driver" filter filterField="driver.name" showFilterMenu={false} header="Sürücü" body={DriverBodyTemplate} sortField='driver.name' sortable></Column>
                        <Column field="Car" filter filterField="car.plate" showFilterMenu={false} header="Araç" body={CarBodyTemplate} sortField="car.plate" sortable></Column>
                        <Column body={actionBodyTemplate}></Column>
                    </DataTable>

                    <Dialog visible={DriverCarsDialog} style={{ width: '450px' }} header="Araç Sürücüsü Detayları" modal className="p-fluid" footer={DriverCarsDialogFooter} onHide={hideDialog}>
                        <div className="p-field">
                            <label htmlFor="carId">Araç</label>
                            <Dropdown value={DriverCars.car} options={Cars} filter showClear filterBy="plate" onChange={onCarChange} optionLabel="plate" />
                            {submitted && !DriverCars.carId && <small className="p-invalid">Araç zorunlu.</small>}
                        </div>
                        <div className="p-field">
                            <label htmlFor="driverId">Sürücü</label>
                            <Dropdown value={DriverCars.driver} filter showClear filterBy="name" options={Drivers} onChange={onDriverChange} optionLabel="name" />
                            {submitted && !DriverCars.driverId && <small className="p-invalid">Sürücü zorunlu.</small>}
                        </div>
                    </Dialog>

                    <Dialog visible={deleteDriverCarsDialog} style={{ width: '450px' }} header="Onay" modal footer={deleteDriverCarsDialogFooter} onHide={hideDeleteDriverCarsDialog}>
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                            {DriverCars && <span>Silmek istediğinize emin misiniz?</span>}
                        </div>
                    </Dialog>

                    <Dialog visible={deleteDriverCarssDialog} style={{ width: '450px' }} header="Onay" modal footer={deleteDriverCarssDialogFooter} onHide={hideDeleteDriverCarssDialog}>
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                            {DriverCars && <span>Seçilen kayıtları silmek istediğinize emin misiniz?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
}
