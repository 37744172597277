import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import EndPointService from '../service/EndPointService'
import { InputMask } from 'primereact/inputmask';


export const CompanyDefinition = () => {
    let emptyCompany = {
        id: null,
        name: '',
        phone: '',
        bank:'',
        address: null,
        iban: null
    };

    const [Companys, setCompanys] = useState(null);
    const [CompanyDialog, setCompanyDialog] = useState(false);
    const [deleteCompanyDialog, setDeleteCompanyDialog] = useState(false);
    const [deleteCompanysDialog, setDeleteCompanysDialog] = useState(false);
    const [Company, setCompany] = useState(emptyCompany);
    const [selectedCompanys, setSelectedCompanys] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);

    const getCompanys = () => {
        EndPointService.get(`/api/Company`).then(data => {
            setCompanys(data);
        });
    }

    useEffect(() => {
        getCompanys();
    }, [])

    const openNew = () => {
        setCompany(emptyCompany);
        setSubmitted(false);
        setCompanyDialog(true);
    }

    const hideDialog = () => {
        setSubmitted(false);
        setCompanyDialog(false);
    }

    const hideDeleteCompanyDialog = () => {
        setDeleteCompanyDialog(false);
    }

    const hideDeleteCompanysDialog = () => {
        setDeleteCompanysDialog(false);
    }

    const saveCompany = async () => {
        setSubmitted(true);
        if (Company.name.trim()) {
            let _Company = { ...Company };
            if (Company.id) {
                try {
                    let updateBody = {
                        name: Company.name,
                        phone: Company.phone,
                        address: Company.address,
                        iban: Company.iban,
                        bank: Company.bank
                    }
                    await EndPointService.put('/api/Company?id=' + _Company.id, updateBody)
                    toast.current.show({ severity: 'success', summary: 'Başarılı', detail: 'Şirket Güncellendi', life: 3000 });
                } catch (error) {
                    toast.current.show({ severity: 'error', summary: 'Hata', detail: 'Şirket Güncellenirken Hata Oluştu', life: 3000 });
                }
            }
            else {
                _Company.id = 0;
                try {
                    await EndPointService.post('/api/Company', _Company)
                    toast.current.show({ severity: 'success', summary: 'Başarılı', detail: 'Şirket Eklendi', life: 3000 });
                } catch (error) {
                    toast.current.show({ severity: 'error', summary: 'Hata', detail: 'Şirket Eklerken Hata Oluştu', life: 3000 });
                }
            }

            getCompanys();
            setCompanyDialog(false);
            setCompany(emptyCompany);
        }
    }

    const editCompany = (Company) => {
        setCompany({ ...Company });
        setCompanyDialog(true);
    }

    const confirmDeleteCompany = (Company) => {
        setCompany(Company);
        setDeleteCompanyDialog(true);
    }

    const deleteCompany = async () => {
        try {
            await EndPointService.delete('/api/Company?id=' + Company.id)
            toast.current.show({ severity: 'success', summary: 'Başarılı', detail: 'Şirket Silindi', life: 3000 });
        } catch (error) {
            toast.current.show({ severity: 'error', summary: 'Hata', detail: 'Şirket Silinirken Hata Oluştu', life: 3000 });
        }
        getCompanys();
        setDeleteCompanyDialog(false);
        setCompany(emptyCompany);
    }

    const confirmDeleteSelected = () => {
        setDeleteCompanysDialog(true);
    }

    const deleteSelectedCompanys = async () => {
        try {
            await selectedCompanys.map(async company => {
                await EndPointService.delete('/api/Company?id=' + company.id)
            })
            toast.current.show({ severity: 'success', summary: 'Başarılı', detail: 'Şirketler Silindi', life: 3000 });
        } catch (error) {
            toast.current.show({ severity: 'error', summary: 'Hata', detail: 'Şirketler Silinirken Hata Oluştu', life: 3000 });
        }
        getCompanys();
        setDeleteCompanysDialog(false);
        setSelectedCompanys(null);
        setCompany(emptyCompany);
    }

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _Company = { ...Company };
        _Company[`${name}`] = val;

        setCompany(_Company);
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success p-mr-2" onClick={() => editCompany(rowData)} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-warning" onClick={() => confirmDeleteCompany(rowData)} />
            </div>
        );
    }

    const header = (
        <div className="table-header">
            <h5 className="p-m-0">Şirketler</h5>
            <span className="p-input-icon-left">
                <React.Fragment>
                    <InputText type="search" style={{ height: '42px', marginRight: '10px', marginBottom: '7px' }} onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Ara..." />
                    <Button label="Ekle" icon="pi pi-plus" className="p-button-success p-mr-2 p-mb-2" onClick={openNew} />
                    <Button label="Sil" icon="pi pi-trash" className="p-button-danger p-mb-2" onClick={confirmDeleteSelected} disabled={!selectedCompanys || !selectedCompanys.length} />
                </React.Fragment>
            </span>
        </div>
    );

    const CompanyDialogFooter = (
        <>
            <Button label="İptal" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Kaydet" icon="pi pi-check" className="p-button-text" onClick={saveCompany} />
        </>
    );
    const deleteCompanyDialogFooter = (
        <>
            <Button label="Hayır" icon="pi pi-times" className="p-button-text" onClick={hideDeleteCompanyDialog} />
            <Button label="Evet" icon="pi pi-check" className="p-button-text" onClick={deleteCompany} />
        </>
    );
    const deleteCompanysDialogFooter = (
        <>
            <Button label="Hayır" icon="pi pi-times" className="p-button-text" onClick={hideDeleteCompanysDialog} />
            <Button label="Evet" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedCompanys} />
        </>
    );

    return (
        <div className="p-grid crud-demo">
            <div className="p-col-12">
                <div className="card">
                    <Toast ref={toast} style={{ top: 60 }} />
                    <DataTable ref={dt} value={Companys} selection={selectedCompanys} selectionPageOnly onSelectionChange={(e) => setSelectedCompanys(e.value)}
                        dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Gösterilen {first} ve {last} Toplam {totalRecords} Şirket İçinden"
                        globalFilter={globalFilter} emptyMessage="No Companys found." header={header}>
                        <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
                        <Column field="id" header="id" style={{ display: 'none' }} sortable></Column>
                        <Column field="name" filter filterField="name" showFilterMenu={false} header="İsim" sortable></Column>
                        <Column field="phone" filter filterField="phone" showFilterMenu={false} header="Telefon" sortable></Column>
                        <Column field="address" filter filterField="address" showFilterMenu={false} header="Adres" sortable></Column>
                        <Column field="bank" header="Banka" sortable></Column>
                        <Column field="iban" header="Iban" sortable></Column>
                        <Column body={actionBodyTemplate}></Column>
                    </DataTable>

                    <Dialog visible={CompanyDialog} style={{ width: '450px' }} header="Şirket Detayları" modal className="p-fluid" footer={CompanyDialogFooter} onHide={hideDialog}>
                        <div className="p-field">
                            <label htmlFor="name">İsim</label>
                            <InputText id="name" value={Company.name} onChange={(e) => onInputChange(e, 'name')} required autoFocus className={classNames({ 'p-invalid': submitted && !Company.name })} />
                            {submitted && !Company.name && <small className="p-invalid">İsim zorunlu.</small>}
                        </div>
                        <div className="p-field">
                            <label htmlFor="phone">Telefon</label>
                            <InputText id="phone" value={Company.phone} onChange={(e) => onInputChange(e, 'phone')} />
                        </div>
                        <div className="p-field">
                            <label htmlFor="address">Adres</label>
                            <InputTextarea id="address" value={Company.address} onChange={(e) => onInputChange(e, 'address')} rows={3} cols={20} autoResize />
                        </div>
                        <div className="p-field">
                            <label htmlFor="bank">Banka</label>
                            <InputText id="bank" value={Company.bank} onChange={(e) => onInputChange(e, 'bank')} />
                        </div>
                        <div className="p-field">
                            <label htmlFor="iban">Iban</label>
                            <InputText id="iban" value={Company.iban} onChange={(e) => onInputChange(e, 'iban')} />
                        </div>
                    </Dialog>

                    <Dialog visible={deleteCompanyDialog} style={{ width: '450px' }} header="Onay" modal footer={deleteCompanyDialogFooter} onHide={hideDeleteCompanyDialog}>
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                            {Company && <span>Silmek istediğinize emin misiniz <b>{Company.name}</b>?</span>}
                        </div>
                    </Dialog>

                    <Dialog visible={deleteCompanysDialog} style={{ width: '450px' }} header="Onay" modal footer={deleteCompanysDialogFooter} onHide={hideDeleteCompanysDialog}>
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                            {Company && <span>Seçilen kayıtları silmek istediğinize emin misiniz?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
}
