import axios from 'axios';
import { setAuthorizationToken } from '../helpers/setAuthorizationToken';
import configData from "../config.json";

const login = async (email, password) => {
    const tokenBody = {
        email: email,
        password: password,
        rememberMe: true
    };
    await axios.post(`${configData.ApiUrl}/api/Account/Login`, tokenBody)
        .then(response => {
            const { token } = response.data
            localStorage.setItem("jwtToken", token);
            setAuthorizationToken(token);
        })
        .catch(err => {
            const res = {
                message: "Giriş Yapılamadı"
            }
            return res
        });
    return axios.get(`${configData.ApiUrl}/api/Account`).then(res => {
        return res.data;
    })
}

const signInWithToken = async () => {
    await axios.get(`${configData.ApiUrl}/api/Account`)
        .then(response => {
            if (response.data) {
                const token = localStorage.getItem("jwtToken")
                setAuthorizationToken(token);
                return response;
            }
            const res = {
                message: "Giriş Yapılamadı"
            }
            return null
        })
        .catch(err => {
            const res = {
                message: "Giriş Yapılamadı"
            }
            return null
        });
    return axios.get(`${configData.ApiUrl}/api/Account`).then(res => {
        return res.data;
    })
};

const logout = () => {
    localStorage.removeItem("jwtToken");
    setAuthorizationToken(false);
}

export default { login, logout, signInWithToken };
