import React, { useState, useEffect, useRef } from 'react';
import { Panel } from 'primereact/panel';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import EndPointService from '../service/EndPointService'
import { useHistory } from 'react-router-dom';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { RadioButton } from 'primereact/radiobutton';
import { InputNumber } from 'primereact/inputnumber';
import { DatePicker, Space } from 'antd';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Reservation } from '../pages/Reservation';
import moment from 'moment';

const eventDateFormat = {
    hour: '2-digit',
    minute: '2-digit',
    meridiem: false
}

export const Dashboard = () => {
    let emptyReservation = {
        id: 0,
        giverCompanyId: 0,
        giverCompany: null,
        takerCompany: null,
        takerCompanyId: 0,
        givenPrice: 0,
        takenPrice: 0,
        carId: 0,
        driverId: 0,
        serviceTypeId: 0,
        givenPriceCurrencyId: 1,
        takenPriceCurrencyId: 1,
        collectionCurrencyId: 1,
        isFromPassenger: false,
        isFromAgency: false,
        departure: '',
        arrival: '',
        date: '',
        completedDate: '',
        description: null,
        collection: 0
    };
    let p = {
        id: 0,
        status: null
    }
    const [Companys, setCompanys] = useState(null);
    const [Cars, setCars] = useState(null);
    const [Drivers, setDrivers] = useState(null);
    const [RezProps, setRezProps] = useState(p)
    const [Reservations, setReservations] = useState(null);
    const [ReservationsCount, setReservationsCount] = useState(null);
    const [DoneReservationsCount, setDoneReservationsCount] = useState(null);
    const [WaitingDoneReservationsCount, setWaitingDoneReservationsCount] = useState(null);
    const [WaitingReservationsCount, setWaitingReservationsCount] = useState(null);
    const [ProcessReservationsCount, setProcessReservationsCount] = useState(null);
    const [DasReservation, setReservation] = useState(emptyReservation);
    const [ReservationDialog, setReservationDialog] = useState(false);
    const [Currencys, setCurrencys] = useState([]);
    const history = useHistory()

    useEffect(() => {
        EndPointService.get('/api/Currency').then(res => { setCurrencys(res) });
        // EndPointService.get(`/api/Company`).then(data => { setCompanys(data.totalCount); });
        // EndPointService.get('/api/Car').then(data => setCars(data.totalCount));
        // EndPointService.get('/api/Driver').then(data => { setDrivers(data.totalCount) });
        EndPointService.get('/api/Reservation').then(data => {
            var event = data.map(res => ({
                id: res.id,
                title: (res.car ? res.car?.plate : "") + " " + res.departure + "-" + res.arrival,
                start: res.date,
                end: res.completedDate
            }))
            setReservations(event)
        });
        EndPointService.post('/api/Reservation/GetReservationInfo').then(res => {
            setReservationsCount(res.total)
            setWaitingDoneReservationsCount(res.waitingDone)
            setDoneReservationsCount(res.completed)
            setWaitingReservationsCount(res.waiting)
            setProcessReservationsCount(res.process)
        });
        document.getElementsByClassName("p-panel-title")[0].innerText = 'Takvim'
    }, [])

    const eventClick = (data) => {
        let p = {
            id: data.event.id
        }
        setRezProps(p)
        // EndPointService.get(`/api/Reservation/${data.event.id}`).then(res => {
        //     res.date = new moment(res.date);
        //     res.completedDate = new moment(res.completedDate);
        //     setReservation(res)
        //     setReservationDialog(true)
        // });
    }

    const hideDialog = () => {
        setReservationDialog(false);
    }

    const ReservationDialogFooter = (
        <>
            <Button label="İptal" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
        </>
    );

    const divClick = (status) => {
        let p = {
            status: status
        }
        setRezProps(p)
    }

    return <div className="dashboard">
        <div className="p-grid">
            <div className="p-col" onClick={() => divClick(2)} style={{cursor: 'pointer'}}>
                <div className="overview-box overview-box-1">
                    <div className="overview-box-header"></div>
                    <div className="overview-box-content">
                        <div className="overview-box-icon"><i className="pi pi-clock"></i></div>
                        <div className="overview-box-title">Bekleyen</div>
                        <div className="overview-box-title" style={{ fontSize: '19px' }}>{WaitingReservationsCount}</div>
                    </div>
                </div>
            </div>
            <div className="p-col" onClick={() => divClick(1)} style={{cursor: 'pointer'}}>
                <div className="overview-box overview-box-2">
                    <div className="overview-box-header"></div>
                    <div className="overview-box-content">
                        <div className="overview-box-icon"><i className="pi pi-car"></i></div>
                        <div className="overview-box-title">İşlemde</div>
                        <div className="overview-box-title" style={{ fontSize: '19px' }}>{ProcessReservationsCount}</div>
                    </div>
                </div>
            </div>
            <div className="p-col" onClick={() => divClick(3)} style={{cursor: 'pointer'}}>
                <div className="overview-box overview-box-4">
                    <div className="overview-box-header"></div>
                    <div className="overview-box-content">
                        <div className="overview-box-icon"><i className="pi pi-chart-line"></i></div>
                        <div className="overview-box-title">Onay Bekleyen</div>
                        <div className="overview-box-title" style={{ fontSize: '19px' }}>{WaitingDoneReservationsCount ?? 0}</div>
                    </div>
                </div>
            </div>
            <div className="p-col" onClick={() => divClick(4)} style={{cursor: 'pointer'}}>
                <div className="overview-box overview-box-3">
                    <div className="overview-box-header"></div>
                    <div className="overview-box-content">
                        <div className="overview-box-icon"><i className="pi pi-check-square"></i></div>
                        <div className="overview-box-title">Tamamlanmış</div>
                        <div className="overview-box-title" style={{ fontSize: '19px' }}>{DoneReservationsCount}</div>
                    </div>
                </div>
            </div>
            <div className="p-col" onClick={() => divClick(5)} style={{cursor: 'pointer'}}>
                <div className="overview-box overview-box-4">
                    <div className="overview-box-header"></div>
                    <div className="overview-box-content">
                        <div className="overview-box-icon"><i className="pi pi-chart-line"></i></div>
                        <div className="overview-box-title">Toplam</div>
                        <div className="overview-box-title" style={{ fontSize: '19px' }}>{ReservationsCount}</div>
                    </div>
                </div>
            </div>
        </div>

        <Reservation prop={RezProps} />
        <div className="p-col-12 schedule">
            <Panel header="Calendar" style={{ height: '100%' }}>
                <FullCalendar events={Reservations}
                    eventTimeFormat={eventDateFormat}
                    eventOverlap={false}
                    plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                    locale="tr"
                    headerToolbar={{ left: 'prev,next', center: 'title', right: 'dayGridMonth,timeGridWeek,timeGridDay' }}
                    buttonText={{ today: 'Bugün', month: 'Aylık', week: 'Haftalık', day: 'Günlük', list: 'liste' }}
                    eventClick={eventClick}/>
            </Panel>
        </div>
        <Dialog visible={ReservationDialog} style={{ width: '650px' }} header="Rezervasyon Detayları" modal className="p-fluid" footer={ReservationDialogFooter} onHide={hideDialog}>
            <div className="p-formgrid p-grid">
                <div className="p-field p-col">
                    <label htmlFor="giverCompanyId">İş Veren Şirket</label>
                    <InputText id="arrival" disabled={true} value={DasReservation?.giverCompany?.name} />
                </div>
                <div className="p-field p-col">
                    <label htmlFor="takerCompanyId">İşi Yapan Şirket</label>
                    <InputText id="arrival" disabled={true} value={DasReservation?.takerCompany?.name} />
                </div>
            </div>
            <div className="p-formgrid p-grid">
                <div className="p-field p-col">
                    <label htmlFor="carId">Araç</label>
                    <InputText id="arrival" disabled={true} value={DasReservation?.car?.plate} />
                </div>
                <div className="p-field p-col">
                    <label htmlFor="driverId">Sürücü</label>
                    <InputText id="arrival" disabled={true} value={DasReservation?.driver?.name} />
                </div>
            </div>
            <div className="p-field">
                <label className="p-mb-3">Ücretin Alınma Şekli</label>
                <div className="p-formgrid p-grid">
                    <div className="p-field-radiobutton p-col">
                        <RadioButton inputId="isFromPassenger" disabled={true} name="isFromPassenger" value="isFromPassenger" checked={DasReservation?.isFromPassenger} />
                        <label htmlFor="isFromPassenger">Yolcudan</label>
                    </div>
                    <div className="p-field-radiobutton p-col">
                        <RadioButton inputId="isFromAgency" disabled={true} name="isFromAgency" value="isFromAgency" checked={DasReservation?.isFromAgency} />
                        <label htmlFor="isFromAgency">Acenteden</label>
                    </div>
                </div>
            </div>
            <div className="p-formgrid p-grid">
                <div className="p-field p-col">
                    <label htmlFor="takenPrice">Ücret</label>
                    <div className="p-inputgroup">
                        <InputNumber id="takenPrice" disabled={true} value={DasReservation?.takenPrice} />
                        <span className="p-inputgroup-addon">
                            <label>{Currencys.find(x => x.id === DasReservation?.takenPriceCurrencyId)?.code}</label>
                        </span>
                    </div>
                </div>
                <div className="p-field p-col">
                    <label htmlFor="collection">Tahsilat</label>
                    <div className="p-inputgroup">
                        <InputNumber id="collection" disabled={true} value={DasReservation?.collection} />
                        <span className="p-inputgroup-addon">
                            <label>{Currencys.find(x => x.id === DasReservation?.collectionCurrencyId)?.code}</label>
                        </span>
                    </div>
                </div>
                <div className="p-field p-col">
                    <label htmlFor="givenPrice">Verilen Ücret</label>
                    <div className="p-inputgroup">
                        <InputNumber id="givenPrice" disabled={true} value={DasReservation?.givenPrice} />
                        <span className="p-inputgroup-addon">
                            <label>{Currencys.find(x => x.id === DasReservation?.givenPriceCurrencyId)?.code}</label>
                        </span>
                    </div>
                </div>
            </div>
            <div className="p-formgrid p-grid">
                <div className="p-field p-col">
                    <Space direction="vertical" size={12}>
                        <label htmlFor="date">Başlangıç Tarihi</label>
                        <DatePicker disabled={true} format="DD-MM-YY HH:mm" value={DasReservation?.date} />
                    </Space>
                </div>
                <div className="p-field p-col">
                    <Space direction="vertical" size={12}>
                        <label htmlFor="completedDate">Bitiş Tarihi</label>
                        <DatePicker disabled={true} format="DD-MM-YY HH:mm" value={DasReservation?.completedDate} />
                    </Space>
                </div>
            </div>
            <div className="p-formgrid p-grid">
                <div className="p-field p-col">
                    <label htmlFor="departure">Alış</label>
                    <InputText id="departure" disabled={true} value={DasReservation?.departure} />
                </div>
                <div className="p-field p-col">
                    <label htmlFor="arrival">Bırakış</label>
                    <InputText id="arrival" disabled={true} value={DasReservation?.arrival} />
                </div>
            </div>
            <div className="p-formgrid p-grid">
                <div className="p-field p-col">
                    <label htmlFor="serviceTypeId">Servis Tipi</label>
                    <InputText id="arrival" disabled={true} value={DasReservation?.serviceType?.name} />
                </div>
                <div className="p-field p-col">
                    <label htmlFor="description">Açıklama</label>
                    <InputTextarea id="description" disabled={true} value={DasReservation?.description} autoResize rows={3} cols={20} />
                </div>
            </div>
        </Dialog>
    </div>;
}