import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { Skeleton } from 'primereact/skeleton';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import EndPointService from '../service/EndPointService'
import { InputNumber } from 'primereact/inputnumber';
import { Dropdown } from 'primereact/dropdown';
import { Checkbox } from 'primereact/checkbox';

export const CarDefinition = () => {
    let emptyCar = {
        id: null,
        plate: '',
        year: '',
        model: '',
        capacity: '',
        companyId: 0
    };

    const [Companys, setCompanys] = useState(null);
    const [Cars, setCars] = useState(null);
    const [InterTourCar, setInterTourCar] = useState(false)
    const [CarDialog, setCarDialog] = useState(false);
    const [deleteCarDialog, setDeleteCarDialog] = useState(false);
    const [deleteCarsDialog, setDeleteCarsDialog] = useState(false);
    const [Car, setCar] = useState(emptyCar);
    const [selectedCars, setSelectedCars] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);

    const getCars = () => {
        EndPointService.get('/api/Car').then(data => setCars(data));

    }

    const getCompanys = () => {
        EndPointService.get('/api/Company').then(data => setCompanys(data));
    }

    useEffect(() => {
        getCars();
        getCompanys();
    }, []);

    useEffect(()=>{
        if(InterTourCar){
            let _Car = { ...Car };
            _Car.companyId = null;    
            _Car.company = null;
            setCar(_Car);
        }
    },[InterTourCar])


    const openNew = () => {
        setCar(emptyCar);
        setInterTourCar(false);
        setSubmitted(false);
        setCarDialog(true);
    }

    const hideDialog = () => {
        setInterTourCar(false);
        setSubmitted(false);
        setCarDialog(false);
    }

    const hideDeleteCarDialog = () => {
        setDeleteCarDialog(false);
    }

    const hideDeleteCarsDialog = () => {
        setDeleteCarsDialog(false);
    }

    const saveCar = async () => {
        setSubmitted(true);
        if (Car.plate.trim() && Car.year && Car.capacity.trim() && Car.model.trim()) {
            let _Car = { ...Car };
            if (Car.id) {
                try {
                    let updateBody = {
                        plate: Car.plate,
                        year: Car.year.toString(),
                        model: Car.model,
                        capacity: Car.capacity,
                        companyId: Car.companyId > 0 ? Car.company.id : null,
                    }
                    await EndPointService.put('/api/Car?id=' + _Car.id, updateBody)
                    toast.current.show({ severity: 'success', summary: 'Başarılı', detail: 'Araç Güncellendi', life: 3000 });
                } catch (error) {
                    toast.current.show({ severity: 'error', summary: 'Hata', detail: 'Araç Güncellenirken Hata Oluştu', life: 3000 });
                }
            }
            else {
                let addBody = {
                    id: 0,
                    plate: Car.plate,
                    year: Car.year.toString(),
                    model: Car.model,
                    capacity: Car.capacity,
                    companyId: Car.companyId > 0 ? Car.companyId : null,
                }
                try {
                    await EndPointService.post('/api/Car', addBody)
                    toast.current.show({ severity: 'success', summary: 'Başarılı', detail: 'Araç Eklendi', life: 3000 });
                } catch (error) {
                    toast.current.show({ severity: 'error', summary: 'Hata', detail: 'Araç Eklerken Hata Oluştu', life: 3000 });
                }
            }

            getCars();
            setCarDialog(false);
            setCar(emptyCar);
        }
    }

    const editCar = (Car) => {
        if(Car.company == null)
            setInterTourCar(true)
        setCar({ ...Car });
        setCarDialog(true);
    }

    const confirmDeleteCar = (Car) => {
        setCar(Car);
        setDeleteCarDialog(true);
    }

    const deleteCar = async () => {
        try {
            await EndPointService.delete('/api/Car?id=' + Car.id)
            toast.current.show({ severity: 'success', summary: 'Başarılı', detail: 'Araç Silindi', life: 3000 });
        } catch (error) {
            toast.current.show({ severity: 'error', summary: 'Hata', detail: 'Araç Silinirken Hata Oluştu', life: 3000 });
        }
        getCars();
        setDeleteCarDialog(false);
        setCar(emptyCar);
    }

    const confirmDeleteSelected = () => {
        setDeleteCarsDialog(true);
    }

    const deleteSelectedCars = async () => {
        try {
            await selectedCars.map(async Car => {
                await EndPointService.delete('/api/Car?id=' + Car.id)
            })
            toast.current.show({ severity: 'success', summary: 'Başarılı', detail: 'Araçlar Silindi', life: 3000 });
        } catch (error) {
            toast.current.show({ severity: 'error', summary: 'Hata', detail: 'Araçlar Silinirken Hata Oluştu', life: 3000 });
        }
        getCars();
        setDeleteCarsDialog(false);
        setSelectedCars(null);
        setCar(emptyCar);
    }

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _Car = { ...Car };
        _Car[`${name}`] = val;

        setCar(_Car);
    }

    const onInputPlateChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _Car = { ...Car };
        _Car[`${name}`] = val.toUpperCase();;

        setCar(_Car);
    }

    const onInputNumberChange = (e, name) => {
        const val = e.value || 0;
        let _Car = { ...Car };
        _Car[`${name}`] = val;

        setCar(_Car);
    }

    const companyBodyTemplate = (data) => {
        var name = data.company?.name ?? "InterTour"
        return (
            <>
                {name}
            </>
        );
    };

    const onCompanyChange = (e) => {
        const val = (e.target && e.target.value) || '';
        let _Car = { ...Car };
        _Car.company = val;
        _Car.companyId = val.id;
        setCar(_Car);
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success p-mr-2" onClick={() => editCar(rowData)} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-warning" onClick={() => confirmDeleteCar(rowData)} />
            </div>
        );
    }

    const header = (
        <div className="table-header">
            <h5 className="p-m-0">Araçlar</h5>
            <span className="p-input-icon-left">
                <React.Fragment>
                    <InputText type="search" style={{ height: '42px', marginRight: '10px', marginBottom: '7px' }} onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Ara..." />
                    <Button label="Ekle" icon="pi pi-plus" className="p-button-success p-mr-2 p-mb-2" onClick={openNew} />
                    <Button label="Sil" icon="pi pi-trash" className="p-button-danger p-mb-2" onClick={confirmDeleteSelected} disabled={!selectedCars || !selectedCars.length} />
                </React.Fragment>
            </span>
        </div>
    );

    const CarDialogFooter = (
        <>
            <Button label="İptal" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Kaydet" icon="pi pi-check" className="p-button-text" onClick={saveCar} />
        </>
    );
    const deleteCarDialogFooter = (
        <>
            <Button label="Hayır" icon="pi pi-times" className="p-button-text" onClick={hideDeleteCarDialog} />
            <Button label="Evet" icon="pi pi-check" className="p-button-text" onClick={deleteCar} />
        </>
    );
    const deleteCarsDialogFooter = (
        <>
            <Button label="Hayır" icon="pi pi-times" className="p-button-text" onClick={hideDeleteCarsDialog} />
            <Button label="Evet" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedCars} />
        </>
    );

    return (
        <div className="p-grid crud-demo">
            <div className="p-col-12">
                <div className="card">
                    <Toast ref={toast} style={{ top: 60 }} />
                    <DataTable ref={dt} value={Cars} selection={selectedCars} selectionPageOnly onSelectionChange={(e) => setSelectedCars(e.value)}
                        dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Gösterilen {first} ve {last} Toplam {totalRecords} Araç İçinden"
                        globalFilter={globalFilter} emptyMessage="No Cars found." header={header}>
                        <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
                        <Column field="id" header="id" style={{ display: 'none' }} sortable></Column>
                        <Column field="plate" header="Plaka" filter filterField="plate" showFilterMenu={false} sortable></Column>
                        <Column field="year" header="Yıl" filter filterField="year" showFilterMenu={false} sortable></Column>
                        <Column field="model" header="Model" filter filterField="model" showFilterMenu={false} sortable></Column>
                        <Column field="capacity" header="Kapasite" filter filterField="capacity" showFilterMenu={false} sortable></Column>
                        <Column field="company" header="Şirket" filter filterField="company.name" showFilterMenu={false} body={companyBodyTemplate} sortable></Column>
                        <Column body={actionBodyTemplate}></Column>
                    </DataTable>

                    <Dialog visible={CarDialog} style={{ width: '450px' }} header="Araç Detayları" modal className="p-fluid" footer={CarDialogFooter} onHide={hideDialog}>
                        <div className="p-field">
                            <label htmlFor="plate">Plaka</label>
                            <InputText id="plate" value={Car.plate} onChange={(e) => onInputPlateChange(e, 'plate')} required autoFocus className={classNames({ 'p-invalid': submitted && !Car.plate })} />
                            {submitted && !Car.plate && <small className="p-invalid">Plaka zorunlu.</small>}
                        </div>
                        <div className="p-field">
                            <label htmlFor="year">Yıl</label>
                            <InputNumber id="year" value={Car.year} useGrouping={false} mode="decimal" onChange={(e) => onInputNumberChange(e, 'year')} required={true} className={classNames({ 'p-invalid': submitted && !Car.year })} />
                            {submitted && !Car.year && <small className="p-invalid">Yıl zorunlu.</small>}
                        </div>
                        <div className="p-field">
                            <label htmlFor="model">Model</label>
                            <InputText id="model" value={Car.model} onChange={(e) => onInputChange(e, 'model')} required={true} className={classNames({ 'p-invalid': submitted && !Car.model })} />
                            {submitted && !Car.model && <small className="p-invalid">Model zorunlu.</small>}
                        </div>
                        <div className="p-field">
                            <label htmlFor="capacity">Kapasite</label>
                            <InputText id="capacity" value={Car.capacity} keyfilter={/^[^A-Za-z<>*'^%&(){}=?_,.;~é"!/-\s]+$/} onChange={(e) => onInputChange(e, 'capacity')} required={true} className={classNames({ 'p-invalid': submitted && !Car.capacity })} />
                            {submitted && !Car.capacity && <small className="p-invalid">Kapasite zorunlu.</small>}
                        </div>
                        <div className="p-field-checkbox p-col">
                            <Checkbox inputId="binary1" checked={InterTourCar} onChange={e => setInterTourCar(e.checked)} />
                            <label htmlFor="binary1">InterTour Aracı</label>
                        </div>
                        <div className="p-field">
                            <label htmlFor="companyId">Şirket</label>
                            <Dropdown filter showClear filterBy="name" value={Car.company} placeholder={InterTourCar ? 'InterTour' : ''} disabled={InterTourCar} optionLabel="name" options={Companys} onChange={onCompanyChange} />
                        </div>
                    </Dialog>

                    <Dialog visible={deleteCarDialog} style={{ width: '450px' }} header="Onay" modal footer={deleteCarDialogFooter} onHide={hideDeleteCarDialog}>
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                            {Car && <span>Silmek istediğinize emin misiniz <b>{Car.plate}</b>?</span>}
                        </div>
                    </Dialog>

                    <Dialog visible={deleteCarsDialog} style={{ width: '450px' }} header="Onay" modal footer={deleteCarsDialogFooter} onHide={hideDeleteCarsDialog}>
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                            {Car && <span>Seçilen kayıtları silmek istediğinize emin misiniz?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
}
