import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { Space } from 'antd';
import { Dialog } from 'primereact/dialog';
import EndPointService from '../service/EndPointService'
import { RadioButton } from 'primereact/radiobutton';
import { InputNumber } from 'primereact/inputnumber';
import { InputTextarea } from 'primereact/inputtextarea';
import { InputText } from 'primereact/inputtext';
import moment from 'moment';
import { Checkbox } from 'primereact/checkbox';
import { DatePicker } from 'antd';
import 'antd/dist/antd.css';
import locale from 'antd/es/date-picker/locale/tr_TR';

export const ReservationDetail = (prop) => {
    const { id } = prop
    let emptyReservation = {
        id: 0,
        giverCompanyId: 0,
        takerCompanyId: 0,
        givenPrice: 0,
        takenPrice: 0,
        carId: 0,
        driverId: 0,
        serviceTypeId: 0,
        givenPriceCurrencyId: 1,
        takenPriceCurrencyId: 1,
        collectionCurrencyId: 1,
        isFromPassenger: false,
        isFromAgency: false,
        departure: '',
        arrival: '',
        date: '',
        completedDate: '',
        description: null,
        collection: 0
    };
    const [Reservation, setReservation] = useState(emptyReservation);
    const [Currencys, setCurrencys] = useState([]);
    const [giverInterTour, setGiverInterTour] = useState(false)
    const [ReservationDialog, setReservationDialog] = useState(false);
    const [takerInterTour, setTakerInterTour] = useState(false)

    const showReservation = (Reservation) => {
        if (Reservation.giverCompany == null)
            setGiverInterTour(true)
        if (Reservation.takerCompany == null)
            setTakerInterTour(true)
        setReservation(null);
        setReservation(Reservation);
        setReservationDialog(true);
    }

    useEffect(() => {
        if (id > "0") {
            EndPointService.get(`/api/Reservation/${id}`).then(res => {
                res.date = new moment(res.date);
                res.completedDate = new moment(res.completedDate);
                showReservation(res)
            })
        }
    }, [id])

    const hideDialog = () => {
        setGiverInterTour(false);
        setTakerInterTour(false);
        setReservationDialog(false);
    }

    const ReservationDialogFooter = (
        <>
            <Button label="İptal" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
        </>
    );


    return (<>
        {Reservation && <Dialog visible={ReservationDialog} style={{ width: '650px' }} header={`Rezervasyon Detayları ${Reservation?.reservationId ?? ""}`} modal className="p-fluid" footer={ReservationDialogFooter} onHide={hideDialog}>
            <div className="p-formgrid p-grid">
                <div className="p-field-checkbox p-col">
                    <Checkbox inputId="binary1" checked={giverInterTour} disabled={true} />
                    <label htmlFor="binary1">İş Veren InterTour</label>
                </div>
                <div className="p-field-checkbox p-col">
                    <Checkbox inputId="binary" checked={takerInterTour} disabled={true} />
                    <label htmlFor="binary">İşi Yapan InterTour</label>
                </div>
            </div>
            <div className="p-formgrid p-grid">
                <div className="p-field p-col">
                    <label htmlFor="giverCompanyId">İş Veren Şirket</label>
                    <InputText id="giverCompany" readOnly={true} value={Reservation?.giverCompany?.name ?? ""} />
                </div>
                <div className="p-field p-col">
                    <label htmlFor="takerCompanyId">İşi Yapan Şirket</label>
                    <InputText id="takerCompany" readOnly={true} value={Reservation?.takerCompany?.name ?? ""} />
                </div>
            </div>
            <div className="p-formgrid p-grid">
                <div className="p-field p-col">
                    <label htmlFor="carId">Araç</label>
                    <InputText id="car" readOnly={true} value={Reservation?.car?.plate ?? ""} />
                </div>
                <div className="p-field p-col">
                    <label htmlFor="driverId">Sürücü</label>
                    <InputText id="driver" readOnly={true} value={Reservation?.driver?.name ?? ""} />
                </div>
            </div>
            <div className="p-field">
                <label className="p-mb-3">Ücretin Alınma Şekli</label>
                <div className="p-formgrid p-grid">
                    <div className="p-field-radiobutton p-col">
                        <RadioButton inputId="isFromPassenger" disabled={true} name="isFromPassenger" value="isFromPassenger" checked={Reservation?.isFromPassenger ?? false} />
                        <label htmlFor="isFromPassenger">Yolcudan</label>
                    </div>
                    <div className="p-field-radiobutton p-col">
                        <RadioButton inputId="isFromAgency" disabled={true} name="isFromAgency" value="isFromAgency" checked={Reservation?.isFromAgency ?? false} />
                        <label htmlFor="isFromAgency">Acenteden</label>
                    </div>
                </div>
            </div>
            <div className="p-formgrid p-grid">
                <div className="p-field p-col">
                    <label htmlFor="takenPrice">Bütçe</label>
                    <div className="p-inputgroup">
                        <InputNumber id="takenPrice" readOnly={true} mode="decimal" minFractionDigits={2} disabled={true} value={Reservation?.takenPrice ?? 0} />
                    </div>
                </div>
                <div className="p-field p-col">
                    <label htmlFor="collection">Tahsilat</label>
                    <div className="p-inputgroup">
                        <InputNumber id="collection" readOnly={true} mode="decimal" minFractionDigits={2} disabled={true} value={Reservation?.collection ?? 0} />
                    </div>
                </div>
                <div className="p-field p-col">
                    <label htmlFor="givenPrice">Verilen Ücret</label>
                    <div className="p-inputgroup">
                        <InputNumber id="givenPrice" readOnly={true} mode="decimal" minFractionDigits={2} disabled={true} value={Reservation?.givenPrice ?? 0} />
                    </div>
                </div>
            </div>
            <div className="p-formgrid p-grid">
                <div className="p-field p-col">
                    <Space direction="vertical" size={12}>
                        <label htmlFor="date">Başlangıç Tarihi</label>
                        <DatePicker disabled={true} locale={locale} getPopupContainer={(triggerNode) => {
                            return triggerNode.parentNode;
                        }} style={{ width: '288px', height: '50px', borderColor: 'rgba(0, 0, 0, 0.38)', borderRadius: '3px' }} showTime={{ format: 'HH:mm' }} format="DD-MM-YY HH:mm" value={Reservation.date} />
                    </Space>
                </div>
                <div className="p-field p-col">
                    <Space direction="vertical" size={12}>
                        <label htmlFor="completedDate">Bitiş Tarihi</label>
                        <DatePicker disabled={true} locale={locale} getPopupContainer={(triggerNode) => {
                            return triggerNode.parentNode;
                        }} style={{ width: '288px', height: '50px', borderColor: 'rgba(0, 0, 0, 0.38)', borderRadius: '3px' }} showTime={{ format: 'HH:mm' }} format="DD-MM-YY HH:mm" value={Reservation.completedDate} />
                    </Space>
                </div>
            </div>
            <div className="p-formgrid p-grid">
                <div className="p-field p-col">
                    <label htmlFor="departure">Alış</label>
                    <InputText id="departure" readOnly={true} value={Reservation?.departure ?? ""} required={true} />
                </div>
                <div className="p-field p-col">
                    <label htmlFor="arrival">Bırakış</label>
                    <InputText id="arrival" readOnly={true} value={Reservation?.arrival ?? ""} required={true} />
                </div>
            </div>
            <div className="p-formgrid p-grid">
                <div className="p-field p-col">
                    <label htmlFor="serviceTypeId">Servis Tipi</label>
                    <InputText id="serviceType" readOnly={true} value={Reservation?.serviceType?.name ?? ""} required={true} />

                </div>
                <div className="p-field p-col">
                    <label htmlFor="description">Açıklama</label>
                    <InputTextarea id="description" readOnly={true} value={Reservation?.description ?? ""} autoResize rows={3} cols={20} />
                </div>
            </div>
        </Dialog>}

    </>)

}