import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { DatePicker, Space } from 'antd';
import { Dropdown } from 'primereact/dropdown';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import EndPointService from '../service/EndPointService'
import { InputMask } from 'primereact/inputmask';
import locale from 'antd/es/date-picker/locale/tr_TR';
import { InputTextarea } from 'primereact/inputtextarea';
import { InputNumber } from 'primereact/inputnumber';
import 'antd/dist/antd.css';
import moment from 'moment';


export const ExchangeRateDefinition = () => {
    let emptyExchangeRate = {
        id: null,
        dValue: null,
        eValue: null,
        date: null,
    };

    const [ExchangeRates, setExchangeRates] = useState(null);
    const [ExchangeRateDialog, setExchangeRateDialog] = useState(false);
    const [deleteExchangeRateDialog, setDeleteExchangeRateDialog] = useState(false);
    const [deleteExchangeRatesDialog, setDeleteExchangeRatesDialog] = useState(false);
    const [ExchangeRate, setExchangeRate] = useState(emptyExchangeRate);
    const [selectedExchangeRates, setSelectedExchangeRates] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);


    const getExchangeRates = () => {
        EndPointService.get('/api/Exchange').then(data => { setExchangeRates(changeDateFormat(data)) });
    }

    const changeDateFormat = (data) => {
        return [...data || []].map(d => {
            d.date = new moment(d.date);
            return d;
        });
    }

    useEffect(() => {
        getExchangeRates();
    }, []);

    const openNew = () => {
        setExchangeRate(emptyExchangeRate);
        setSubmitted(false);
        setExchangeRateDialog(true);
    }

    const hideDialog = () => {
        setSubmitted(false);
        setExchangeRateDialog(false);
    }

    const hideDeleteExchangeRateDialog = () => {
        setDeleteExchangeRateDialog(false);
    }

    const hideDeleteExchangeRatesDialog = () => {
        setDeleteExchangeRatesDialog(false);
    }

    const objectNullCheck = (data) => {
        var result = true;
        for (const [key, value] of Object.entries(data)) {
            if (key !== "id" && !value) {
                result = false;
            }
        }
        return result;
    }

    const saveExchangeRate = async () => {
        setSubmitted(true);
        if (objectNullCheck(ExchangeRate)) {
            let _ExchangeRate = { ...ExchangeRate };
            if (ExchangeRate.id) {
                try {
                    let updateBody = {
                        eValue: ExchangeRate.eValue,
                        dValue: ExchangeRate.dValue,
                        date: moment(ExchangeRate.date).utc(true),
                    }
                    await EndPointService.put('/api/Exchange?id=' + _ExchangeRate.id, updateBody)
                    toast.current.show({ severity: 'success', summary: 'Başarılı', detail: 'Döviz Güncellendi', life: 3000 });
                } catch (error) {
                    toast.current.show({ severity: 'error', summary: 'Hata', detail: 'Döviz Güncellenirken Hata Oluştu', life: 3000 });
                }
            }
            else {
                try {
                    let addBody = {
                        eValue: ExchangeRate.eValue,
                        dValue: ExchangeRate.dValue,
                        date: moment(ExchangeRate.date).utc(true),
                    }
                    await EndPointService.post('/api/Exchange', addBody)
                    toast.current.show({ severity: 'success', summary: 'Başarılı', detail: 'Döviz Eklendi', life: 3000 });
                } catch (error) {
                    toast.current.show({ severity: 'error', summary: 'Hata', detail: 'Döviz Eklerken Hata Oluştu', life: 3000 });
                }
            }

            getExchangeRates();
            setExchangeRateDialog(false);
            setExchangeRate(emptyExchangeRate);
        }
    }

    const editExchangeRate = (ExchangeRate) => {
        setExchangeRate({ ...ExchangeRate });
        setExchangeRateDialog(true);
    }

    const confirmDeleteExchangeRate = (ExchangeRate) => {
        setExchangeRate(ExchangeRate);
        setDeleteExchangeRateDialog(true);
    }

    const deleteExchangeRate = async () => {
        try {
            await EndPointService.delete('/api/Exchange?id=' + ExchangeRate.id)
            toast.current.show({ severity: 'success', summary: 'Başarılı', detail: 'Döviz Silindi', life: 3000 });
        } catch (error) {
            toast.current.show({ severity: 'error', summary: 'Hata', detail: 'Döviz Silinirken Hata Oluştu', life: 3000 });
        }
        getExchangeRates();
        setDeleteExchangeRateDialog(false);
        setExchangeRate(emptyExchangeRate);
    }

    const confirmDeleteSelected = () => {
        setDeleteExchangeRatesDialog(true);
    }

    const deleteSelectedExchangeRates = async () => {
        try {
            await selectedExchangeRates.map(async ExchangeRate => {
                await EndPointService.delete('/api/Exchange?id=' + ExchangeRate.id)
            })
            toast.current.show({ severity: 'success', summary: 'Başarılı', detail: 'Dövizler Silindi', life: 3000 });
        } catch (error) {
            toast.current.show({ severity: 'error', summary: 'Hata', detail: 'Dövizler Silinirken Hata Oluştu', life: 3000 });
        }
        getExchangeRates();
        setDeleteExchangeRatesDialog(false);
        setSelectedExchangeRates(null);
        setExchangeRate(emptyExchangeRate);
    }

    const onInputDateChange = (e, name) => {
        const val = e._d || '';
        let _ExchangeRate = { ...ExchangeRate };
        _ExchangeRate[`${name}`] = new moment(val);
        setExchangeRate(_ExchangeRate);
    }    

    const onInputNumberChange = (e, name) => {
        const val = e.value || 0;
        let _ExchangeRate = { ...ExchangeRate };
        _ExchangeRate[`${name}`] = val;
        setExchangeRate(_ExchangeRate);
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success p-mr-2" onClick={() => editExchangeRate(rowData)} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-warning" onClick={() => confirmDeleteExchangeRate(rowData)} />
            </div>
        );
    }

    const header = (
        <div className="table-header">
            <h5 className="p-m-0">Günlük Döviz Kurları</h5>
            <span className="p-input-icon-left">
                <React.Fragment>
                    <InputText type="search" style={{ height: '42px', marginRight: '10px', marginBottom: '7px' }} onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Ara..." />
                    <Button label="Ekle" icon="pi pi-plus" className="p-button-success p-mr-2 p-mb-2" onClick={openNew} />
                    <Button label="Sil" icon="pi pi-trash" className="p-button-danger p-mb-2" onClick={confirmDeleteSelected} disabled={!selectedExchangeRates || !selectedExchangeRates.length} />
                </React.Fragment>
            </span>
        </div>
    );

    const ExchangeRateDialogFooter = (
        <>
            <Button label="İptal" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Kaydet" icon="pi pi-check" className="p-button-text" onClick={saveExchangeRate} />
        </>
    );
    const deleteExchangeRateDialogFooter = (
        <>
            <Button label="Hayır" icon="pi pi-times" className="p-button-text" onClick={hideDeleteExchangeRateDialog} />
            <Button label="Evet" icon="pi pi-check" className="p-button-text" onClick={deleteExchangeRate} />
        </>
    );
    const deleteExchangeRatesDialogFooter = (
        <>
            <Button label="Hayır" icon="pi pi-times" className="p-button-text" onClick={hideDeleteExchangeRatesDialog} />
            <Button label="Evet" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedExchangeRates} />
        </>
    );

    const dateBodyTemplate = (data) => {
        var date = data.date.format("DD-MM-YYYY");
        return (
            <>
                {date}
            </>
        );
    };

    return (
        <div className="p-grid crud-demo">
            <div className="p-col-12">
                <div className="card">
                    <Toast ref={toast} style={{ top: 60 }} />
                    <DataTable ref={dt} value={ExchangeRates} selection={selectedExchangeRates} selectionPageOnly onSelectionChange={(e) => setSelectedExchangeRates(e.value)}
                        dataKey="id" 
                        // paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                        // paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        // currentPageReportTemplate="Gösterilen {first} ve {last} Toplam {totalRecords} Döviz İçinden"
                        globalFilter={globalFilter} emptyMessage="No ExchangeRates found." header={header}>
                        <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
                        <Column field="id" header="id" style={{ display: 'none' }}></Column>
                        <Column field="date" header="Tarih" body={dateBodyTemplate} sortable></Column>
                        <Column field="dValue"  header="Dolar.......TL" sortable></Column>
                        <Column field="eValue" header="Euro.......TL" sortable></Column>
                        <Column body={actionBodyTemplate}></Column>
                    </DataTable>

                    <Dialog visible={ExchangeRateDialog} style={{ width: '450px' }} header="Döviz Detayları" modal className="p-fluid" footer={ExchangeRateDialogFooter} onHide={hideDialog}>
                        <div className="p-field" >
                            <label>Dolar</label>
                            <InputNumber id="dvalue" mode="decimal" minFractionDigits={2} value={ExchangeRate.dValue} required onValueChange={(e) => onInputNumberChange(e, 'dValue')} />
                        </div>
                        <div className="p-field">
                            <label htmlFor="value">Euro</label>
                            <InputNumber id="evalue" mode="decimal" minFractionDigits={2} value={ExchangeRate.eValue} required onValueChange={(e) => onInputNumberChange(e, 'eValue')} />
                        </div>
                        <div className="p-field">
                            <Space direction="vertical" size={12}>
                                <label htmlFor="date">Tarih</label>
                                <DatePicker locale={locale} getPopupContainer={(triggerNode) => {
                                    return triggerNode.parentNode;
                                }} style={{ width: '408px', height: '50px', borderColor: 'rgba(0, 0, 0, 0.38)', borderRadius: '3px' }} onChange={(e) => onInputDateChange(e, 'date')} onOk={(e) => onInputDateChange(e, 'date')} format="DD-MM-YYYY" value={ExchangeRate.date} />
                                {submitted && !ExchangeRate.date && <small className="p-invalid">Tarih zorunlu.</small>}
                            </Space>
                        </div>
                    </Dialog>

                    <Dialog visible={deleteExchangeRateDialog} style={{ width: '450px' }} header="Onay" modal footer={deleteExchangeRateDialogFooter} onHide={hideDeleteExchangeRateDialog}>
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                            {ExchangeRate && <span>Silmek istediğinize emin misiniz <b>{ExchangeRate.name}</b>?</span>}
                        </div>
                    </Dialog>

                    <Dialog visible={deleteExchangeRatesDialog} style={{ width: '450px' }} header="Onay" modal footer={deleteExchangeRatesDialogFooter} onHide={hideDeleteExchangeRatesDialog}>
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                            {ExchangeRate && <span>Seçilen kayıtları silmek istediğinize emin misiniz?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
}
