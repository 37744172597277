import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { DatePicker, Space } from 'antd';
import { InputNumber } from 'primereact/inputnumber';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import EndPointService from '../service/EndPointService'
import { InputTextarea } from 'primereact/inputtextarea';
import locale from 'antd/es/date-picker/locale/tr_TR';
import moment from 'moment';
import { Dropdown } from 'primereact/dropdown';
import 'antd/dist/antd.css';

export const Payment = () => {

    let emptyPayment = {
        id: null,
        date: '',
        amount: '',
        companyId: 0,
        carId: 0,
        description:''
    };

    const [Payment, setPayment] = useState(emptyPayment);
    const [IncomingPayment, setIncomingPayment] = useState([]);
    const [OutgoingPayment, setOutgoingPayment] = useState([]);
    const [globalIncomingFilter, setGlobalIncomingFilter] = useState(null);
    const [globalOutgoingFilter, setGlobalOutgoingFilter] = useState(null);
    const [PaymentDialog, setPaymentDialog] = useState(false);
    const [Companys, setCompanys] = useState(null);
    const [Cars, setCars] = useState([]);
    const [deletePaymentDialog, setDeletePaymentDialog] = useState(false);
    const [submittedPayment, setSubmittedPayment] = useState(false);
    const [IsOutgoingPayment, setIsOutgoingPayment] = useState(false);
    const toast = useRef(null);
    const dt = useRef(null);

    const getPayments = () => {
        EndPointService.get(`/api/Payment`).then(res => {
            var allPayment = getDateFormat(res)
            setIncomingPayment(allPayment.filter(x => !x.isMyPayment));
            setOutgoingPayment(allPayment.filter(x => x.isMyPayment));
        });
    }

    useEffect(() => {
        getPayments()
        EndPointService.get('/api/Company').then(data => setCompanys(data));
        EndPointService.get('/api/Car').then(data => setCars(data));
    }, [])

    const getDateFormat = (data) => {
        return [...data || []].map(d => {
            d.date = new moment(d.date);
            return d;
        });
    }

    const savePayment = async () => {
        setSubmittedPayment(true);
        if (Payment.amount !== 0 && (Payment.company || Payment.car) && Payment.date) {
            let _Payment = { ...Payment };
            if (Payment.id) {
                try {
                    let updateBody = {
                        date: Payment.date,
                        amount: (IsOutgoingPayment && Payment.company) || (!IsOutgoingPayment && Payment.car) ? Payment.amount * -1 : Payment.amount,
                        companyId: Payment.company ? Payment.company.id : null,
                        carId: Payment.car ? Payment.car.id : null,
                        isMyPayment: IsOutgoingPayment,
                        description: Payment.description
                    }
                    await EndPointService.put('/api/Payment?id=' + _Payment.id, updateBody)
                    toast.current.show({ severity: 'success', summary: 'Başarılı', detail: 'Ödeme Güncellendi', life: 3000 });
                } catch (error) {
                    toast.current.show({ severity: 'error', summary: 'Hata', detail: 'Ödeme Güncellenirken Hata Oluştu', life: 3000 });
                }
            }
            else {
                let addBody = {
                    date: Payment.date,
                    amount: (IsOutgoingPayment && Payment.company) || (!IsOutgoingPayment && Payment.car) ? Payment.amount * -1 : Payment.amount,
                    companyId: Payment.company ? Payment.company.id : null,
                    carId: Payment.car ? Payment.car.id : null,
                    isMyPayment: IsOutgoingPayment,
                    description: Payment.description
                }
                try {
                    await EndPointService.post('/api/Payment', addBody)
                    toast.current.show({ severity: 'success', summary: 'Başarılı', detail: 'Ödeme Eklendi', life: 3000 });
                } catch (error) {
                    toast.current.show({ severity: 'error', summary: 'Hata', detail: 'Ödeme Eklerken Hata Oluştu', life: 3000 });
                }
            }
            getPayments()
            setPaymentDialog(false);
            setPayment(emptyPayment);
        }
    }


    const deletePayment = async () => {
        try {
            await EndPointService.delete('/api/Payment?id=' + Payment.id)
            toast.current.show({ severity: 'success', summary: 'Başarılı', detail: 'Ödeme Silindi', life: 3000 });
        } catch (error) {
            toast.current.show({ severity: 'error', summary: 'Hata', detail: 'Ödeme Silinirken Hata Oluştu', life: 3000 });
        }
        getPayments()
        setDeletePaymentDialog(false);
        setPayment(emptyPayment);
    }

    const onCompanyChange = (e) => {
        const val = (e.target && e.target.value) || '';
        let _Payment = { ...Payment };
        _Payment.company = val;
        _Payment.companyId = val.id;

        _Payment.car = null;
        _Payment.carId = 0;

        setPayment(_Payment);
    }

    const onCarChange = (e) => {
        const val = (e.target && e.target.value) || '';
        let _Payment = { ...Payment };
        _Payment.car = val;
        _Payment.carId = val.id;

        _Payment.company = null;
        _Payment.companyId = 0;

        setPayment(_Payment);
    }

    const onInputDateChange = (e) => {
        const val = e._d || '';
        let _Payment = { ...Payment };
        _Payment.date = new moment(val);
        setPayment(_Payment);
    }

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _Payment = { ...Payment };
        _Payment[`${name}`] = val;
        setPayment(_Payment);
    }

    const onInputNumberChange = (e) => {
        const val = e.value || 0;
        let _Payment = { ...Payment };
        _Payment.amount = val;
        setPayment(_Payment);
    }

    const dateBodyTemplate = (data) => {
        var date = data.date.format("DD-MM-YYYY");
        return (
            <>
                {date}
            </>
        );
    };

    const CompanyBodyTemplate = (data) => {
        var name = data?.company?.name
        return (
            <>
                {name}
            </>
        );
    };

    const CarBodyTemplate = (data) => {
        var name = data?.car?.plate
        return (
            <>
                {name}
            </>
        );
    };

    const openNewPayment = (OutgoingPayment) => {
        setIsOutgoingPayment(OutgoingPayment)
        setPayment(emptyPayment);
        setSubmittedPayment(false);
        setPaymentDialog(true);
    }

    const editPayment = (Payment) => {
        setPayment({ ...Payment });
        setPaymentDialog(true);
    }

    const confirmDeletePayment = (Payment) => {
        setPayment(Payment);
        setDeletePaymentDialog(true);
    }

    const hidePaymentDialog = () => {
        setSubmittedPayment(false);
        setPaymentDialog(false);
    }

    const hideDeletePaymentDialog = () => {
        setDeletePaymentDialog(false);
    }

    const actionIncomingPaymentBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success p-mr-2" onClick={() => {
                    setIsOutgoingPayment(false)
                    editPayment(rowData)
                }} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-warning" onClick={() => confirmDeletePayment(rowData)} />
            </div>
        );
    }

    const actionOutgoingPaymentBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success p-mr-2" onClick={() => {
                    setIsOutgoingPayment(true)
                    editPayment(rowData)
                }} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-warning" onClick={() => confirmDeletePayment(rowData)} />
            </div>
        );
    }

    const priceBodyTemplate = (rowData) => {
        if(rowData.amount < 0)
            rowData.amount *= -1
        return formatCurrency(rowData.amount) + "₺";
    }


    const formatCurrency = (value) => {
        return new Intl.NumberFormat('tr-TR', {
            style: 'currency',
            currency: 'TRY'
        }).format(value).replace("₺", "");
    }

    const PaymentDialogFooter = (
        <>
            <Button label="İptal" icon="pi pi-times" className="p-button-text" onClick={hidePaymentDialog} />
            <Button label="Kaydet" icon="pi pi-check" className="p-button-text" onClick={savePayment} />
        </>
    );

    const headerIncoming = (
        <div className="table-header">
            <h5 className="p-m-0">Firmalardan Gelen Ödemeler</h5>
            <span className="p-input-icon-left">
                <React.Fragment>
                    <InputText type="search" style={{ height: '42px', marginRight: '10px', marginBottom: '7px' }} onInput={(e) => setGlobalIncomingFilter(e.target.value)} placeholder="Ara..." />
                    <Button label="Ekle" icon="pi pi-plus" className="p-button-success p-mr-2 p-mb-2" onClick={() => openNewPayment(false)} />
                </React.Fragment>
            </span>
        </div>
    );

    const headerOutgoing = (
        <div className="table-header">
            <h5 className="p-m-0">Benim Yaptığım Ödemeler</h5>
            <span className="p-input-icon-left">
                <React.Fragment>
                    <InputText type="search" style={{ height: '42px', marginRight: '10px', marginBottom: '7px' }} onInput={(e) => setGlobalOutgoingFilter(e.target.value)} placeholder="Ara..." />
                    <Button label="Ekle" icon="pi pi-plus" className="p-button-success p-mr-2 p-mb-2" onClick={() => openNewPayment(true)} />
                </React.Fragment>
            </span>
        </div>
    );

    const deletePaymentDialogFooter = (
        <>
            <Button label="Hayır" icon="pi pi-times" className="p-button-text" onClick={hideDeletePaymentDialog} />
            <Button label="Evet" icon="pi pi-check" className="p-button-text" onClick={deletePayment} />
        </>
    );

    return (
        <div className="p-grid crud-demo">
            <Toast ref={toast} style={{ top: 60 }} />
            <div className="p-col-12 p-lg-12">
                <div className="Paymentd">
                    <DataTable ref={dt} value={IncomingPayment} style={{ borderStyle: 'groove' }}
                        dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Gösterilen {first} ve {last} Toplam {totalRecords} Firmalardan Gelen Ödeme İçinden"
                        emptyMessage="Firmalardan Gelen Ödemeler." header={headerIncoming} globalFilter={globalIncomingFilter}>
                        <Column field="id" header="id" style={{ display: 'none' }} sortable></Column>
                        <Column field="date" filter filterField="date" body={dateBodyTemplate} showFilterMenu={false} header="Tarih" sortable></Column>
                        <Column field="company" filter filterField="company.name" showFilterMenu={false} header="Şirket" body={CompanyBodyTemplate} sortField='company.name' sortable></Column>
                        <Column field="car" filter filterField="car.plate" showFilterMenu={false} header="Araç" body={CarBodyTemplate} sortField='car.plate' sortable></Column>
                        <Column field="amount" filter filterField="amount" body={priceBodyTemplate} showFilterMenu={false} header="Yapılan Ödeme" sortable></Column>
                        <Column body={actionIncomingPaymentBodyTemplate}></Column>
                    </DataTable>
                </div>
            </div>
            <div className="p-col-12 p-lg-12" style={{ marginTop: '50px' }}>
                <div className="Paymentd">
                    <DataTable ref={dt} value={OutgoingPayment} style={{ borderStyle: 'groove' }}
                        dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Gösterilen {first} ve {last} Toplam {totalRecords} Benim Yaptığım Ödemeler İçinden"
                        emptyMessage="Benim Yaptığım Ödemeler." header={headerOutgoing} globalFilter={globalOutgoingFilter}>
                        <Column field="id" header="id" style={{ display: 'none' }} sortable></Column>
                        <Column field="date" filter filterField="date" body={dateBodyTemplate} showFilterMenu={false} header="Tarih" sortable></Column>
                        <Column field="company" filter filterField="company.name" showFilterMenu={false} header="Şirket" body={CompanyBodyTemplate} sortField='company.name' sortable></Column>
                        <Column field="car" filter filterField="car.plate" showFilterMenu={false} header="Araç" body={CarBodyTemplate} sortField='car.plate' sortable></Column>
                        <Column field="amount" filter filterField="amount" body={priceBodyTemplate} showFilterMenu={false} header="Yapılan Ödeme" sortable></Column>
                        <Column body={actionOutgoingPaymentBodyTemplate}></Column>
                    </DataTable>
                </div>
            </div>
            <Dialog visible={PaymentDialog} style={{ width: '450px' }} header="Ödeme Detayları" modal className="p-fluid" footer={PaymentDialogFooter} onHide={hidePaymentDialog}>
                <div className="p-field">
                    <label htmlFor="companyId">Şirket</label>
                    <Dropdown filter showClear filterBy="name" value={Payment.company} optionLabel="name" options={Companys} onChange={onCompanyChange} />
                </div>
                <div className="p-field">
                    <label htmlFor="companyId">Araç</label>
                    <Dropdown filter showClear filterBy="plate" value={Payment.car} optionLabel="plate" options={Cars} onChange={onCarChange} />
                </div>
                <div className="p-field">
                    <Space direction="vertical" size={12}>
                        <label htmlFor="birthdate">Tarih</label>
                        <DatePicker locale={locale} getPopupContainer={(triggerNode) => {
                            return triggerNode.parentNode;
                        }} style={{ width: '408px', height: '50px', borderColor: 'rgba(0, 0, 0, 0.38)', borderRadius: '3px' }} onChange={(e) => onInputDateChange(e)} onOk={(e) => onInputDateChange(e)} format="DD-MM-YYYY" value={Payment.date} />
                    </Space>
                </div>
                <div className="p-field">
                    <label htmlFor="amount">Ödeme</label>
                    <InputNumber id="amount" value={Payment.amount} mode="currency" currency="TRY" locale="tr-TR" onChange={(e) => onInputNumberChange(e)} />
                </div>
                <div className="p-field">
                    <label htmlFor="description">Açıklama</label>
                    <InputTextarea id="description" value={Payment.description} onChange={(e) => onInputChange(e, 'description')} autoResize rows={3} cols={20} />
                </div>
            </Dialog>
            <Dialog visible={deletePaymentDialog} style={{ width: '450px' }} header="Onay" modal footer={deletePaymentDialogFooter} onHide={hideDeletePaymentDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                    {Payment && <span>Silmek istediğinize emin misiniz?</span>}
                </div>
            </Dialog>
        </div>

    )
}