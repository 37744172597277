import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Panel } from 'primereact/panel';
import { Ripple } from 'primereact/ripple';
import EndPointService from '../../service/EndPointService'
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import 'antd/dist/antd.css';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import moment from 'moment';
import { InputNumber } from 'primereact/inputnumber';
import { savePDF } from '@progress/kendo-react-pdf';
import { ReservationDetail } from '../../components/ReservationDetail';


let emptyTotal = [
    { id: 1, text: 'TOPLAM CİRO', value: '' },
    { id: 2, text: 'TOPLAM ALINAN', value: '' },
    { id: 3, text: 'ŞİRKET PAYI', value: '' },
    { id: 4, text: 'KALAN', value: '' },
]


export const CarArchive = () => {
    const [Cars, setCars] = useState(null);
    const [Car, setCar] = useState(null);
    const [GenelToplam, setGenelToplam] = useState(0);
    const [Archives, setArchives] = useState([]);
    const [Kdv, setKdv] = useState(10)
    const [total, setTotal] = useState(emptyTotal);
    const [totalBudget, setTotalBudget] = useState(0)
    const [showReservation, setShowReservation] = useState(false);
    const [reservationId, setReservationId] = useState(null)
    const [Dates, setDates] = useState(null);
    const [Driver, setDriver] = useState(null)
    const [dateInfo, setDateInfo] = useState('')
    const [EndDate, setEndDate] = useState(null);
    const [StartDate, setStartDate] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);
    const [AllDriverCars, setAllDriverCars] = useState([]);
    const [DriverCars, setDriverCars] = useState([]);
    const [height, setHeight] = useState(0)
    const [width, setWidth] = useState(0)
    const ref = useRef(null)
    const [Currencys, setCurrencys] = useState([]);

    useEffect(() => {
        EndPointService.get('/api/Car').then(res => {
            setCars(res)
        });
        EndPointService.get('/api/Driver').then(res => {
            EndPointService.post('/api/Driver/GetDeletedList').then(resDel => {
                setAllDriverCars(res.concat(resDel))
            });
        });
        EndPointService.get('/api/Currency').then(res => {
            setCurrencys(res)
        });
    }, []);
    useEffect(() => {
        setHeight(ref.current.clientHeight)
        setWidth(ref.current.clientWidth)
    })
    useEffect(() => {
        dateInfoCalculate()
    }, Dates)

    const dateInfoCalculate = () => {
        let info = ''
        if (Dates) {
            if (Dates[0])
                info += `${new moment(Dates[0]).format("DD/MM/YYYY")}`
            if (Dates[1])
                info += ` - ${new moment(Dates[1]).format("DD/MM/YYYY")}`
        }
        setDateInfo(info)
    }

    const search = () => {
        if (Car?.id === undefined)
            return
        let url = `/api/Reservation/GetConsensusCar?carId=${Car?.id}`
        if (Driver?.id)
            url += `&driverId=${Driver?.id}`;
        if (Dates) {
            if (Dates[0])
                url += `&StartDate=${new Date(Dates[0].getTime() - (Dates[0].getTimezoneOffset() * 60000)).toISOString()}`
            if (Dates[1])
                url += `&EndDate=${new Date(Dates[1].getTime() - (Dates[1].getTimezoneOffset() * 60000)).toISOString()}`
        }

        EndPointService.post(url).then(res => {
            let findDriver = []
            res.map(item => {
                if (item.driver) {
                    let find = AllDriverCars.find(x => x.name === item.driver)
                    if (find && findDriver.indexOf(find) === -1)
                        findDriver.push(find)
                }
            })
            setDriverCars(findDriver)
            setArchives(getDateFormat(res))
            let genelTop = res.map(item => item.amountTl).reduce((prev, curr) => prev + curr, 0);
            setGenelToplam(genelTop)
            let top = res.map(item => item.budgetTl).reduce((prev, curr) => prev + curr, 0) + res.filter(x => x.budgetCurrencyId == null && x.budgetTl == 0).map(item => item.budget).reduce((prev, curr) => prev + curr, 0);
            setTotalBudget(top)
            let resKdv = genelTop > 0 ? ((genelTop * Kdv) / 100) : 0
            let resTotal = [
                { id: 1, text: 'TOPLAM CİRO', value: genelTop + '₺' },
                { id: 2, text: 'TOPLAM ALINAN', value: top.toFixed(2) + '₺' },
                { id: 3, text: `ŞİRKET PAYI`, value: resKdv.toFixed(2) + '₺' },
                { id: 4, text: 'KALAN', value: (genelTop - (top + resKdv)).toFixed(2) + '₺', color: 'green' },
            ]
            setTotal(resTotal)
        });
    }

    const exportPdf = () => {
        if (!Car)
            return

        savePDF(ref.current, {
            paperSize: 'A4',
            landscape: true,
            keepTogether: ".keepTogether",
            fileName: `${Car.plate}.pdf`,
            scale: 0.37,
        })
    }

    const clear = () => {
        setTotal(emptyTotal)
        setArchives([])
        setGenelToplam(0)
        setTotalBudget(0)
        setCar()
        setDates()
        setStartDate()
        setEndDate()
    }

    const getDateFormat = (data) => {
        return [...data || []].map(d => {
            d.date = new moment(d.date);
            return d;
        });
    }

    const onEndDateChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        var date = new Date(val.getTime() - (val.getTimezoneOffset() * 60000)).toISOString();
        setEndDate(date);
    }

    const onStartDateChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        var date = new Date(val.getTime() - (val.getTimezoneOffset() * 60000)).toISOString();
        setStartDate(date);
    }

    const onCarChange = (e) => {
        const val = (e.target && e.target.value) || '';
        setCar(val);

        // if (val.id) {
        //     let carDriver = [];
        //     AllDriverCars.filter(x => x.car.id == val.id).map(x => {
        //         carDriver.push(x.driver)
        //     })
        //     setDriverCars(carDriver)
        // }
    }

    const numberFormat = (value) =>
        new Intl.NumberFormat('tr-TR', {
            style: 'currency',
            currency: 'TRY'
        }).format(value).replace("₺", "");

    const onDriverChange = (e) => {
        const val = (e.target && e.target.value) || '';
        setDriver(val);
    }

    // const collectionBudgetBodyTemplate = (data) => {
    //     return (
    //         <>
    //             {data.detail ? data.budget + "₺" : <label style={{ color: data.amount > 0 ? 'green' : 'red' }}></label>}
    //         </>
    //     );
    // }

    const collectionPriceBodyTemplate = (data) => {
        var currency = Currencys.find(x => x.id === data.amountCurrencyId)?.code;
        var fullPrice = numberFormat(data.amount) + " " + (currency ?? "₺") + (currency && data.amountTl ? `(${numberFormat(data.amountTl)}₺)` : "");
        return (
            <>
                {fullPrice}
            </>
        );
    }

    const collectionBudgetBodyTemplate = (data) => {
        var currency = Currencys.find(x => x.id === data.budgetCurrencyId)?.code;
        var fullPrice = numberFormat(data.budget) + " " + (currency ?? "₺") + (currency && data.budgetTl ? `(${numberFormat(data.budgetTl)}₺)` : "");
        return (
            <>
                {fullPrice}
            </>
        );
    }

    const dateBodyTemplate = (data) => {
        var date = data.date.format("DD-MM-YY HH:mm");
        return (
            <>
                {data.detail ? date : <label style={{ color: data.amount > 0 ? 'green' : 'red' }}>{date}</label>}
            </>
        );
    };

    const detailBodyTemplate = (data) => {
        return (
            <>
                {data.detail ? data.detail : <label style={{ color: data.amount > 0 ? 'green' : 'red', marginLeft: '50%' }}>{data.description}</label>}
            </>
        );
    }

    const totalTextBodyTemplate = (data) => {
        return (
            <>
                {data.color ? <label style={{ color: data.color }}>{data.text}</label> : data.text}
            </>
        );
    }

    const totalValueBodyTemplate = (data) => {
        return (
            <>
                {data.color ? <label style={{ color: data.color }}>{data.value}</label> : data.value}
            </>
        );
    }

    const template = (options) => {
        const toggleIcon = options.collapsed ? 'pi pi-chevron-down' : 'pi pi-chevron-up';
        const className = `${options.className} justify-content-start`;

        return (
            <div className={className}>
                <button className={options.togglerClassName} onClick={options.onTogglerClick}>
                    <span className={toggleIcon}></span>
                    <Ripple />
                </button>
            </div>
        )
    }

    const idBodyTemplate = (data) => {
        return (
            <p onClick={() => {
                setReservationId(data.reservationUId)
                setShowReservation(true)
            }} style={{ color: 'blue' }}> {data.reservationId}</p>
        );
    };

    const onInputChange = (e) => {
        setKdv(e);
        total[1].text = `KDV %${e}`
    }

    return (
        <div className="p-col-12">
            <div className="Reservationd">
                <Toast ref={toast} style={{ top: 60 }} />
                <Panel headerTemplate={template} toggleable>
                    <div className="p-formgrid p-grid">
                        <div className="p-field p-col" >
                            <label style={{ marginLeft: '44px' }}>Araç</label>
                            <Dropdown value={Car} style={{ width: '200px', marginLeft: '10px' }} filter showClear filterBy="plate" options={Cars} onChange={onCarChange} optionLabel="plate" />
                        </div>
                        <div className="p-field p-col">
                            <label htmlFor="driverId">Sürücü</label>
                            <Dropdown value={Driver} emptyMessage='Önce Arama Yapmalısınız!!' filter style={{ width: '200px', marginLeft: '10px' }} showClear filterBy="name" options={DriverCars} onChange={onDriverChange} optionLabel="name" />
                        </div>
                        <div className="p-field p-col">
                            <label >Tarih Aralığı</label>
                            <Calendar value={Dates} selectionMode="range" style={{ width: '200px', marginLeft: '10px' }} onChange={(e) => setDates(e.value)} dateFormat="dd/mm/yy" />
                        </div>
                        <div className="p-field p-col">
                            <Button icon="pi pi-search" className="p-button-rounded p-button-success" onClick={search} />
                            <Button icon="pi pi-times" className="p-button-rounded p-button-danger" style={{ marginLeft: '20px' }} onClick={clear} />
                            <Button icon="pi pi-file-pdf" className="p-button-rounded p-button-info" style={{ marginLeft: '20px' }} onClick={exportPdf} />
                        </div>
                    </div>
                </Panel>
                <div id='divToPrint' style={{ marginTop: '30px' }} className='custom-div' ref={ref}>
                    <div className="p-formgrid p-grid" style={{ borderStyle: 'groove', width: '100%', height: '85px', marginLeft: '0.01%' }}>
                        <div className="p-col-12 p-md-4" style={{ marginTop: '10px' }}>
                            <h5 className="p-m-0">Plaka: {Car?.plate ?? ""} </h5>
                        </div>
                        <div className="p-col-12 p-md-4" style={{ marginTop: '10px' }}>
                            <h5 className="p-m-0">Model: {Car?.model ?? ""} </h5>
                        </div>
                        <div className="p-col-12 p-md-2" style={{ marginTop: '10px' }}>
                            <h5 className="p-m-0">Kapasite: {Car?.capacity ?? ""}</h5>
                        </div>
                        <div className="p-col-12 p-md-2" style={{ marginTop: '10px' }}>
                            <h5 className="p-m-0">Yıl: {Car?.year ?? ""}</h5>
                        </div>
                        <div className="p-col-12 p-md-4" style={{ marginTop: '10px' }}>
                            <h5 className="p-m-0">Tarih Aralığı: {dateInfo} </h5>
                        </div>
                    </div>
                    <DataTable ref={dt} value={Archives} style={{ borderStyle: 'groove', marginTop: '30px' }} dataKey="id" className="datatable-responsive"
                        emptyMessage="Kayıt Bulunamadı">
                        <Column field="reservationId" header="Hizmet No" body={idBodyTemplate}></Column>
                        <Column field="date" header="Tarih" body={dateBodyTemplate}></Column>
                        <Column field="detail" header="Detay" body={detailBodyTemplate}></Column>
                        <Column field="description" header="Açıklama"></Column>
                        <Column field="driver" header="Sürücü"></Column>
                        <Column field="company" header="İş Veren"></Column>
                        <Column field="amount" style={{ width: '200px' }} header="Bütçe" body={collectionPriceBodyTemplate}></Column>
                        <Column field="budget" style={{ width: '200px' }} header="Tahsilat" body={collectionBudgetBodyTemplate}></Column>                       
                    </DataTable>
                    {GenelToplam || totalBudget > 0 ? (<>
                        <div className="p-formgrid p-grid" style={{ left: '75%', position: 'absolute' }}>
                            <h5>{`TOPLAM: ${GenelToplam.toFixed(2)}₺ `}</h5>
                            <h5 style={{ marginLeft: '100px' }}>{`${totalBudget.toFixed(2)}₺`}</h5>
                        </div>

                    </>) : (null)}

                    <div className="p-formgrid p-grid keepTogether" style={{ marginTop: '50px', marginLeft: '50px' }}>
                        <DataTable value={total} style={{ minWidth: '300px', borderStyle: 'groove' }}>
                            <Column field="text" style={{ minWidth: '200px' }} header="Hesap Özeti" body={totalTextBodyTemplate}></Column>
                            <Column field="value" style={{ minWidth: '200px' }} body={totalValueBodyTemplate}></Column>
                        </DataTable>
                        <div className="p-field p-col" style={{ marginTop: '165px', zIndex: '10' }}>
                            <InputNumber size={3} prefix="%" tooltip="Oran Değişikliği Sonrası Tekrar Arama Yapmalısınız!" tooltipOptions={{ position: 'left' }} value={Kdv} style={{ height: '7px', marginLeft: '-310px' }} mode="decimal" min={0} max={100} onValueChange={(e) => onInputChange(e.value)} />
                        </div>
                    </div>

                    {showReservation && <ReservationDetail id={reservationId} />}


                </div>
            </div>
        </div>
    );
}
