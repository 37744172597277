import React, { useEffect, useState, useRef } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from "primereact/button";
import { useHistory } from 'react-router-dom';
import { login,logout } from "../actions/authAction";
import { useDispatch, useSelector } from 'react-redux';
import { BlockUI } from 'primereact/blockui';
import { Toast } from 'primereact/toast';

const defaultInputs = {
	email: '',
	password: ''
};

function Login() {
	const auth = useSelector(({ auth }) => auth);
	const toast = useRef(null);
	const dispatch = useDispatch();
	const [input, setInput] = useState(defaultInputs);
	const [blockedDocument, setBlockedDocument] = useState(false);
	const history = useHistory();

	useEffect(() => {
		dispatch(logout());
	}, []);

	const handleChange = e => {
		setInput({ ...input, [e.target.id]: e.target.value });
	};

	const onSubmit = () => {
		setBlockedDocument(true)
		dispatch(login(input.email, input.password));
		setBlockedDocument(false)
	}

	useEffect(() => {
		if(auth?.error && auth?.errorMessage?.message)
			toast.current.show({severity:'error', summary: 'Error', detail: "Giriş Yapılamadı Bilgileri Kontrol Ediniz!", life: 3000});
	}, [auth]);


	if (auth.user)
		history.push('/')

	return (
		<div className="login-body">
			<BlockUI blocked={blockedDocument} fullScreen />
			<Toast ref={toast} />
			<div className="login-panel p-fluid">
				<div className="login-panel-header">
					<img src="assets/layout/images/interlogo.png" style={{height:'50px', width:'125px'}}/>
				</div>
				<div className="login-panel-content">
					<div className="p-grid">
						<div className="p-col-12">
							<h1>InterTour</h1>
						</div>
						<div className="p-col-12">
							<span className="p-float-label">
								<InputText id="email" type="text" value={input.email} onChange={(e) => handleChange(e)} />
								<label>Email</label>
							</span>
						</div>
						<div className="p-col-12">
							<span className="p-float-label">
								<InputText id="password" type="password" value={input.password} onChange={(e) => handleChange(e)} />
								<label>Password</label>
							</span>
						</div>
						<div className="p-col-12">
							<Button label="Giriş" onClick={() => onSubmit()} style={{ width: '100%' }} />
						</div>
						{/* <div className="p-col-12">
							Don't have an account? <a href="/#">Sign Up</a> now.
						</div> */}
					</div>
				</div>
			</div>
		</div>
	)

}

export default Login;