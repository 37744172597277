import axios from 'axios';
import configData from "../config.json";


class EndPointService {
  constructor() {
    this.api_url = configData.ApiUrl;
  }

  post = (url, body) => {
    return new Promise(async (resolve, reject) => {
      await axios.post(this.api_url + url, body)
        .then(response => {
          if (response?.data) {
            resolve(response.data);
          }
          else{
            resolve();
          }          
        })
        .catch(error => {
          if (error.response) {
            reject(error.response.data);
          } else {
            reject(error);
          }
        });
    })
  }

  put = (url, body) => {
    return new Promise(async (resolve, reject) => {
      await axios.put(this.api_url + url, body)
        .then(response => {
          if (response) {
            resolve(response);
          }
        })
        .catch(error => {
          if (error.response) {
            reject(error.response.data);
          } else {
            reject(error);
          }
        });
    })
  }

  get = (url) => {
    return new Promise(async (resolve, reject) => {
      await axios
        .get(this.api_url + url)
        .then(response => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response);
          }
        })
        .catch(error => {
          if (error.response) {
            reject(error.response.data);
          } else {
            reject(error);
          }
        });
    });
  }

  delete = (url) => {
    return new Promise(async (resolve, reject) => {
      await axios
        .delete(this.api_url + url)
        .then(response => {
          if (response) {
            resolve(response);
          } else {
            reject(response);
          }
        })
        .catch(error => {
          if (error.response) {
            reject(error.response.data);
          } else {
            reject(error);
          }
        });
    });
  }
}


const instance = new EndPointService();

export default instance;