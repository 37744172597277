import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { DatePicker, Space } from 'antd';
import { Dialog } from 'primereact/dialog';
import EndPointService from '../service/EndPointService'
import CountryService from '../service/CountryService'
import { Dropdown } from 'primereact/dropdown';
import { RadioButton } from 'primereact/radiobutton';
import { InputNumber } from 'primereact/inputnumber';
import classNames from 'classnames';
import { AutoComplete } from 'primereact/autocomplete';
import { Calendar } from 'primereact/calendar';
import { InputTextarea } from 'primereact/inputtextarea';
import { InputMask } from 'primereact/inputmask';
import { MultiSelect } from 'primereact/multiselect';
import { InputText } from 'primereact/inputtext';
import { MultiStateCheckbox } from 'primereact/multistatecheckbox';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import locale from 'antd/es/date-picker/locale/tr_TR';
import moment from 'moment';
import { Checkbox } from 'primereact/checkbox';
import 'antd/dist/antd.css';


export const Reservation = ({ prop }) => {
    const { id, status } = prop
    let emptyReservation = {
        id: 0,
        giverCompanyId: 0,
        takerCompanyId: 0,
        givenPrice: 0,
        takenPrice: 0,
        carId: 0,
        driverId: 0,
        serviceTypeId: 0,
        givenPriceCurrencyId: 1,
        takenPriceCurrencyId: 1,
        collectionCurrencyId: 1,
        isFromPassenger: false,
        isFromAgency: false,
        departure: '',
        arrival: '',
        date: '',
        completedDate: '',
        description: null,
        collection: 0
    };

    let emptyPassenger = {
        id: 0,
        nameSurname: '',
        nationalityNumber: '',
        nationality: null,
        phone: '',
        isMen: false,
        isWoman: false,
        reservationId: 0,
    };



    const Status = [
        { key: 1, name: "İşlemde" },
        { key: 2, name: "Bekliyor" },
        { key: 4, name: "Tamamlanmış" },
        { key: 3, name: "Onay Bekliyor" },
    ]

    const [loading, setLoading] = useState(false);
    const [totalRecords, setTotalRecords] = useState(0);
    const [Cars, setCars] = useState([]);
    const [AllReadonly, setAllReadonly] = useState(false)
    const [CompanyCars, setCompanyCars] = useState([]);
    const [Currencys, setCurrencys] = useState([]);
    const [giverInterTour, setGiverInterTour] = useState(false)
    const [takerInterTour, setTakerInterTour] = useState(false)
    const [ReservationDetail1, setReservationDetail1] = useState('')
    const [ReservationDetail2, setReservationDetail2] = useState('')
    const [FilteredCompanys, setFilteredCompanys] = useState([]);
    const [Drivers, setDrivers] = useState([]);
    const [countries, setCountries] = useState([]);
    const [DriverCars, setDriverCars] = useState([]);
    const [AllDriverCars, setAllDriverCars] = useState([]);
    const [Companys, setCompanys] = useState([]);
    const [ServiceTypes, setServiceTypes] = useState([]);
    const [FilterCars, setFilterCars] = useState([]);
    const [FilterDrivers, setFilterDrivers] = useState([]);
    const [FilterGiverCompanys, setFilterGiverCompanys] = useState([]);
    const [FilterTakerCompanys, setFilterTakerCompanys] = useState([]);
    const [FilterServiceTypes, setFilterServiceTypes] = useState([]);
    const [Reservations, setReservations] = useState([]);
    const [ReservationDialog, setReservationDialog] = useState(false);
    const [ReservationDetailDialog, setReservationDetailDialog] = useState(false);
    const [PassengerDialog, setPassengerDialog] = useState(false);
    const [isDoneReservationDialog, setIsDoneReservationDialog] = useState(false);
    const [isNotDoneReservationDialog, setIsNotDoneReservationDialog] = useState(false);
    const [deleteReservationDialog, setDeleteReservationDialog] = useState(false);
    const [deletePassengerDialog, setDeletePassengerDialog] = useState(false);
    const [deleteReservationsDialog, setDeleteReservationsDialog] = useState(false);
    const [deletePassengersDialog, setDeletePassengersDialog] = useState(false);
    const [Reservation, setReservation] = useState(emptyReservation);
    const [Passenger, setPassenger] = useState(emptyPassenger);
    const [selectedReservations, setSelectedReservations] = useState(null);
    const [selectedPassengers, setSelectedPassengers] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [submittedPassenger, setSubmittedPassenger] = useState(false);
    const [expandedRows, setExpandedRows] = useState(null);
    const [Locations, setLocations] = useState([]);
    const [FilteredLocations, setFilteredLocations] = useState([]);
    const [allSearch, setAllSearch] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);

    const [lazyParams, setLazyParams] = useState({
        first: 0,
        rows: 10,
        page: 0,
        sortField: null,
        sortOrder: null,
        filters: {
            'car': { value: null, matchMode: FilterMatchMode.IN },
            'driver': { value: null, matchMode: FilterMatchMode.IN },
            'serviceType': { value: null, matchMode: FilterMatchMode.IN },
            'giverCompany': { value: null, matchMode: FilterMatchMode.IN },
            'takerCompany': { value: null, matchMode: FilterMatchMode.IN },
            'date': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
            'reservationStatus': { value: null, matchMode: FilterMatchMode.IN },
            'reservationId': { value: null, matchMode: FilterMatchMode.EQUALS },
        }
    });

    const getReservations = () => {
        var searchText = "";
        lazyParams.filters.car.value?.map(car => {
            searchText = searchText.concat(`car.plate$${car.plate},`)
        })
        lazyParams.filters.driver.value?.map(driver => {
            searchText = searchText.concat(`driver.name$${driver.name},`)
        })
        lazyParams.filters.serviceType.value?.map(serviceType => {
            searchText = searchText.concat(`serviceType.name$${serviceType.name},`)
        })
        lazyParams.filters.giverCompany.value?.map(giverCompany => {
            searchText = searchText.concat(`giverCompany.name$${encodeURIComponent(giverCompany.name)},`)
        })
        lazyParams.filters.takerCompany.value?.map(takerCompany => {
            searchText = searchText.concat(`takerCompany.name$${encodeURIComponent(takerCompany.name)},`)
        })
        if (status && status != 5)
            searchText = searchText.concat(`status$${status},`)
        else {
            lazyParams.filters.reservationStatus.value?.map(reservationStatus => {
                searchText = searchText.concat(`status$${reservationStatus.key},`)
            })
        }
        if (lazyParams.filters.date.constraints[0]?.value) {
            searchText = searchText.concat(`date$${new Date(lazyParams.filters.date.constraints[0].value[0].getTime() - (lazyParams.filters.date.constraints[0].value[0].getTimezoneOffset() * 60000)).toISOString()},`)
            if (lazyParams.filters.date.constraints[0].value[1])
                searchText = searchText.concat(`date$${new Date(lazyParams.filters.date.constraints[0].value[1].getTime() - (lazyParams.filters.date.constraints[0].value[1].getTimezoneOffset() * 60000)).toISOString()},`)
        }

        let search = lazyParams.filters.reservationId?.value ?? "";
        EndPointService.get(`/api/Reservation/Paging?pNumber=${lazyParams.page ? lazyParams.page + 1 : 1}&pSize=${lazyParams.rows}&search=${searchText.slice(0, -1)}&searchText=${search}`).then(res => {
            setReservations(getDateReservations(res.data))
            setTotalRecords(res.totalCount);
            setLoading(false);
        });
    }

    const onPage = (event) => {
        setLazyParams(event);
    }

    const onSort = (event) => {
        setLazyParams(event);
    }

    const onFilter = (event) => {
        event['first'] = 0;
        setLazyParams(event);
    }

    const getDateReservations = (data) => {
        return [...data || []].map(d => {
            d.date = new moment(d.date);
            d.completedDate = new moment(d.completedDate);
            return d;
        });
    }

    useEffect(() => {
        EndPointService.get('/api/Currency').then(res => {
            setCurrencys(res)
        });
        EndPointService.get('/api/Car').then(res => {
            setCars(res)
            setFilterCars(res)
            let currentCompanyCar = res.filter(x => !x.company)
            setCompanyCars(currentCompanyCar)
        });
        EndPointService.get('/api/Company').then(res => {
            res.map(x => {
                x.optName = x.phone ? x.name + ` ${x.phone}` : x.name
                return x
            })
            setCompanys(res)
            setFilterTakerCompanys(res)
            setFilterGiverCompanys(res)
        });
        EndPointService.get('/api/Driver').then(res => {
            setDrivers(res)
            setDriverCars(res)
            setFilterDrivers(res)
        });
        EndPointService.get('/api/ServiceType').then(res => {
            setServiceTypes(res)
            setFilterServiceTypes(res)
        });
        EndPointService.get('/api/DriverCars').then(res => {
            setAllDriverCars(res)
        });
        EndPointService.get('/api/Reservation/GetLocations').then(res => {
            setLocations(res)
        });
        const countryservice = new CountryService();
        countryservice.getCountries().then(data => setCountries(data));
    }, []);

    useEffect(async () => {
        if (id > "0") {
            var res = await EndPointService.get(`/api/Reservation/${id}`)
            res.date = new moment(res.date);
            res.completedDate = new moment(res.completedDate);
            if (res.isDone)
                showReservation(res)
            else
                editReservation(res)
        }
    }, [id])

    useEffect(async () => {
        if (status) {
            getReservations()
        }
    }, [status])

    useEffect(() => {
        if (allSearch)
            searchRes()
        else
            getReservations();
    }, [lazyParams])

    useEffect(() => {
        if (allSearch) {
            searchRes()
        }
    }, [allSearch])

    const searchRes = () => {
        setLoading(true);
        EndPointService.post(`/api/Reservation/Search?pNumber=${lazyParams.page ? lazyParams.page + 1 : 1}&pSize=${lazyParams.rows}&searchText=${allSearch}`).then(res => {
            setReservations(getDateReservations(res.data))
            setTotalRecords(res.totalCount);
            setLoading(false);
        });
    }

    useEffect(() => {
        let _Reservation = { ...Reservation };
        if (giverInterTour) {
            _Reservation.giverCompany = null;
            _Reservation.takenPrice = 0
        }
        if (takerInterTour) {
            _Reservation.takerCompany = null;
            let currentCompanyCar = Cars.filter(x => !x.company)
            setCompanyCars(currentCompanyCar)
            setDriverCars(Drivers)
        }
        if (giverInterTour && !takerInterTour)
            _Reservation.takenPrice = 0
        setReservation(_Reservation);
    }, [giverInterTour, takerInterTour])

    const openNew = () => {
        setReservation(emptyReservation);
        setSubmitted(false);
        setReservationDialog(true);
    }

    const openNewPassenger = (data) => {
        let emptyPassengerr = {
            id: 0,
            nameSurname: '',
            nationalityNumber: '',
            phone: '',
            isMen: false,
            isWoman: false,
            reservationId: data.id,
        };
        setPassenger(emptyPassengerr);
        setSubmittedPassenger(false);
        setPassengerDialog(true);
    }

    const hideDialog = () => {
        setSubmitted(false);
        setGiverInterTour(false);
        setTakerInterTour(false);
        setAllReadonly(false);
        setReservationDialog(false);
    }

    const hidePassengerDialog = () => {
        setSubmittedPassenger(false);
        setPassengerDialog(false);
    }

    const hideDeleteReservationDialog = () => {
        setDeleteReservationDialog(false);
    }


    const hideIsDoneReservationDialog = () => {
        setIsDoneReservationDialog(false);
    }

    const hideIsNotDoneReservationDialog = () => {
        setIsNotDoneReservationDialog(false);
    }

    const hideDeleteReservationsDialog = () => {
        setDeleteReservationsDialog(false);
    }

    const hideDeletePassengerDialog = () => {
        setDeletePassengerDialog(false);
    }

    const hideDeletePassengersDialog = () => {
        setDeletePassengersDialog(false);
    }

    const saveReservation = async () => {
        setSubmitted(true);
        if (Reservation.date > Reservation.completedDate) {
            toast.current.show({ severity: 'warn', summary: 'Uyarı', detail: 'Başlangıç Tarihi Bitiş Tarihinden Büyük Olamaz', life: 3000 });
            return;
        }
        if (Reservation.giverCompany && Reservation.takerCompany && Reservation.giverCompany?.id && Reservation.giverCompany?.id === Reservation.takerCompany?.id) {
            toast.current.show({ severity: 'warn', summary: 'Uyarı', detail: 'İş Alan Şirket İş Veren Şirket İle Aynı Olamaz', life: 3000 });
            return;
        }
        if (Reservation.giverCompany && Reservation.giverCompany?.id === undefined) {
            await EndPointService.post('/api/Company', { name: Reservation.giverCompany })
            var allComp = await EndPointService.get(`/api/Company`)
            allComp.map(x => {
                x.optName = x.phone ? x.name + ` ${x.phone}` : x.name
                return x
            })
            setCompanys(allComp)
            Reservation.giverCompany = allComp.find(x => x.name === Reservation.giverCompany)
        }
        if (Reservation.takerCompany && Reservation.takerCompany?.id === undefined) {
            await EndPointService.post('/api/Company', { name: Reservation.takerCompany })
            var allComp = await EndPointService.get(`/api/Company`)
            allComp.map(x => {
                x.optName = x.phone ? x.name + ` ${x.phone}` : x.name
                return x
            })
            setCompanys(allComp)
            Reservation.takerCompany = allComp.find(x => x.name === Reservation.takerCompany)
        }
        if (Reservation.serviceType?.id && Reservation.date && Reservation.completedDate && Reservation.arrival && Reservation.departure) {
            let _Reservation = { ...Reservation };
            if (Reservation.id) {
                try {
                    let updateBody = {
                        id: Reservation.id,
                        car: null,
                        carId: Reservation.carId > 0 ? Reservation.carId : Reservation.car ? Reservation.car.id : null,
                        driver: null,
                        driverId: Reservation.driverId > 0 ? Reservation.driverId : Reservation.driver ? Reservation.driver.id : null,
                        giverCompanyId: Reservation.giverCompany?.id > 0 ? Reservation.giverCompany.id : null,
                        takerCompanyId: Reservation.takerCompany?.id > 0 ? Reservation.takerCompany.id : null,
                        givenPrice: Reservation.givenPrice,
                        takenPrice: Reservation.takenPrice,
                        serviceTypeId: Reservation.serviceType.id,
                        isFromPassenger: Reservation.isFromPassenger,
                        isFromAgency: Reservation.isFromAgency,
                        date: moment(Reservation.date).utc(true),
                        description: Reservation.description,
                        collection: Reservation.collection,
                        departure: Reservation.departure,
                        arrival: Reservation.arrival,
                        completedDate: moment(Reservation.completedDate).utc(true),
                        givenPriceCurrencyId: Reservation.givenPriceCurrencyId,
                        takenPriceCurrencyId: Reservation.takenPriceCurrencyId,
                        collectionCurrencyId: Reservation.collectionCurrencyId
                    }
                    await EndPointService.put('/api/Reservation?id=' + _Reservation.id, updateBody)
                    toast.current.show({ severity: 'success', summary: 'Başarılı', detail: 'Rezervasyon Güncellendi', life: 3000 });
                } catch (error) {
                    toast.current.show({ severity: 'error', summary: 'Hata', detail: 'Rezervasyon Güncellenirken Hata Oluştu', life: 3000 });
                }
            }
            else {
                let addBody = {
                    car: Reservation.carId > 0 ? null : Reservation.car,
                    carId: Reservation.carId > 0 ? Reservation.carId : null,
                    driver: Reservation.driverId > 0 ? null : Reservation.driver,
                    driverId: Reservation.driverId > 0 ? Reservation.driverId : null,
                    giverCompanyId: Reservation.giverCompany?.id > 0 ? Reservation.giverCompany.id : null,
                    takerCompanyId: Reservation.takerCompany?.id > 0 ? Reservation.takerCompany.id : null,
                    givenPrice: Reservation.givenPrice,
                    takenPrice: Reservation.takenPrice,
                    serviceTypeId: Reservation.serviceTypeId,
                    isFromPassenger: Reservation.isFromPassenger,
                    isFromAgency: Reservation.isFromAgency,
                    date: moment(Reservation.date).utc(true),
                    description: Reservation.description,
                    collection: Reservation.collection,
                    departure: Reservation.departure,
                    arrival: Reservation.arrival,
                    completedDate: moment(Reservation.completedDate).utc(true),
                    givenPriceCurrencyId: Reservation.givenPriceCurrencyId,
                    takenPriceCurrencyId: Reservation.takenPriceCurrencyId,
                    collectionCurrencyId: Reservation.collectionCurrencyId
                }
                try {
                    await EndPointService.post('/api/Reservation', addBody)
                    toast.current.show({ severity: 'success', summary: 'Başarılı', detail: 'Rezervasyon Eklendi', life: 3000 });
                } catch (error) {
                    toast.current.show({ severity: 'error', summary: 'Hata', detail: 'Rezervasyon Eklerken Hata Oluştu', life: 3000 });
                }
            }

            getReservations();
            setReservationDialog(false);
            setReservation(emptyReservation);
            setGiverInterTour(false);
            setTakerInterTour(false);
            setSubmitted(false);
        }
    }

    const savePassenger = async () => {
        setSubmittedPassenger(true);
        if (Passenger.nameSurname.trim()) {
            let _Passenger = { ...Passenger };
            if (Passenger.id) {
                try {
                    let updateBody = {
                        id: Passenger.id,
                        nameSurname: Passenger.nameSurname,
                        nationalityNumber: Passenger.nationalityNumber,
                        phone: Passenger.phone,
                        isMen: Passenger.isMen,
                        isWoman: Passenger.isWoman,
                        reservationId: Passenger.reservationId,
                        nationality: Passenger.nationality?.name
                    }
                    await EndPointService.put('/api/Passenger?id=' + _Passenger.id, updateBody)
                    toast.current.show({ severity: 'success', summary: 'Başarılı', detail: 'Yolcu Güncellendi', life: 3000 });
                } catch (error) {
                    toast.current.show({ severity: 'error', summary: 'Hata', detail: 'Yolcu Güncellenirken Hata Oluştu', life: 3000 });
                }
            }
            else {
                let addBody = {
                    nameSurname: Passenger.nameSurname,
                    nationalityNumber: Passenger.nationalityNumber,
                    phone: Passenger.phone,
                    isMen: Passenger.isMen,
                    isWoman: Passenger.isWoman,
                    reservationId: Passenger.reservationId,
                    nationality: Passenger.nationality?.name
                }
                try {
                    await EndPointService.post('/api/Passenger', addBody)
                    toast.current.show({ severity: 'success', summary: 'Başarılı', detail: 'Yolcu Eklendi', life: 3000 });
                } catch (error) {
                    toast.current.show({ severity: 'error', summary: 'Hata', detail: 'Yolcu Eklerken Hata Oluştu', life: 3000 });
                }
            }
            getReservations();
            setPassengerDialog(false);
            setPassenger(emptyPassenger);
        }
    }

    const editReservation = (Reservation) => {
        if (Reservation.giverCompany == null)
            setGiverInterTour(true)
        if (Reservation.takerCompany == null)
            setTakerInterTour(true)

        setReservation(null);
        if (Reservation.giverCompany) {
            Reservation.giverCompany.optName = Reservation.giverCompany.phone ? Reservation.giverCompany.name + ` ${Reservation.giverCompany.phone}` : Reservation.giverCompany.name
        }
        if (Reservation.takerCompany) {
            Reservation.takerCompany.optName = Reservation.takerCompany.phone ? Reservation.takerCompany.name + ` ${Reservation.takerCompany.phone}` : Reservation.takerCompany.name
        }
        setReservation(Reservation);
        setReservationDialog(true);
    }

    const showReservation = (Reservation) => {
        if (Reservation.giverCompany == null)
            setGiverInterTour(true)
        if (Reservation.takerCompany == null)
            setTakerInterTour(true)

        setReservation(null);
        if (Reservation.giverCompany) {
            Reservation.giverCompany.optName = Reservation.giverCompany.phone ? Reservation.giverCompany.name + ` ${Reservation.giverCompany.phone}` : Reservation.giverCompany.name
        }
        if (Reservation.takerCompany) {
            Reservation.takerCompany.optName = Reservation.takerCompany.phone ? Reservation.takerCompany.name + ` ${Reservation.takerCompany.phone}` : Reservation.takerCompany.name
        }
        setReservation(Reservation);
        setAllReadonly(true)
        setReservationDialog(true);
    }

    const showReservationDetailDialog = async (Reservation) => {
        var res = await EndPointService.get(`/api/Reservation/GetReservationDetail?reservationId=${Reservation.id}`)
        setReservationDetail1(res.str1 ?? "")
        setReservationDetail2(res.str2 ?? "")
        setReservationDetailDialog(true)
    }

    const editPassenger = (Passenger) => {
        let _Passenger = { ...Passenger };
        _Passenger.nationality = countries.find(x => x.name === Passenger.nationality);
        setPassenger(_Passenger);
        setPassengerDialog(true);
    }

    const confirmIsDoneReservation = (Reservation) => {
        setReservation(Reservation);
        setIsDoneReservationDialog(true);
    }

    const confirmIsNotDoneReservation = (Reservation) => {
        setReservation(Reservation);
        setIsNotDoneReservationDialog(true);
    }

    const confirmDeleteReservation = (Reservation) => {
        setReservation(Reservation);
        setDeleteReservationDialog(true);
    }

    const confirmDeletePassenger = (Passenger) => {
        setPassenger(Passenger);
        setDeletePassengerDialog(true);
    }

    const deleteReservation = async () => {
        try {
            await EndPointService.delete('/api/Reservation?id=' + Reservation.id)
            toast.current.show({ severity: 'success', summary: 'Başarılı', detail: 'Rezervasyon Silindi', life: 3000 });
        } catch (error) {
            toast.current.show({ severity: 'error', summary: 'Hata', detail: 'Rezervasyon Silinirken Hata Oluştu', life: 3000 });
        }
        getReservations();
        setDeleteReservationDialog(false);
        setReservation(emptyReservation);
    }

    const isDoneReservation = async () => {
        try {
            await EndPointService.post(`/api/Reservation/SetDone?id=${Reservation.id}&done=true`)
            toast.current.show({ severity: 'success', summary: 'Başarılı', detail: 'Rezervasyon Onaylandı', life: 3000 });
        } catch (error) {
            toast.current.show({ severity: 'error', summary: 'Hata', detail: 'Rezervasyon Onaylanırken Hata Oluştu', life: 3000 });
        }
        getReservations();
        setIsDoneReservationDialog(false);
        setReservation(emptyReservation);
    }

    const isNotDoneReservation = async () => {
        try {
            await EndPointService.post(`/api/Reservation/SetDone?id=${Reservation.id}&done=false`)
            toast.current.show({ severity: 'success', summary: 'Başarılı', detail: 'Rezervasyon Onayı Geri Alındı', life: 3000 });
        } catch (error) {
            toast.current.show({ severity: 'error', summary: 'Hata', detail: 'Rezervasyon Onayı Geri Alınırken Hata Oluştu', life: 3000 });
        }
        getReservations();
        setIsNotDoneReservationDialog(false);
        setReservation(emptyReservation);
    }

    const deletePassenger = async () => {
        try {
            await EndPointService.delete('/api/Passenger?id=' + Passenger.id)
            toast.current.show({ severity: 'success', summary: 'Başarılı', detail: 'Yolcu Silindi', life: 3000 });
        } catch (error) {
            toast.current.show({ severity: 'error', summary: 'Hata', detail: 'Yolcu Silinirken Hata Oluştu', life: 3000 });
        }
        getReservations();
        setDeletePassengerDialog(false);
        setPassenger(emptyPassenger);
    }

    const confirmDeleteSelected = () => {
        setDeleteReservationsDialog(true);
    }

    const confirmDeletePassengersSelected = () => {
        setDeletePassengersDialog(true);
    }

    const deleteSelectedReservations = async () => {
        try {
            await selectedReservations.map(async Reservation => {
                await EndPointService.delete('/api/Reservation?id=' + Reservation.id)
            })
            toast.current.show({ severity: 'success', summary: 'Başarılı', detail: 'Rezervasyonlar Silindi', life: 3000 });
        } catch (error) {
            toast.current.show({ severity: 'error', summary: 'Hata', detail: 'Rezervasyonlar Silinirken Hata Oluştu', life: 3000 });
        }
        getReservations();
        setDeleteReservationsDialog(false);
        setSelectedReservations(null);
        setReservation(emptyReservation);
    }

    const deleteSelectedsPassengers = async () => {
        try {
            await selectedPassengers.map(async Passenger => {
                await EndPointService.delete('/api/Passenger?id=' + Passenger.id)
            })
            toast.current.show({ severity: 'success', summary: 'Başarılı', detail: 'Yolcular Silindi', life: 3000 });
        } catch (error) {
            toast.current.show({ severity: 'error', summary: 'Hata', detail: 'Yolcular Silinirken Hata Oluştu', life: 3000 });
        }
        getReservations();
        setDeletePassengersDialog(false);
        setSelectedPassengers(null);
        setPassenger(emptyPassenger);
    }

    const carBodyTemplate = (data) => {
        var plate = data.car?.plate
        return (
            <>
                {plate}
            </>
        );
    };

    const serviceTypeBodyTemplate = (data) => {
        var name = data.serviceType?.name
        return (
            <>
                {name}
            </>
        );
    };

    const idBodyTemplate = (data) => {
        return (
            <p onClick={() => showReservation(data)} style={{ color: 'blue' }}> {data.reservationId}</p>
        );
    };

    const driverBodyTemplate = (data) => {
        var name = data.driver?.name
        return (
            <>
                {name}
            </>
        );
    };

    const giverCompanyBodyTemplate = (data) => {
        var name = data.giverCompany?.name ?? "InterTour"
        return (
            <>
                {name}
            </>
        );
    };

    const takerCompanyBodyTemplate = (data) => {
        var name = data.takerCompany?.name ?? "InterTour"
        return (
            <>
                {name}
            </>
        );
    };

    const dateBodyTemplate = (data) => {
        var date = data.date.format("DD-MM-YY HH:mm");
        return (
            <>
                {date}
            </>
        );
    };

    const completedDateBodyTemplate = (data) => {
        var date = data.completedDate.format("DD-MM-YY HH:mm");
        return (
            <>
                {date}
            </>
        );
    };

    const numberFormat = (value) =>
        new Intl.NumberFormat('tr-TR', {
            style: 'currency',
            currency: 'TRY'
        }).format(value).replace("₺", "");

    const takenPriceBodyTemplate = (data) => {
        if (data.giverCompany == null && data.takerCompany != null) {
            var currency = Currencys.find(x => x.id === data.givenPriceCurrencyId)?.code;
            var fullPrice = numberFormat(data.givenPrice) + " " + (currency ?? "") + (data.givenPriceTl ? ` (${numberFormat(data.givenPriceTl)}₺)` : "");
        }
        else {
            var currency = Currencys.find(x => x.id === data.takenPriceCurrencyId)?.code;
            var fullPrice = numberFormat(data.takenPrice) + " " + (currency ?? "") + (data.takenPriceTl ? ` (${numberFormat(data.takenPriceTl)}₺)` : "");
        }
        return (
            <>
                {fullPrice}
            </>
        );
    }

    const givenPriceBodyTemplate = (data) => {
        var currency = Currencys.find(x => x.id === data.givenPriceCurrencyId)?.code;
        var fullPrice = numberFormat(data.givenPrice) + " " + (currency ?? "");
        return (
            <>
                {fullPrice}
            </>
        );
    }

    const collectionPriceBodyTemplate = (data) => {
        var currency = Currencys.find(x => x.id === data.collectionCurrencyId)?.code;
        var fullPrice = numberFormat(data.collection) + " " + (currency ?? "") + (data.collectionTl ? ` (${numberFormat(data.collectionTl)}₺)` : "");
        return (
            <>
                {fullPrice}
            </>
        );
    }

    const statusBodyTemplate = (data) => {
        return (
            <>{data.isDone ? (<span className={`product-badge status-4`}>Tamamlanmış</span>) : (
                <span className={`product-badge status-${data.reservationStatus}`}>{data.reservationStatus === 3 ? "Onay Bekliyor" : data.reservationStatus === 2 ? "Bekliyor" : "İşlemde"}</span>
            )}
            </>
        )
    };

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _Reservation = { ...Reservation };
        _Reservation[`${name}`] = val;
        setReservation(_Reservation);
    }

    const onInputPassengerChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _Passenger = { ...Passenger };
        _Passenger[`${name}`] = val;
        setPassenger(_Passenger);
    }

    const onCarChange = (e) => {
        const val = (e.target && e.target.value) || '';
        let _Reservation = { ...Reservation };
        _Reservation.car = val;
        _Reservation.carId = val.id;
        setReservation(_Reservation);

        if (val.id) {
            let carDriver = [];
            AllDriverCars.filter(x => x.car.id == val.id).map(x => {
                carDriver.push(x.driver)
            })
            setDriverCars(carDriver)
        }
        else {
            setDriverCars(Drivers)
        }
    }

    const onInputNumberChange = (e, name) => {
        const val = e.value || 0;
        let _Reservation = { ...Reservation };
        _Reservation[`${name}`] = val;
        setReservation(_Reservation);
    }

    const onDriverChange = (e) => {
        const val = (e.target && e.target.value) || '';
        let _Reservation = { ...Reservation };
        _Reservation.driver = val;
        _Reservation.driverId = val.id;
        setReservation(_Reservation);
    }

    const onTakenPriceCurrencyChange = (e) => {
        const val = (e.target && e.target.value) || 1;
        let _Reservation = { ...Reservation };
        _Reservation.takenPriceCurrencyId = val;
        setReservation(_Reservation);
    }

    const onGivenPriceCurrencyIdChange = (e) => {
        const val = (e.target && e.target.value) || 1;
        let _Reservation = { ...Reservation };
        _Reservation.givenPriceCurrencyId = val;
        setReservation(_Reservation);
    }

    const onCollectionCurrencyChange = (e) => {
        const val = (e.target && e.target.value) || 1;
        let _Reservation = { ...Reservation };
        _Reservation.collectionCurrencyId = val;
        setReservation(_Reservation);
    }

    const onServiceTypeChange = (e) => {
        const val = (e.target && e.target.value) || '';
        let _Reservation = { ...Reservation };
        _Reservation.serviceType = val;
        _Reservation.serviceTypeId = val.id;
        setReservation(_Reservation);
    }

    const ongiverCompanyChange = (e) => {
        const val = (e.target && e.target.value) || '';
        let _Reservation = { ...Reservation };
        _Reservation.giverCompany = val;
        _Reservation.giverCompanyId = val.id;
        setReservation(_Reservation);
    }

    const ontakerCompanyChange = (e) => {
        const val = (e.target && e.target.value) || '';
        let _Reservation = { ...Reservation };
        _Reservation.takerCompany = val;
        _Reservation.takerCompanyId = val.id;
        _Reservation.collection = 0;
        _Reservation.car = null
        _Reservation.carId = 0;
        if (!val.id)
            _Reservation.givenPrice = 0
        setReservation(_Reservation);

        if (val.id) {
            EndPointService.get(`/api/Car/GetWithCompany?companyId=${val.id}`).then(data => {
                setCompanyCars(data)
            });
        }
        else {
            let currentCompanyCar = Cars.filter(x => !x.company)
            setCompanyCars(currentCompanyCar)
            setDriverCars(Drivers)
        }

    }

    const onInputDateChange = (e, name) => {
        const val = e._d || '';
        let _Reservation = { ...Reservation };
        _Reservation[`${name}`] = new moment(val);
        setReservation(_Reservation);
    }

    const clearFilter = () => {
        setAllSearch('')
        let _lazyParams = { ...lazyParams };
        _lazyParams.filters = {
            'car': { value: null, matchMode: FilterMatchMode.IN },
            'driver': { value: null, matchMode: FilterMatchMode.IN },
            'serviceType': { value: null, matchMode: FilterMatchMode.IN },
            'giverCompany': { value: null, matchMode: FilterMatchMode.IN },
            'takerCompany': { value: null, matchMode: FilterMatchMode.IN },
            'date': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
            'reservationId': { value: null, matchMode: FilterMatchMode.EQUALS },
            'reservationStatus': { value: null, matchMode: FilterMatchMode.IN },
        };
        setLazyParams(_lazyParams);
    }

    const onIsFromChange = (e) => {
        let _Reservation = { ...Reservation };
        if (e.target.name === "isFromAgency") {
            _Reservation.isFromAgency = true;
            _Reservation.isFromPassenger = false;
            _Reservation.collection = 0;
        }
        else {
            _Reservation.isFromAgency = false;
            _Reservation.isFromPassenger = true;
        }
        setReservation(_Reservation);
    }


    const onGenderChange = (e) => {
        let _Passenger = { ...Passenger };
        if (e.target.name === "isMen") {
            _Passenger.isMen = true;
            _Passenger.isWoman = false;
        }
        else {
            _Passenger.isMen = false;
            _Passenger.isWoman = true;
        }
        setPassenger(_Passenger);
    }

    const onNationalityChange = (e) => {
        const val = (e.target && e.target.value) || '';
        let _Passenger = { ...Passenger };
        _Passenger.nationality = val;
        setPassenger(_Passenger);
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                {
                    !rowData.isDone ?
                        (<>
                            <Button icon="pi pi-pencil" style={{ width: '30px', height: '30px' }} className="p-button-rounded p-button-success p-mr-2" onClick={() => editReservation(rowData)} />
                            {rowData.reservationStatus === 3 && rowData.car?.id && rowData.driver?.id ?
                                (<><Button icon="pi pi-check" style={{ width: '30px', height: '30px' }} className="p-button-rounded" onClick={() => confirmIsDoneReservation(rowData)} /></>)
                                : (null)}</>)
                        : (<><Button icon="pi pi-times" style={{ width: '30px', height: '30px' }} className="p-button-rounded" onClick={() => confirmIsNotDoneReservation(rowData)} />
                            <Button icon="pi pi-search" style={{ width: '30px', height: '30px' }} className="p-button-rounded p-button-info" onClick={() => showReservation(rowData)} /></>)
                }
                <Button icon="pi pi-envelope" style={{ width: '30px', height: '30px' }} className="p-button-rounded p-button-info" onClick={() => showReservationDetailDialog(rowData)} />
                <Button icon="pi pi-trash" style={{ width: '30px', height: '30px' }} className="p-button-rounded p-button-warning" onClick={() => confirmDeleteReservation(rowData)} />
            </div>
        );
    }

    const actionPassengerBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success p-mr-2" onClick={() => editPassenger(rowData)} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-warning" onClick={() => confirmDeletePassenger(rowData)} />
            </div>
        );
    }

    const isFromAgencyBodyTemplate = (rowData) => {
        return <i className={classNames('pi', { 'true-icon pi-check-circle': rowData.isFromAgency, 'false-icon pi-times-circle': !rowData.isFromAgency })}></i>;
    }

    const isFromPassengerBodyTemplate = (rowData) => {
        return <i className={classNames('pi', { 'true-icon pi-check-circle': rowData.isFromPassenger, 'false-icon pi-times-circle': !rowData.isFromPassenger })}></i>;
    }

    const header = (
        <div className="table-header">
            <h5 className="p-m-0">Rezervasyonlar</h5>
            <span className="p-input-icon-left">
                <React.Fragment>
                    <span className="p-input-icon-left">
                        <i className="pi pi-search" />
                        <InputText id="allSearch" className="p-inputtext-sm p-mr-2 p-mb-2" style={{ width: '300px' }} value={allSearch} onChange={(e) => setAllSearch(e.target?.value ?? null)} placeholder="Ara.." />
                    </span>
                    <Button type="button" icon="pi pi-filter-slash" label="Temizle" className="p-button-outlined p-mr-2 p-mb-2" onClick={clearFilter} />
                    <Button label="Ekle" icon="pi pi-plus" className="p-button-success p-mr-2 p-mb-2" onClick={openNew} />
                    <Button label="Sil" icon="pi pi-trash" className="p-button-danger p-mb-2" onClick={confirmDeleteSelected} disabled={!selectedReservations || !selectedReservations.length} />
                </React.Fragment>
            </span>
        </div>
    );

    const headerExpand = (data) => (
        <div className="table-header">
            <h5 className="p-m-0">Yolcular</h5>
            <span className="p-input-icon-left">
                <React.Fragment>
                    <Button label="Ekle" icon="pi pi-plus" className="p-button-success p-mr-2 p-mb-2" onClick={() => openNewPassenger(data)} />
                    <Button label="Sil" icon="pi pi-trash" className="p-button-danger p-mb-2" onClick={confirmDeletePassengersSelected} disabled={!selectedPassengers || !selectedPassengers.length} />
                </React.Fragment>
            </span>
        </div>
    );

    const ReservationDialogFooter = (
        <>
            <Button label="İptal" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            {!AllReadonly ? <Button label="Kaydet" icon="pi pi-check" className="p-button-text" onClick={saveReservation} /> : null}
        </>
    );

    const PassengerDialogFooter = (
        <>
            <Button label="İptal" icon="pi pi-times" className="p-button-text" onClick={hidePassengerDialog} />
            <Button label="Kaydet" icon="pi pi-check" className="p-button-text" onClick={savePassenger} />
        </>
    );
    const deleteReservationDialogFooter = (
        <>
            <Button label="Hayır" icon="pi pi-times" className="p-button-text" onClick={hideDeleteReservationDialog} />
            <Button label="Evet" icon="pi pi-check" className="p-button-text" onClick={deleteReservation} />
        </>
    );
    const ReservationDetailDialogFooter = (
        <>
            <Button label="Tamam" icon="pi pi-times" className="p-button-text" onClick={() => setReservationDetailDialog(n => !n)} />
        </>
    );
    const isDoneReservationDialogFooter = (
        <>
            <Button label="Hayır" icon="pi pi-times" className="p-button-text" onClick={hideIsDoneReservationDialog} />
            <Button label="Evet" icon="pi pi-check" className="p-button-text" onClick={isDoneReservation} />
        </>
    );
    const isNotDoneReservationDialogFooter = (
        <>
            <Button label="Hayır" icon="pi pi-times" className="p-button-text" onClick={hideIsNotDoneReservationDialog} />
            <Button label="Evet" icon="pi pi-check" className="p-button-text" onClick={isNotDoneReservation} />
        </>
    );
    const deleteReservationsDialogFooter = (
        <>
            <Button label="Hayır" icon="pi pi-times" className="p-button-text" onClick={hideDeleteReservationsDialog} />
            <Button label="Evet" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedReservations} />
        </>
    );
    const deletePassengerDialogFooter = (
        <>
            <Button label="Hayır" icon="pi pi-times" className="p-button-text" onClick={hideDeletePassengerDialog} />
            <Button label="Evet" icon="pi pi-check" className="p-button-text" onClick={deletePassenger} />
        </>
    );
    const deletePassengersDialogFooter = (
        <>
            <Button label="Hayır" icon="pi pi-times" className="p-button-text" onClick={hideDeletePassengersDialog} />
            <Button label="Evet" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedsPassengers} />
        </>
    );

    const idFilterTemplate = (options) => {
        return <InputText value={options.value} onChange={(e) => options.filterCallback(e.target.value)} className="p-column-filter" />;
    }

    const carFilterTemplate = (options) => {
        return <div style={{ width: '250px' }}><MultiSelect style={{ width: '200px' }} value={options.value} options={FilterCars} itemTemplate={(car) => { return (<> {car.plate}</>); }} onChange={(e) => options.filterCallback(e.value)} optionLabel="plate" className="p-column-filter" /></div>
    }

    const driverFilterTemplate = (options) => {
        return <div style={{ width: '250px' }}><MultiSelect style={{ width: '200px' }} value={options.value} options={FilterDrivers} itemTemplate={(driver) => { return (<> {driver.name}</>); }} onChange={(e) => options.filterCallback(e.value)} optionLabel="name" className="p-column-filter" /></div>
    }

    const serviceTypeFilterTemplate = (options) => {
        return <div style={{ width: '250px' }}><MultiSelect style={{ width: '200px' }} value={options.value} options={FilterServiceTypes} itemTemplate={(type) => { return (<> {type.name}</>); }} onChange={(e) => options.filterCallback(e.value)} optionLabel="name" className="p-column-filter" /></div>
    }

    const takerCompanyTypeFilterTemplate = (options) => {
        return <div style={{ width: '250px' }}><MultiSelect style={{ width: '200px' }} filter showClear filterBy="name" value={options.value} options={FilterTakerCompanys} itemTemplate={(type) => { return (<> {type.name}</>); }} onChange={(e) => options.filterCallback(e.value)} optionLabel="name" className="p-column-filter" /></div>
    }

    const statusFilterTemplate = (options) => {
        return <div style={{ width: '250px' }}><MultiSelect style={{ width: '200px' }} value={options.value} options={Status} itemTemplate={statusItemTemplate} onChange={(e) => options.filterCallback(e.value)} optionLabel="name" className="p-column-filter" /></div>
    }

    const statusItemTemplate = (type) => {
        return <span className={`product-badge status-${type.key}`}> {type.name}</span>;
    }

    const giverCompanyTypeFilterTemplate = (options) => {
        return <div style={{ width: '250px' }}><MultiSelect value={options.value} filter showClear filterBy="name" style={{ width: '200px' }} options={FilterGiverCompanys} itemTemplate={(type) => { return (<> {type.name}</>); }} onChange={(e) => options.filterCallback(e.value)} optionLabel="name" className="p-column-filter" /></div>

    }

    const dateFilterTemplate = (options) => {
        return <div style={{ width: '400px' }}><Calendar value={options.value} style={{ width: '200px' }} selectionMode="range" onChange={(e) => options.filterCallback(e.value, options.index)} dateFormat="dd/mm/yy" /></div>
    }

    const rowExpansionTemplate = (data) => {
        return (
            <div className="p-col-12 crud-demo">
                <DataTable paginator value={data.passengers} rowsPerPageOptions={[5, 10, 25]} rows={10} className="datatable-responsive" dataKey="id"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Gösterilen {first} ve {last} Toplam {totalRecords} Yolcu İçinden"
                    emptyMessage="No Passengers found." responsiveLayout="scroll" header={() => headerExpand(data)}
                    selection={selectedPassengers} selectionPageOnly onSelectionChange={(e) => setSelectedPassengers(e.value)}>
                    <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
                    <Column field="id" header="id" style={{ display: 'none' }} sortable></Column>
                    <Column field="nationalityNumber" header="T.C. Kimlik No/ Passport No" sortable></Column>
                    <Column field="nameSurname" header="İsim Soyisim" sortable></Column>
                    <Column field="phone" header="Telefon" sortable></Column>
                    <Column field="nationality" header="Uyruk" sortable></Column>
                    <Column body={actionPassengerBodyTemplate}></Column>
                </DataTable>
            </div>
        );
    }

    const filterClearTemplate = (options) => {
        return <Button type="button" icon="pi pi-filter-slash" onClick={options.filterClearCallback} className="p-button-outlined"></Button>;
    }

    const filterApplyTemplate = (options) => {
        return <Button type="button" icon="pi pi-search" onClick={options.filterApplyCallback} className="p-button-success"></Button>
    }

    const searchCompany = (event) => {
        let _filteredCompanys;
        if (!event.query.trim().length) {
            _filteredCompanys = [...Companys];
        }
        else {
            _filteredCompanys = Companys.filter((company) => {
                return company.name.toLowerCase().startsWith(event.query.toLowerCase());
            });
        }
        setFilteredCompanys(_filteredCompanys);
    }

    const searchLocations = (event) => {
        let _filteredLocations;
        if (!event.query.trim().length) {
            _filteredLocations = [...Locations];
        }
        else {
            _filteredLocations = Locations.filter((loc) => {
                return loc.toLowerCase().startsWith(event.query.toLowerCase());
            });
        }
        setFilteredLocations(_filteredLocations);
    }

    return (
        <div className="p-col-12 crud-demo">
            <div className="Reservationd">
                <Toast ref={toast} style={{ top: 60 }} />
                <DataTable ref={dt} value={Reservations} lazy filterDisplay="menu" className="p-datatable-scrollable" responsiveLayout="scroll" dataKey="id" paginator
                    first={lazyParams.first} rows={lazyParams.rows} totalRecords={totalRecords} onPage={onPage}
                    onSort={onSort} sortField={lazyParams.sortField} sortOrder={lazyParams.sortOrder}
                    onFilter={onFilter} filters={lazyParams.filters} loading={loading}
                    selection={selectedReservations} selectionPageOnly onSelectionChange={(e) => setSelectedReservations(e.value)}
                    rowExpansionTemplate={rowExpansionTemplate}
                    expandedRows={expandedRows} onRowToggle={(e) => setExpandedRows(e.data)}
                    rowsPerPageOptions={[5, 10, 25]}
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Gösterilen {first} ve {last} Toplam {totalRecords} Rezervasyon İçinden"
                    emptyMessage="No Reservation found." header={header}>
                    <Column selectionMode="multiple" style={{ maxWidth: '50px' }}></Column>
                    <Column expander style={{ maxWidth: '50px' }} />
                    <Column field="id" header="id" style={{ display: 'none' }} ></Column>
                    <Column field="reservationId" body={idBodyTemplate} showFilterMenuOptions={false} filterElement={idFilterTemplate} filterField="reservationId" filter header="Hizmet No"
                        filterClear={filterClearTemplate} filterApply={filterApplyTemplate}></Column>
                    <Column field="serviceType" showFilterMenuOptions={false} filter filterField="serviceType" body={serviceTypeBodyTemplate} filterElement={serviceTypeFilterTemplate} header="Hizmet Türü" sortField="serviceType.name"
                        filterClear={filterClearTemplate} filterApply={filterApplyTemplate}></Column>
                    <Column field="giverCompany" showFilterMenuOptions={false} filter filterField="giverCompany" header="İş Veren" body={giverCompanyBodyTemplate} filterElement={giverCompanyTypeFilterTemplate} sortField="giverCompany.name"
                        filterClear={filterClearTemplate} filterApply={filterApplyTemplate}></Column>
                    <Column field="takerCompany" showFilterMenuOptions={false} filter filterField="takerCompany" header="İşi Yapan" body={takerCompanyBodyTemplate} filterElement={takerCompanyTypeFilterTemplate} sortField="takerCompany.name"
                        filterClear={filterClearTemplate} filterApply={filterApplyTemplate}></Column>
                    <Column field="date" header="Tarih" showFilterMenuOptions={false} filterElement={dateFilterTemplate} filter filterField="date" body={dateBodyTemplate}
                        filterClear={filterClearTemplate} filterApply={filterApplyTemplate}></Column>
                    {/* <Column field="completedDate" header="Bitiş" showFilterMenuOptions={false} filterElement={dateFilterTemplate} filter filterField="date" body={completedDateBodyTemplate}
                        filterClear={filterClearTemplate} filterApply={filterApplyTemplate}></Column> */}
                    <Column field="departure" header="Nereden" ></Column>
                    <Column field="arrival" header="Nereye" ></Column>
                    <Column field="takenPrice" header="Hizmet Bedeli" body={takenPriceBodyTemplate}></Column>
                    <Column field="collection" header="Tahsilat" body={collectionPriceBodyTemplate}></Column>
                    <Column field="car" showFilterMenuOptions={false} filter filterField="car" body={carBodyTemplate} filterElement={carFilterTemplate} header="Plaka" sortField="car.plate"
                        filterClear={filterClearTemplate} filterApply={filterApplyTemplate}></Column>
                    <Column field="driver" showFilterMenuOptions={false} filter filterField="driver" body={driverBodyTemplate} filterElement={driverFilterTemplate} header="Şoför" sortField='driver.name'
                        filterClear={filterClearTemplate} filterApply={filterApplyTemplate}></Column>
                    {/* 
                        <Column field="givenPrice" header="Verilen Ücret" body={givenPriceBodyTemplate}></Column>
                        <Column field="isFromPassenger" dataType="boolean" bodyClassName="text-center" body={isFromPassengerBodyTemplate} header="Yolcu Kaynaklı" ></Column>
                        <Column field="isFromAgency" dataType="boolean" bodyClassName="text-center" body={isFromAgencyBodyTemplate} header="Acente Kaynaklı" ></Column> */}
                    <Column field="description" header="Açıklama" ></Column>

                    <Column field="reservationStatus" header="Durum" body={statusBodyTemplate} showFilterMenuOptions={false} filter filterField="reservationStatus" filterElement={statusFilterTemplate}
                        filterClear={filterClearTemplate} filterApply={filterApplyTemplate}></Column>
                    <Column body={actionBodyTemplate}></Column>
                </DataTable>

                <Dialog visible={ReservationDialog} style={{ width: '650px' }} header={`Rezervasyon Detayları ${Reservation?.reservationId ?? ""}`} modal className="p-fluid" footer={ReservationDialogFooter} onHide={hideDialog}>
                    <div className="p-formgrid p-grid">
                        <div className="p-field-checkbox p-col">
                            <Checkbox inputId="binary1" checked={giverInterTour} disabled={AllReadonly} onChange={e => setGiverInterTour(e.checked)} />
                            <label htmlFor="binary1">İş Veren InterTour</label>
                        </div>
                        <div className="p-field-checkbox p-col">
                            <Checkbox inputId="binary" checked={takerInterTour} disabled={AllReadonly} onChange={e => setTakerInterTour(e.checked)} />
                            <label htmlFor="binary">İşi Yapan InterTour</label>
                        </div>
                    </div>
                    <div className="p-formgrid p-grid">
                        <div className="p-field p-col">
                            <label htmlFor="giverCompanyId">İş Veren Şirket</label>
                            <Dropdown value={Reservation.giverCompany} editable disabled={AllReadonly || giverInterTour} placeholder={giverInterTour ? 'InterTour' : ''} filter showClear filterBy="optName" options={Companys} onChange={ongiverCompanyChange} optionLabel="optName" />
                        </div>
                        <div className="p-field p-col">
                            <label htmlFor="takerCompanyId">İşi Yapan Şirket</label>
                            <Dropdown value={Reservation.takerCompany} placeholder={takerInterTour ? 'InterTour' : ''} editable disabled={AllReadonly || takerInterTour} tooltipOptions={{ position: 'top' }} filter showClear filterBy="optName" options={Companys} onChange={ontakerCompanyChange} optionLabel="optName" />
                        </div>
                    </div>
                    <div className="p-formgrid p-grid">
                        <div className="p-field p-col">
                            <label htmlFor="carId">Araç</label>
                            <Dropdown value={Reservation.car} options={CompanyCars} editable disabled={AllReadonly} filter showClear filterBy="plate" onChange={onCarChange} optionLabel="plate" />
                        </div>
                        <div className="p-field p-col">
                            <label htmlFor="driverId">Sürücü</label>
                            <Dropdown value={Reservation.driver} filter showClear editable disabled={AllReadonly} filterBy="name" options={Reservation.car ? DriverCars : []} onChange={onDriverChange} optionLabel="name" />
                        </div>
                    </div>
                    <div className="p-field">
                        <label className="p-mb-3">Ücretin Alınma Şekli</label>
                        <div className="p-formgrid p-grid">
                            <div className="p-field-radiobutton p-col">
                                <RadioButton inputId="isFromPassenger" disabled={AllReadonly} name="isFromPassenger" value="isFromPassenger" onChange={onIsFromChange} checked={Reservation.isFromPassenger} />
                                <label htmlFor="isFromPassenger">Yolcudan</label>
                            </div>
                            <div className="p-field-radiobutton p-col">
                                <RadioButton inputId="isFromAgency" disabled={AllReadonly} name="isFromAgency" value="isFromAgency" onChange={onIsFromChange} checked={Reservation.isFromAgency} />
                                <label htmlFor="isFromAgency">Acenteden</label>
                            </div>
                        </div>
                    </div>
                    <div className="p-formgrid p-grid">
                        <div className="p-field p-col">
                            <label htmlFor="takenPrice">Bütçe</label>
                            <div className="p-inputgroup">
                                <InputNumber id="takenPrice" readOnly={AllReadonly} mode="decimal" minFractionDigits={2} disabled={giverInterTour && !takerInterTour} value={Reservation.takenPrice} required onValueChange={(e) => onInputNumberChange(e, 'takenPrice')} />
                                <span className="p-inputgroup-addon">
                                    <MultiStateCheckbox disabled={AllReadonly || (giverInterTour && !takerInterTour)} value={Reservation.takenPriceCurrencyId} options={Currencys} optionValue="id" onChange={onTakenPriceCurrencyChange} />
                                    <label>{Currencys.find(x => x.id === Reservation.takenPriceCurrencyId)?.code}</label>
                                </span>
                            </div>
                        </div>
                        <div className="p-field p-col">
                            <label htmlFor="collection">Tahsilat</label>
                            <div className="p-inputgroup">
                                <InputNumber id="collection" readOnly={AllReadonly} value={Reservation.collection} mode="decimal" minFractionDigits={2} disabled={Reservation.isFromAgency} onValueChange={(e) => onInputNumberChange(e, 'collection')} />
                                <span className="p-inputgroup-addon">
                                    <MultiStateCheckbox value={Reservation.collectionCurrencyId} disabled={AllReadonly || Reservation.isFromAgency} options={Currencys} optionValue="id" onChange={onCollectionCurrencyChange} />
                                    <label>{Currencys.find(x => x.id === Reservation.collectionCurrencyId)?.code}</label>
                                </span>
                            </div>
                        </div>
                        <div className="p-field p-col">
                            <label htmlFor="givenPrice">Verilen Ücret</label>
                            <div className="p-inputgroup">
                                <InputNumber id="givenPrice" readOnly={AllReadonly} value={Reservation.givenPrice} mode="decimal" minFractionDigits={2} disabled={!Reservation.takerCompany} onValueChange={(e) => onInputNumberChange(e, 'givenPrice')} />
                                <span className="p-inputgroup-addon">
                                    <MultiStateCheckbox value={Reservation.givenPriceCurrencyId} disabled={AllReadonly || !Reservation.takerCompany} options={Currencys} optionValue="id" onChange={onGivenPriceCurrencyIdChange} />
                                    <label>{Currencys.find(x => x.id === Reservation.givenPriceCurrencyId)?.code}</label>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="p-formgrid p-grid">
                        <div className="p-field p-col">
                            <Space direction="vertical" size={12}>
                                <label htmlFor="date">Başlangıç Tarihi</label>
                                <DatePicker disabled={AllReadonly} locale={locale} getPopupContainer={(triggerNode) => {
                                    return triggerNode.parentNode;
                                }} style={{ width: '288px', height: '50px', borderColor: 'rgba(0, 0, 0, 0.38)', borderRadius: '3px' }} onChange={(e) => onInputDateChange(e, 'date')} onOk={(e) => onInputDateChange(e, 'date')} showTime={{ format: 'HH:mm' }} format="DD-MM-YY HH:mm" value={Reservation.date} />
                                {submitted && !Reservation.date && <small className="p-invalid">Başlangıç Tarihi zorunlu.</small>}
                            </Space>
                        </div>
                        <div className="p-field p-col">
                            <Space direction="vertical" size={12}>
                                <label htmlFor="completedDate">Bitiş Tarihi</label>
                                <DatePicker disabled={AllReadonly} locale={locale} getPopupContainer={(triggerNode) => {
                                    return triggerNode.parentNode;
                                }} style={{ width: '288px', height: '50px', borderColor: 'rgba(0, 0, 0, 0.38)', borderRadius: '3px' }} onChange={(e) => onInputDateChange(e, 'completedDate')} onOk={(e) => onInputDateChange(e, 'completedDate')} showTime={{ format: 'HH:mm' }} format="DD-MM-YY HH:mm" value={Reservation.completedDate} />
                                {submitted && !Reservation.completedDate && <small className="p-invalid">Bitiş Tarihi zorunlu.</small>}
                            </Space>
                        </div>
                    </div>
                    <div className="p-formgrid p-grid">
                        <div className="p-field p-col">
                            <label htmlFor="departure">Alış</label>
                            <AutoComplete id="departure" value={Reservation.departure} disabled={AllReadonly} suggestions={FilteredLocations} completeMethod={searchLocations} onChange={(e) => onInputChange(e, 'departure')} />
                            {/* <InputText id="departure" readOnly={AllReadonly} value={Reservation.departure} onChange={(e) => onInputChange(e, 'departure')} required={true} className={classNames({ 'p-invalid': submitted && !Reservation.departure })} /> */}
                            {submitted && !Reservation.departure && <small className="p-invalid">Alış zorunlu.</small>}
                        </div>
                        <div className="p-field p-col">
                            <label htmlFor="arrival">Bırakış</label>
                            <AutoComplete id="departure" value={Reservation.arrival} disabled={AllReadonly} suggestions={FilteredLocations} completeMethod={searchLocations} onChange={(e) => onInputChange(e, 'arrival')} />
                            {/* <InputText id="arrival" readOnly={AllReadonly} value={Reservation.arrival} onChange={(e) => onInputChange(e, 'arrival')} required={true} className={classNames({ 'p-invalid': submitted && !Reservation.arrival })} /> */}
                            {submitted && !Reservation.arrival && <small className="p-invalid">Bırakış zorunlu.</small>}
                        </div>
                    </div>
                    <div className="p-formgrid p-grid">
                        <div className="p-field p-col">
                            <label htmlFor="serviceTypeId">Servis Tipi</label>
                            <Dropdown value={Reservation.serviceType} disabled={AllReadonly} filter showClear required filterBy="name" options={ServiceTypes} onChange={onServiceTypeChange} optionLabel="name" className={classNames({ 'p-invalid': submitted && !Reservation.serviceType })} />
                            {submitted && !Reservation.serviceTypeId && <small className="p-invalid">Servis Tipi zorunlu.</small>}
                        </div>
                        <div className="p-field p-col">
                            <label htmlFor="description">Açıklama</label>
                            <InputTextarea id="description" readOnly={AllReadonly} value={Reservation.description} onChange={(e) => onInputChange(e, 'description')} autoResize rows={3} cols={20} />
                        </div>
                    </div>
                </Dialog>

                <Dialog visible={PassengerDialog} style={{ width: '450px' }} header="Yolcu Detayları" modal className="p-fluid" footer={PassengerDialogFooter} onHide={hidePassengerDialog}>
                    <div className="p-field">
                        <label htmlFor="nationalityNumber">T.C. Kimlik No/ Passport No</label>
                        <InputMask mask="99999999999" id="nationalityNumber" value={Passenger.nationalityNumber} onChange={(e) => onInputPassengerChange(e, 'nationalityNumber')} required autoFocus className={classNames({ 'p-invalid': submittedPassenger && !Passenger.nationalityNumber })} />
                    </div>
                    <div className="p-field">
                        <label htmlFor="nameSurname">İsim Soyisim</label>
                        <InputText id="nameSurname" value={Passenger.nameSurname} onChange={(e) => onInputPassengerChange(e, 'nameSurname')} required className={classNames({ 'p-invalid': submittedPassenger && !Passenger.nameSurname })} />
                        {submitted && !Passenger.nameSurname && <small className="p-invalid">İsim zorunlu.</small>}
                    </div>
                    <div className="p-field">
                        <label htmlFor="phone">Telefon</label>
                        <InputText id="phone" value={Passenger.phone} onChange={(e) => onInputPassengerChange(e, 'phone')} required className={classNames({ 'p-invalid': submittedPassenger && !Passenger.phone })} />
                    </div>
                    <div className="p-field">
                        <label className="p-mb-3">Cinsiyet</label>
                        <div className="p-formgrid p-grid">
                            <div className="p-field-radiobutton p-col-6">
                                <RadioButton inputId="isMen" name="isMen" value="isMen" onChange={onGenderChange} checked={Passenger.isMen} />
                                <label htmlFor="isMen">Erkek</label>
                            </div>
                            <div className="p-field-radiobutton p-col-6">
                                <RadioButton inputId="isWoman" name="isWoman" value="isWoman" onChange={onGenderChange} checked={Passenger.isWoman} />
                                <label htmlFor="isWoman">Kadın</label>
                            </div>
                        </div>
                    </div>
                    <div className="p-field">
                        <label htmlFor="nationality">Uyruk</label>
                        <Dropdown id="nationality" value={Passenger.nationality} filter showClear required filterBy="name" options={countries} onChange={onNationalityChange} optionLabel="name" className={classNames({ 'p-invalid': submitted && !Passenger.nationality })} />
                    </div>
                </Dialog>

                <Dialog visible={ReservationDetailDialog} style={{ width: '850px' }} header="Mesajlar" modal footer={ReservationDetailDialogFooter} onHide={() => setReservationDetailDialog(n => !n)}>
                    <div className="p-formgrid p-grid">
                        <div className="p-field p-col">
                            <div dangerouslySetInnerHTML={{ __html: ReservationDetail1.replace(/\n/g, '<br />') }} ></div>
                            {/* <InputTextarea id="detay1" readOnly value={ReservationDetail1} autoResize cols={50} /> */}
                        </div>
                        <div className="p-field p-col">
                            <div dangerouslySetInnerHTML={{ __html: ReservationDetail2.replace(/\n/g, '<br />') }} ></div>
                            {/* <InputTextarea id="detay2" readOnly value={ReservationDetail2} autoResize cols={50} /> */}
                        </div>
                    </div>
                </Dialog>

                <Dialog visible={deleteReservationDialog} style={{ width: '450px' }} header="Onay" modal footer={deleteReservationDialogFooter} onHide={hideDeleteReservationDialog}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                        {Reservation && <span>Silmek istediğinize emin misiniz?</span>}
                    </div>
                </Dialog>

                <Dialog visible={isDoneReservationDialog} style={{ width: '450px' }} header="Onay" modal footer={isDoneReservationDialogFooter} onHide={hideIsDoneReservationDialog}>
                    <div className="confirmation-content">
                        <i className="pi pi-check p-mr-3" style={{ fontSize: '2rem' }} />
                        {Reservation && <span>Onaylamak istediğinize emin misiniz?</span>}
                    </div>
                </Dialog>

                <Dialog visible={isNotDoneReservationDialog} style={{ width: '450px' }} header="Onay" modal footer={isNotDoneReservationDialogFooter} onHide={hideIsNotDoneReservationDialog}>
                    <div className="confirmation-content">
                        <i className="pi pi-check p-mr-3" style={{ fontSize: '2rem' }} />
                        {Reservation && <span>Onayı Geri Almak istediğinize emin misiniz?</span>}
                    </div>
                </Dialog>

                <Dialog visible={deleteReservationsDialog} style={{ width: '450px' }} header="Onay" modal footer={deleteReservationsDialogFooter} onHide={hideDeleteReservationsDialog}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                        {Reservation && <span>Seçilen kayıtları silmek istediğinize emin misiniz?</span>}
                    </div>
                </Dialog>

                <Dialog visible={deletePassengerDialog} style={{ width: '450px' }} header="Onay" modal footer={deletePassengerDialogFooter} onHide={hideDeletePassengerDialog}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                        {Passenger && <span>Silmek istediğinize emin misiniz?</span>}
                    </div>
                </Dialog>

                <Dialog visible={deletePassengersDialog} style={{ width: '450px' }} header="Onay" modal footer={deletePassengersDialogFooter} onHide={hideDeletePassengersDialog}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                        {Passenger && <span>Seçilen kayıtları silmek istediğinize emin misiniz?</span>}
                    </div>
                </Dialog>
            </div>
        </div>
    );
}
